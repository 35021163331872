import React from 'react';
import { Box } from '@material-ui/core';
import { alpha, styled } from '@material-ui/core/styles';
import { defaultTo, filter } from 'lodash';
import { paramCase } from 'change-case';
import useMobile from '../../hooks/useMobile';
import { MLazyLoadImage } from '../core/@react-lazy-load-image-component-extend';
import { varFadeIn, MotionInView } from '../core/animate';
import { getSelectedImage } from '../../utils/getSelectedImage';
import { CartCalculation } from './CartCalculation';
import { CartSummaryTable } from './CartSummaryTable';
import { CART_PREVIEW_VIEW_TYPE } from '../../utils/constants';

const COMPACT_MIN_HEIGHT = 500;

const RootStyleContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative'
}));

const RootStyleInner = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  position: 'relative',
  backgroundColor: theme.palette.primary.main,
  borderRadius: theme.shape.borderRadiusSm
}));

const BackgroundImageContainer = styled(MLazyLoadImage)(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  position: 'absolute',
  borderRadius: theme.shape.borderRadiusSm
}));

const BackgroundOverlay = styled(Box)(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 2,
  width: '100%',
  height: '100%',
  position: 'absolute',
  borderRadius: theme.shape.borderRadiusSm
}));

const MotionInViewStyle = styled(MotionInView)({
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  position: 'absolute'
});

export const CartPreviewPanel = ({ config, checkoutStage, sx, ...other }) => {
  const isMobile = useMobile();
  const { primaryColour, overlay, desktopImage, mobileImage, showShadow, stickyHeader, viewType } =
    config.checkout.preview;
  const img = getSelectedImage(isMobile, desktopImage, mobileImage);
  const { completed } = checkoutStage;
  const isCompactView = viewType === CART_PREVIEW_VIEW_TYPE[1] && !isMobile;
  const isStandardView = viewType === CART_PREVIEW_VIEW_TYPE[0] || isMobile;

  return (
    <RootStyleContainer>
      <RootStyleInner
        sx={{
          ...(isStandardView && {
            borderRadius: '0 !important'
          }),
          ...(isCompactView && {
            margin: (theme) => theme.spacing(2),
            height: 'fit-content',
            minHeight: COMPACT_MIN_HEIGHT,
            ...(stickyHeader && { position: 'sticky', top: (theme) => theme.spacing(3) })
          }),
          ...(primaryColour && { backgroundColor: primaryColour }),
          ...(showShadow && { boxShadow: (theme) => theme.customShadows.z2 }),
          ...sx
        }}
      >
        {img && (
          <MotionInViewStyle variants={varFadeIn} sx={{ height: '100%' }}>
            <BackgroundImageContainer
              alt="Checkout background image"
              src={img}
              imgSx={{
                ...(isStandardView && {
                  borderRadius: '0 !important'
                })
              }}
            />
            <BackgroundOverlay
              sx={{
                ...(overlay && {
                  backgroundImage: (theme) =>
                    `linear-gradient(to top, ${theme.palette.grey[900]} 0%,${alpha(theme.palette.grey[900], 0)} 100%)`
                })
              }}
            />
          </MotionInViewStyle>
        )}
        {!completed ? (
          <CartCalculation config={config} {...other} />
        ) : (
          <CartCompleteSummary config={config} checkoutStage={checkoutStage} />
        )}
      </RootStyleInner>
    </RootStyleContainer>
  );
};

const CartCompleteSummary = ({ config, checkoutStage }) => {
  const { loading, summary } = checkoutStage;
  const currency = summary?.currency;
  const discount = summary?.invoice?.discount?.amount;
  const shipping = summary?.invoice?.shipping?.amount;
  const subtotal = summary?.invoice?.subTotal?.amount;
  const total = summary?.invoice?.total?.amount;
  const products = filter(summary?.invoice?.items, (o) => paramCase(defaultTo(o.type, '')) === 'product');

  return (
    <CartSummaryTable
      currency={currency}
      config={config}
      loading={{ table: loading }}
      showDiscount={false}
      isShippingStep
      isValidDiscount // Will always display discount if exists
      discount={discount}
      shipping={shipping}
      subtotal={subtotal}
      total={total}
      products={products}
    />
  );
};
