import React from 'react';
// material
import { Box, Stack, MenuItem, TextField, InputAdornment, Typography } from '@material-ui/core';
import { alpha, styled } from '@material-ui/core/styles';
import { getTextAlignPosition, TEXT_POSITIONS } from '../../../utils/getPositions';
import { MAX_DROPDOWN_WIDTH_LARGE } from '../../../utils/constants';

const RadioSelection = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  borderRadius: theme.shape.textfieldBorderRadius,
  border: `1px solid ${alpha(theme.palette.border, 0.32)}`,
  height: '100%',
  padding: theme.spacing(1.3, 2),
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(2),
  minWidth: 50,
  textAlign: 'center'
}));

const DropDown = styled(TextField)(() => ({
  maxWidth: MAX_DROPDOWN_WIDTH_LARGE
}));

const RadioVariantSelector = ({ position, prop, title, options, form, onChange, sx }) => {
  const { values } = form;
  const isSelected = (value) => values[prop] === value;

  return (
    <Stack spacing={1.5} sx={{ maxWidth: MAX_DROPDOWN_WIDTH_LARGE, ...sx }}>
      <Typography
        variant="body1"
        sx={{ color: (theme) => theme.palette.text.secondary, textAlign: getTextAlignPosition(position) }}
      >
        {title}
      </Typography>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: getTextAlignPosition(position) }}>
        {options.map((option) => {
          return (
            <RadioSelection
              key={option}
              sx={{
                ...(position === TEXT_POSITIONS[2] && { marginRight: 0, marginLeft: (theme) => theme.spacing(1) }),
                ...(isSelected(option) && { border: (theme) => `2px solid ${theme.palette.primary.main}` })
              }}
              onClick={() => onChange(null, option)}
            >
              <Typography
                variant="body2"
                sx={{
                  userSelect: 'none',
                  color: (theme) => (isSelected(option) ? theme.palette.primary.main : theme.palette.text.secondary)
                }}
              >
                {option}
              </Typography>
            </RadioSelection>
          );
        })}
      </Box>
    </Stack>
  );
};

const DropdownVariantSelector = ({ prop, title, options, form, onChange, sx }) => {
  const { getFieldProps } = form;
  return (
    <DropDown
      select
      fullWidth
      {...getFieldProps(prop)}
      onChange={(e) => onChange(e, e.target.value)}
      SelectProps={{
        onClose: () => {
          setTimeout(() => {
            document.activeElement.blur();
          }, 0);
        }
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="end" sx={{ color: (theme) => theme.palette.text.secondary }}>
            {`${title} -`}&nbsp;
          </InputAdornment>
        )
      }}
      FormHelperTextProps={{
        sx: {
          margin: 0,
          mt: 1
        }
      }}
      sx={{ ...sx }}
    >
      {options.map((_option) => (
        <MenuItem key={_option} value={_option}>
          {_option}
        </MenuItem>
      ))}
    </DropDown>
  );
};

export const ProductVariantSelector = ({ isSelectView, ...other }) => {
  if (isSelectView) {
    return <RadioVariantSelector {...other} />;
  }

  return <DropdownVariantSelector {...other} />;
};
