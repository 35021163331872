import { reduce, multiply, isEmpty } from 'lodash';
import numeral from 'numeral';

const FORMAT_PATTERN = '0,0.00';

export const getCartTotal = (products) => {
  if (isEmpty(products)) {
    return 0;
  }
  const totals = products.map((_product) => _product.price.amount * _product.quantity);
  return reduce(totals, (sum, n) => sum + n);
};

export const getCartTotalInMinorUnits = (products) => {
  if (isEmpty(products)) {
    return 0;
  }
  const totals = products.map((_product) => _product.price.amountInMinorUnits * _product.quantity);
  return reduce(totals, (sum, n) => sum + n);
};

export const getProductQuantityTotal = (price, quantity) => (price ? multiply(price, quantity) : 0);

// Calculated discount on top of ONLY products NOT shipping.
// total = ((subtotal - discount) + shipping)
export const calculateTotal = (subtotal, shipping, discount) => {
  const zero = numeral(0).format(FORMAT_PATTERN);
  if (!subtotal) {
    return zero;
  }
  const subTotalWithdiscount = calculateDiscount(subtotal, discount);
  const floorSubTotalWithDiscount = floorZero(subTotalWithdiscount);
  const final = shipping ? floorSubTotalWithDiscount.add(shipping) : floorSubTotalWithDiscount;
  return floorZero(final).format(FORMAT_PATTERN);
};

const calculateDiscount = (subtotal, discount) => (discount ? numeral(subtotal).subtract(discount) : numeral(subtotal));

const floorZero = (price) => (price.value() <= 0 ? numeral(0) : price);
