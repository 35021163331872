import React from 'react';
import { Box, Stack, Typography } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { styled } from '@material-ui/core/styles';
import warningFilled from '@iconify/icons-ep/warning-filled';
import {
  DEFAULT_PAYMENT_ERROR_ORDER_MESSAGE_HEADER,
  DEFAULT_PAYMENT_ERROR_ORDER_MESSAGE_TITLE,
  DEFAULT_PAYMENT_ERROR_ORDER_MESSAGE_BODY
} from '../../../../utils/constants';
import { SummarySection } from '../SummarySection';

const IconStyle = styled(Icon)(({ theme }) => ({
  color: theme.palette.error.main
}));

export const FailureDetails = ({ headingProps, showHeaderIcon, icon, heading, sx }) => {
  return (
    <Stack spacing={2} {...headingProps} sx={sx.heading}>
      {showHeaderIcon && (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <IconStyle icon={icon} width={40} height={40} />
        </Box>
      )}
      <Typography variant="h6">{heading}</Typography>
    </Stack>
  );
};

export default function OrderMessagePaymentErrorView({
  heading,
  headingProps,
  title,
  message,
  showBorder,
  showHeaderIcon,
  sx
}) {
  const { title: titleSx, content: contentSx } = sx;
  return (
    <SummarySection
      showBorder={showBorder}
      header={
        <FailureDetails
          headingProps={headingProps}
          showHeaderIcon={showHeaderIcon}
          icon={warningFilled}
          heading={heading || DEFAULT_PAYMENT_ERROR_ORDER_MESSAGE_HEADER}
          sx={sx}
        />
      }
      title={title || DEFAULT_PAYMENT_ERROR_ORDER_MESSAGE_TITLE}
      sx={titleSx}
    >
      <Typography variant="body1" sx={{ color: 'text.secondary', ...contentSx }}>
        {message || DEFAULT_PAYMENT_ERROR_ORDER_MESSAGE_BODY}
      </Typography>
    </SummarySection>
  );
}
