import React from 'react';
import { Box, Typography, Stack } from '@material-ui/core';
import { alpha, styled } from '@material-ui/core/styles';

const BoxStyled = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.textfieldBorderRadius,
  border: `solid 1px ${alpha(theme.palette.border, 0.32)}`
}));

export const SummarySection = ({ header, title, showBorder, children, sx, root }) => (
  <BoxStyled
    sx={{
      ...root?.sx,
      ...(showBorder === false && { padding: (theme) => theme.spacing(1, 3), border: 'none' })
    }}
  >
    <Stack spacing={2}>
      {header}
      <Typography variant="subtitle1" sx={{ color: (theme) => theme.palette.primary.main, ...sx }}>
        {title}
      </Typography>
      {children}
    </Stack>
  </BoxStyled>
);

export const TextHeadingStyle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary
}));

export const TextStyle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary
}));
