import React from 'react';
import { Box, LinearProgress, Typography } from '@material-ui/core';

export const Loader = ({ message, sx }) => (
  <>
    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', width: '100%', ...sx }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
        {message && (
          <Typography variant="body1" sx={{ mb: (theme) => theme.spacing(3), color: 'text.secondary' }}>
            {message}
          </Typography>
        )}
        <LinearProgress
          sx={{
            width: '90%',
            maxWidth: '100px'
          }}
        />
      </Box>
    </Box>
  </>
);
