import React, { useCallback, useMemo } from 'react';
import { Grid, Container } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';
import { defaultTo, isEmpty } from 'lodash';
import { getDesktopItemsPerRow, getItemsPerRow } from '../../utils/getItemsPerRow';
import useMobile from '../../hooks/useMobile';
import { ProductCardListItem } from './ProductCardListItem';
import EmptyContent from '../core/EmptyContent';
import {
  DEFAULT_DESKTOP_GRID_SIZE,
  DEFAULT_MOBILE_GRID_SIZE,
  DEFAULT_PRODUCTS_MISSING_DESCRIPTION,
  DEFAULT_PRODUCTS_MISSING_TITLE,
  PRODUCTS_TABLE_VIEW_TYPE
} from '../../utils/constants';
import { isInvalid } from '../../utils/nullable';
import { LargeLoaderComponent } from '../core/LargeLoaderComponent';

export const ProductsTable = ({
  config,
  currency,
  loading,
  products,
  hasNext,
  total,
  handleLoadMore,
  handleNavigationClick
}) => {
  const isProductsEmpty = !loading && isEmpty(products);
  const { showProductInfo, desktopProductRows, mobileProductRows, productsViewType, messaging } = config;
  const isMobile = useMobile();
  const isWideView = productsViewType === PRODUCTS_TABLE_VIEW_TYPE[1];
  const numberOfProducts = defaultTo(total, 0);
  const getMobileRows = useCallback(
    () => (mobileProductRows ? getItemsPerRow(mobileProductRows, numberOfProducts) : DEFAULT_MOBILE_GRID_SIZE),
    [mobileProductRows, numberOfProducts]
  );
  const getDesktopRows = useCallback(
    () =>
      desktopProductRows ? getDesktopItemsPerRow(desktopProductRows, numberOfProducts) : DEFAULT_DESKTOP_GRID_SIZE,
    [desktopProductRows, numberOfProducts]
  );

  const ProductsViewMemo = useMemo(() => {
    const gridSpacing = {
      xs: isMobile ? 3 : 5
    };

    const gridSizing = {
      xs: isMobile ? getMobileRows() : getDesktopRows()
    };
    // A product is considered in loading state if products are empty. Seeing as `isProductsEmpty` should manage all states including loading
    return loading ? (
      <LargeLoaderComponent />
    ) : (
      <InfiniteScroll
        initialLoad={false}
        loadMore={handleLoadMore}
        hasMore={hasNext}
        loader={<LargeLoaderComponent key={0} />}
      >
        <Grid container direction="row" spacing={isWideView ? 0 : gridSpacing}>
          {products.map((_product) => (
            <Grid key={_product.id} item {...gridSizing}>
              <ProductCardListItem
                config={config}
                currency={currency}
                showProductInfo={showProductInfo}
                product={_product}
                handleNavigationClick={handleNavigationClick}
                isWideView={isWideView}
              />
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>
    );
  }, [
    showProductInfo,
    isWideView,
    config,
    loading,
    products,
    handleLoadMore,
    hasNext,
    currency,
    isMobile,
    getMobileRows,
    getDesktopRows,
    handleNavigationClick
  ]);

  return (
    <Container
      maxWidth={false}
      sx={{
        '&.MuiContainer-root': {
          ...(isWideView && { paddingLeft: 0, paddingRight: 0 })
        }
      }}
    >
      {isProductsEmpty ? (
        <EmptyContent
          title={isInvalid(messaging?.title) ? DEFAULT_PRODUCTS_MISSING_TITLE : messaging?.title}
          description={
            isInvalid(messaging?.description) ? DEFAULT_PRODUCTS_MISSING_DESCRIPTION : messaging?.description
          }
        />
      ) : (
        ProductsViewMemo
      )}
    </Container>
  );
};
