import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, max, min } from 'lodash';
import { Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import Page from './components/core/Page';
import ThemeConfig from './theme';
import {
  DEFAULT_CHECKOUT_PAGE_NAME,
  DEFAULT_EMAIL_COMMS_SUBSCRIPTION,
  DEFAULT_PROCESSING_CHECKOUT_FORM_ERROR_MESSAGE
} from './utils/constants';
import { defaultTo } from './utils/nullable';
import { CheckoutView } from './components/checkout/CheckoutView';
import {
  SHIPPING_STEP,
  PAYMENT_STEP,
  CREDIT_CARD_OPTION_ID,
  INVALID_DISCOUNT_ERROR,
  isLastStep,
  STEPS,
  isFirstStep
} from './utils/checkoutUtils';

const CheckoutContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
}));

const FlexForm = styled(Form)(() => ({
  flexGrow: 1
}));

CheckoutPageTemplate.propTypes = {
  context: PropTypes.object, // Used to pre-fill information
  logo: PropTypes.object,
  loading: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  checkoutStage: PropTypes.object.isRequired,
  hasCheckoutError: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  shippingOptions: PropTypes.array.isRequired,
  shippingCountries: PropTypes.array.isRequired,
  handleLoadShippingOptions: PropTypes.func.isRequired,
  handleLoadPaymentOptions: PropTypes.func,
  // Callback for checkout
  handleValidateDiscount: PropTypes.func.isRequired,
  handleCheckout: PropTypes.func.isRequired,
  handleChangePaymentOption: PropTypes.func.isRequired,
  handleChangedDiscount: PropTypes.func.isRequired,
  handleChangedPage: PropTypes.func.isRequired,
  handleCheckoutFailure: PropTypes.func.isRequired,
  // Common functions
  handleNavigationClick: PropTypes.func.isRequired,
  handleGetNavigation: PropTypes.func.isRequired,
  handleGetNavigations: PropTypes.func.isRequired,
  handleOnPageEnter: PropTypes.func
};

export default function CheckoutPageTemplate({
  context,
  config,
  shippingCountries,
  shippingOptions,
  paymentMethods,
  handleCheckout,
  handleChangedDiscount,
  handleChangedPage,
  handleChangePaymentOption,
  handleCheckoutFailure,
  handleLoadShippingOptions,
  handleValidateDiscount,
  handleOnPageEnter,
  ...other
}) {
  const prefill = context?.prefill;
  const [loadingDiscount, setLoadingDiscount] = useState(false);
  // Payment provider elements reference
  const paymentsContext = useRef({
    stripe: {
      elements: null,
      client: null
    }
  });

  const handleUpdateStripeContext = useCallback((elements, client) => {
    paymentsContext.current.stripe = {
      elements,
      client
    };
  }, []);

  const [discountStore, setDiscountStore] = useState({
    isValidDiscount: false,
    discountAmount: null,
    discountCode: ''
  });

  const buildValues = useCallback(
    (values) => {
      try {
        const checkout = config?.checkout;
        const emailSubscription = !checkout?.showEmailComminicationCheckbox
          ? DEFAULT_EMAIL_COMMS_SUBSCRIPTION
          : values.emailCommunicationAccepted;
        const shipping = {
          address: values.address,
          city: values.city,
          postcode: values.postcode,
          country: values.country
        };
        const billing = {
          address: values.billingAddress,
          city: values.billingCity,
          postcode: values.billingPostcode,
          country: values.billingCountry
        };
        return {
          emailCommunicationAccepted: emailSubscription,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          shippingAddress: shipping,
          billingSameAsShipping: values.billingAddressSameAsShipping,
          billingAddress: {
            ...(values.billingAddressSameAsShipping ? shipping : billing)
          },
          discountCode: values.discount,
          discountValid: values.discountValid,
          shippingOption: values.shipping,
          paymentOption: values.payment
        };
      } catch {
        handleCheckoutFailure(DEFAULT_PROCESSING_CHECKOUT_FORM_ERROR_MESSAGE);
        return null;
      }
    },
    [config?.checkout, handleCheckoutFailure]
  );

  const CheckoutSchema = Yup.object().shape({
    emailCommunicationAccepted: Yup.bool().required('Please choose an option for email communication'),
    email: Yup.string().email().required('Email is required'),
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    address: Yup.string().required('Address is required'),
    city: Yup.string().required('City is required'),
    postcode: Yup.string().required('Postcode is required'),
    country: Yup.string().required('Country is required'),
    billingAddressSameAsShipping: Yup.boolean().required('Please select an option'),
    billingAddress: Yup.string()
      .nullable()
      .when('billingAddressSameAsShipping', {
        is: (val) => val === false,
        then: Yup.string().nullable().required('Billing Address is required')
      }),
    billingCity: Yup.string()
      .nullable()
      .when('billingAddressSameAsShipping', {
        is: (val) => val === false,
        then: Yup.string().nullable().required('Billing City is required')
      }),
    billingPostcode: Yup.string()
      .nullable()
      .when('billingAddressSameAsShipping', {
        is: (val) => val === false,
        then: Yup.string().nullable().required('Billing Postcode is required')
      }),
    billingCountry: Yup.string()
      .nullable()
      .when('billingAddressSameAsShipping', {
        is: (val) => val === false,
        then: Yup.string().nullable().required('Billing Country is required')
      }),
    shipping: Yup.number()
      .nullable()
      .when('activeStep', {
        is: (val) => val === SHIPPING_STEP,
        then: Yup.number().nullable().required('Shipping option required')
      }), // Selected shipping key
    payment: Yup.string()
      .nullable()
      .when('activeStep', {
        is: (val) => val === PAYMENT_STEP,
        then: Yup.string().nullable().required('Payment option required')
      }), // Selected payment method key
    cardNumber: Yup.number().when('payment', {
      is: CREDIT_CARD_OPTION_ID,
      then: Yup.number()
        .required('Card Number is required')
        .typeError('Card Number can only contain numbers')
        .test(
          'len',
          'Please enter valid card number',
          (val) =>
            val && val.toString().replace(/ /g, '').length >= 13 && val && val.toString().replace(/ /g, '').length <= 18
        )
    }),
    cardName: Yup.string().when('payment', {
      is: CREDIT_CARD_OPTION_ID,
      then: Yup.string()
        .required('Card Name is required')
        .test('alphabets', 'Name must only contain alphabets', (value) => {
          return /^[A-Za-z ]+$/.test(value);
        })
    }),
    expirationDate: Yup.string().when('payment', {
      is: CREDIT_CARD_OPTION_ID,
      then: Yup.string()
        .required('Expiration Date is required')
        .test('expDateYear', 'Please enter valid Expiration Date', (value) => {
          return /^(0[1-9]|1[0-2])\/([0-9]{2})$/.test(value);
        })
    }),
    securityCode: Yup.number().when('payment', {
      is: CREDIT_CARD_OPTION_ID,
      then: Yup.number()
        .required('CVV Security Code is required')
        .typeError('CVV Security Code can only contain numbers')
        .test(
          'len',
          'Please enter valid CVV Security Code',
          (val) => val && val.toString().length >= 3 && val && val.toString().length <= 4
        )
    })
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      activeStep: 0,
      // Information
      emailCommunicationAccepted: defaultTo(prefill?.emailCommunicationAccepted, DEFAULT_EMAIL_COMMS_SUBSCRIPTION),
      email: defaultTo(prefill?.email, ''),
      firstName: defaultTo(prefill?.firstName, ''),
      lastName: defaultTo(prefill?.lastName, ''),
      // Shipping
      address: defaultTo(prefill?.address, ''),
      city: defaultTo(prefill?.city, ''),
      postcode: defaultTo(prefill?.postcode, ''),
      country: defaultTo(prefill?.country, !isEmpty(shippingCountries) ? shippingCountries[0].code : ''), // Country code is stored not label / id
      // Billing
      billingAddressSameAsShipping: defaultTo(prefill?.billingAddressSameAsShipping, true),
      billingAddress: defaultTo(prefill?.billingAddress, ''),
      billingCity: defaultTo(prefill?.billingCity, ''),
      billingPostcode: defaultTo(prefill?.billingPostcode, ''),
      billingCountry: defaultTo(prefill?.billingCountry, !isEmpty(shippingCountries) ? shippingCountries[0].code : ''), // Country code is stored not label / id
      // Summary
      discount: '',
      // Shipping
      shipping: defaultTo(prefill?.shipping, null), // Shipping option key selected
      // Payment
      payment: defaultTo(prefill?.payment, null),
      // UNUSED
      cardNumber: '',
      cardName: '',
      expirationDate: '',
      securityCode: ''
    },
    validationSchema: CheckoutSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        resetTouched();
        handleNext();
        if (isLastStep(values.activeStep)) {
          const update = { ...values, discountValid: discountStore.isValidDiscount };
          const processedValues = buildValues(update);
          await handleCheckout(processedValues, paymentsContext.current);
        }
        setSubmitting(false);
      } catch (error) {
        setSubmitting(false);
      }
    }
  });

  const { touched, values, initialValues, validateForm, setFieldValue, setFieldError, setTouched, handleSubmit } =
    formik;

  const resetTouched = () => {
    // Have to do this as we are not always submitting and touched is true on submit
    setTouched({
      ...touched,
      shipping: false,
      payment: false,
      cardNumber: false,
      cardName: false,
      expirationDate: false,
      securityCode: false
    });
  };

  const updateTouched = () => {
    // We need to inform lower components if there are erros to show as back as been clicked and otherwise may not display. This will be reset on foward step
    setTouched({
      ...touched,
      shipping: true,
      payment: true,
      cardNumber: true,
      cardName: true,
      expirationDate: true,
      securityCode: true
    });
  };

  const handleLoadOptions = (nextStep) => {
    if (nextStep === SHIPPING_STEP) {
      handleLoadShippingOptions(values.country);
    }
    // Deprecated - For now we won't use country to determine which payment options will be displayed. This is because of the `express payment option` which requires
    // all payment options to be known earlier in the checkout journey.
    // if (nextStep === PAYMENT_STEP) {
    //   handleLoadPaymentOptions(values.country);
    // }
  };

  const handleNext = () => {
    const step = values.activeStep;
    // Next destination
    const nextStep = min([step + 1, STEPS.length - 1]);
    // Update form to change view
    setFieldValue('activeStep', nextStep);
    // Pre-load options before navigation view shown
    handleLoadOptions(nextStep);
    // Send data to event listeners
    const processedValues = buildValues(values);
    handleChangedPage(processedValues, nextStep, isLastStep(nextStep), isFirstStep(nextStep));
  };

  const handleBack = () => {
    validateForm().then((errors) => {
      updateTouched();
      // Fix - Avoid getting locked on a non error page when there is an error
      if (isEmpty(errors)) {
        const step = values.activeStep;
        const nextStep = max([0, step - 1]);
        setFieldValue('activeStep', nextStep);
        // Reset payment value
        setFieldValue('payment', initialValues.payment);
        setFieldValue('shipping', initialValues.shipping);
        // Event listener
        const processedValues = buildValues(values);
        handleChangedPage(processedValues, nextStep, isLastStep(nextStep), isFirstStep(nextStep));
      }
    });
  };

  const handleChangePayment = (...args) =>
    handleChangePaymentOption(buildValues({ ...values, discountValid: discountStore.isValidDiscount }), ...args);

  const handleUpdateDiscountStore = useCallback(
    (propagate, state) => {
      setDiscountStore(state);
      if (propagate) {
        const update = { ...values, discount: state.discountCode, discountValid: state.isValidDiscount };
        const processedValues = buildValues(update);
        handleChangedDiscount(processedValues);
      }
    },
    [values, buildValues, handleChangedDiscount, setDiscountStore]
  );

  const resetDiscountValues = useCallback(
    (propagate) => {
      setFieldError('discount', '');
      handleUpdateDiscountStore(propagate, {
        isValidDiscount: false,
        discountAmount: null,
        discountCode: ''
      });
    },
    [handleUpdateDiscountStore, setFieldError]
  );

  const handleDiscountCheck = useCallback(
    async (discount) => {
      resetDiscountValues(false);
      setLoadingDiscount(true);
      try {
        const result = await handleValidateDiscount(discount, values.email);
        const { active, amount } = result;
        handleUpdateDiscountStore(true, {
          isValidDiscount: active,
          discountAmount: amount,
          discountCode: discount
        });
        if (!active) {
          setFieldError('discount', INVALID_DISCOUNT_ERROR);
        }
      } catch {
        resetDiscountValues(false);
        setFieldError('discount', INVALID_DISCOUNT_ERROR);
      }
      setLoadingDiscount(false);
    },
    [
      values.email,
      handleValidateDiscount,
      setFieldError,
      resetDiscountValues,
      setLoadingDiscount,
      handleUpdateDiscountStore
    ]
  );

  const onClearDiscount = useCallback(() => {
    setFieldValue('discount', '');
    resetDiscountValues(true);
  }, [setFieldValue, resetDiscountValues]);

  return (
    <ThemeConfig customTheme={config?.global?.theme} settings={config?.settings}>
      <Page
        title={defaultTo(config?.checkout?.metadata?.page, DEFAULT_CHECKOUT_PAGE_NAME)}
        description={defaultTo(config?.checkout?.metadata?.description, '')}
        onPageEnter={handleOnPageEnter}
      >
        <CheckoutContainer>
          <FormikProvider value={formik}>
            <FlexForm autoComplete="off" noValidate onSubmit={handleSubmit}>
              <CheckoutView
                form={formik}
                config={config}
                loadingDiscount={loadingDiscount}
                shippingCountries={shippingCountries}
                shippingOptions={shippingOptions}
                paymentMethods={paymentMethods}
                activeStep={values.activeStep}
                discountStore={discountStore}
                handleSubmit={handleSubmit}
                handleBack={handleBack}
                handleChangePaymentOption={handleChangePayment}
                handleUpdateStripeContext={handleUpdateStripeContext}
                handleDiscountCheck={handleDiscountCheck}
                onClearDiscount={onClearDiscount}
                {...other}
              />
            </FlexForm>
          </FormikProvider>
        </CheckoutContainer>
      </Page>
    </ThemeConfig>
  );
}
