import React from 'react';
import { Stack, Skeleton } from '@material-ui/core';
import { FormHeadingText } from './SkeletonForm';

const FormOptionSkeleton = () => <Skeleton variant="rectangular" sx={{ height: 60 }} />;

export const SkeletonFormOption = (props) => (
  <Stack spacing={3} direction="column" {...props}>
    <FormOptionSkeleton />
    <FormOptionSkeleton />
    <FormOptionSkeleton />
  </Stack>
);

export const SkeletonSingleOption = (props) => (
  <Stack spacing={2} direction="column" {...props}>
    <FormHeadingText />
    <FormOptionSkeleton {...props} />
  </Stack>
);
