import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { styled } from '@material-ui/core/styles';
import { LoadingButton } from '@material-ui/lab';
import { defaultTo, isEmpty } from 'lodash';
import { Box, Stack, Alert, Typography, FormHelperText } from '@material-ui/core';
import { Form, FormikProvider, useFormik } from 'formik';
import useMobile from '../../hooks/useMobile';
import Markdown from '../core/Markdown';
import MTextField from '../core/@material-extend/MTextField';
import { QuillEditor } from '../core/editor';

const DEFAULT_FORM_WIDTH = 680;
const DEFAULT_FORM_MIN_WIDTH = 550;

const RootStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(4, 6)
}));

export default function ContactForm({ isVertical, config, showNotes, handleContactSuccess, sx }) {
  const [messageSent, setMessageSent] = useState(null);
  const isMobile = useMobile();
  const {
    contactNotes,
    contactSuccessMessage,
    appearance: { input }
  } = config;

  const ContactSchema = Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string().email().required('Email is required'),
    number: Yup.string().nullable().optional(),
    message: Yup.string()
      .test('Character Length', 'Maximum 1500 characters', (val) => defaultTo(val, '').length < 1500)
      .required('A message is required in your contact')
  });

  const form = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      number: '',
      message: ''
    },
    validationSchema: ContactSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await handleContactSuccess(values);
        setMessageSent(true);
        setSubmitting(false);
      } catch (error) {
        setMessageSent(false);
        setSubmitting(false);
      }
    }
  });

  const { values, isSubmitting, errors, touched, handleSubmit, setFieldValue, getFieldProps } = form;

  const WordCounter = useMemo(() => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <Typography variant="caption" sx={{ color: 'text.secondary' }}>
          Maximum 1500 characters - {values.message?.length} characters
        </Typography>
      </Box>
    );
  }, [values.message]);

  return (
    <RootStyle
      sx={{
        ...(!isMobile && {
          width: DEFAULT_FORM_WIDTH,
          minWidth: DEFAULT_FORM_MIN_WIDTH
        }),
        ...(isMobile && { padding: (theme) => theme.spacing(0) }),
        ...(isVertical && { px: 0 }),
        ...sx
      }}
    >
      <FormikProvider value={form}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={isVertical ? 4 : 2}>
            {Boolean(!isEmpty(contactNotes) && showNotes) && <Markdown children={contactNotes} />}
            <Stack direction={isMobile ? 'column' : 'row'} spacing={2}>
              <MTextField
                shape={input}
                fullWidth
                autoComplete="off"
                label="First Name"
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />
              <MTextField
                shape={input}
                fullWidth
                autoComplete="off"
                label="Last Name"
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Stack>
            <MTextField
              shape={input}
              fullWidth
              autoComplete="off"
              label="Number (optional)"
              {...getFieldProps('number')}
              error={Boolean(touched.number && errors.number)}
              helperText={touched.number && errors.number}
            />
            <MTextField
              shape={input}
              fullWidth
              autoComplete="off"
              label="Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <Stack spacing={1}>
              <Typography variant="body2" color="text.secondary">
                Message
              </Typography>
              <QuillEditor
                autoComplete="off"
                id="Message"
                name="Message"
                value={values.message}
                onChange={(val, _, source) => {
                  if (source === 'user') {
                    setFieldValue('message', val);
                  }
                }}
                error={Boolean(touched.message && errors.message)}
              />
              {touched.message && errors.message && (
                <FormHelperText error>{touched.message && errors.message}</FormHelperText>
              )}
            </Stack>
            {WordCounter}
            <Box sx={{ pt: (theme) => theme.spacing(2) }}>
              <LoadingButton disabled={messageSent} fullWidth type="submit" loading={isSubmitting} variant="contained">
                Send Message
              </LoadingButton>
            </Box>
            {messageSent && !isSubmitting && (
              <Alert severity="success">{contactSuccessMessage || 'Your message has successfully been sent'}</Alert>
            )}
            {messageSent === false && !isSubmitting && (
              <Alert severity="error">
                Unfortunately, there was an error sending your message. Please try again shortly.
              </Alert>
            )}
          </Stack>
        </Form>
      </FormikProvider>
    </RootStyle>
  );
}
