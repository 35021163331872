import React from 'react';
import { Box, Stack, Typography } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { defaultTo, capitalize, isEmpty } from 'lodash';
import { styled } from '@material-ui/core/styles';
import successFilled from '@iconify/icons-ep/success-filled';
import {
  fromNameTemplate,
  DEFAULT_ORDER_MESSAGE_BODY,
  DEFAULT_ORDER_MESSAGE_HEADER,
  DEFAULT_ORDER_MESSAGE_TITLE
} from '../../../utils/constants';
import { SummarySection } from './SummarySection';

const IconStyle = styled(Icon)(({ theme }) => ({
  color: theme.palette.success.main
}));

export const OrderDetails = ({
  icon,
  orderId,
  customer,
  heading,
  fallbackHeading,
  headingProps,
  showHeaderIcon,
  sx
}) => {
  const customerName = defaultTo(customer?.firstName, customer?.lastName);
  const formattedName = customerName && capitalize(customerName);
  const defaultHeading = formattedName ? `Thank you ${formattedName}` : fallbackHeading;

  const formattedHeading = isEmpty(heading)
    ? defaultHeading
    : fromNameTemplate(heading, customer?.firstName, customer?.lastName);

  return (
    <Stack spacing={2} {...headingProps} sx={sx.heading}>
      {showHeaderIcon && (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <IconStyle icon={icon} width={40} height={40} />
        </Box>
      )}
      <Stack spacing={0} direction="column">
        <Typography variant="h6">{formattedHeading}</Typography>
        <Typography variant="body1" sx={{ color: 'text.secondary' }}>{`Order #${orderId}`}</Typography>
      </Stack>
    </Stack>
  );
};

export default function OrderMessageSuccessView({
  orderId,
  customer,
  title,
  message,
  heading,
  headingProps,
  showHeaderIcon,
  showBorder,
  sx
}) {
  const { title: titleSx, content: contentSx } = sx;
  return (
    <SummarySection
      showBorder={showBorder}
      header={
        <OrderDetails
          icon={successFilled}
          orderId={orderId}
          customer={customer}
          heading={heading}
          fallbackHeading={DEFAULT_ORDER_MESSAGE_HEADER}
          headingProps={headingProps}
          showHeaderIcon={showHeaderIcon}
          sx={sx}
        />
      }
      title={title || DEFAULT_ORDER_MESSAGE_TITLE}
      sx={titleSx}
    >
      <Typography variant="body1" sx={{ color: 'text.secondary', ...contentSx }}>
        {message || DEFAULT_ORDER_MESSAGE_BODY}
      </Typography>
    </SummarySection>
  );
}
