import React from 'react';
import { Stack, Box, CircularProgress, IconButton, InputAdornment } from '@material-ui/core';
import { defaultTo } from 'lodash';
import { Icon } from '@iconify/react';
import CloseIcon from '@material-ui/icons/Close';
import { alpha, styled, useTheme } from '@material-ui/core/styles';
import arrowForwardRounded from '@iconify/icons-material-symbols/arrow-forward-rounded';
import MTextField from '../core/@material-extend/MTextField';

const inputStyle = (colour) => {
  return {
    sx: {
      '& label.Mui-focused': {
        color: colour
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: colour
      },
      '& .MuiOutlinedInput-input': {
        '&.Mui-disabled': {
          color: colour,
          '-webkit-text-fill-color': colour
        }
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: alpha(colour, 0.5)
        },
        '&:hover fieldset': {
          borderColor: colour
        },
        '&.Mui-focused fieldset': {
          borderColor: colour
        },
        '&.Mui-disabled': {
          backgroundColor: (theme) => alpha(theme.palette.common.white, 0.1)
        }
      }
    }
  };
};

const StackStyle = styled(Stack)({
  zIndex: 100
});

export const DiscountInput = ({
  input,
  isValidDiscount,
  discountProps,
  touched,
  errors,
  contrastColour,
  loading,
  validateDiscount,
  onClickClearDiscount
}) => {
  const theme = useTheme();
  const { value: discountValue } = discountProps;
  const commonStyles = {
    ...(contrastColour ? { color: contrastColour } : { color: theme.palette.primary.main })
  };
  return (
    <Box sx={{ ...commonStyles, zIndex: 100 }}>
      <StackStyle direction="row" spacing={2}>
        <MTextField
          key="discount"
          shape={input}
          disabled={isValidDiscount}
          fullWidth
          label="Discount"
          {...discountProps}
          error={Boolean(touched && errors)}
          helperText={touched && errors}
          {...(contrastColour && { ...inputStyle(contrastColour) })}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {isValidDiscount ? (
                  <IconButton
                    onClick={onClickClearDiscount}
                    sx={{
                      color: defaultTo(contrastColour, theme.palette.primary.main),
                      svg: {
                        color: 'inherit !important'
                      }
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    disabled={loading}
                    onClick={() => validateDiscount(discountValue)}
                    sx={{
                      color: `${defaultTo(contrastColour, theme.palette.primary.main)} !important`
                    }}
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size={18} />
                    ) : (
                      <Icon color="inherit" icon={arrowForwardRounded} />
                    )}
                  </IconButton>
                )}
              </InputAdornment>
            ),
            ...(contrastColour && {
              style: {
                color: contrastColour
              }
            })
          }}
          InputLabelProps={{
            ...(contrastColour && {
              style: {
                color: contrastColour
              }
            })
          }}
        />
      </StackStyle>
    </Box>
  );
};
