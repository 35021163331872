const TOP_POSITIONS = ['TOP_LEFT', 'TOP_CENTER', 'TOP_RIGHT'];
const MIDDLE_POSITIONS = ['MIDDLE_LEFT', 'MIDDLE_CENTER', 'MIDDLE_RIGHT'];
const BOTTOM_POSITIONS = ['BOTTOM_LEFT', 'BOTTOM_CENTER', 'BOTTOM_RIGHT'];

const LEFT_POSITIONS = ['TOP_LEFT', 'MIDDLE_LEFT', 'BOTTOM_LEFT'];
const CENTER_POSITIONS = ['TOP_CENTER', 'MIDDLE_CENTER', 'BOTTOM_CENTER'];
const RIGHT_POSITIONS = ['TOP_RIGHT', 'MIDDLE_RIGHT', 'BOTTOM_RIGHT'];

export const getVerticalPosition = (position) => {
  return (
    (TOP_POSITIONS.includes(position) && 'start') ||
    (MIDDLE_POSITIONS.includes(position) && 'center') ||
    (BOTTOM_POSITIONS.includes(position) && 'end') ||
    'center'
  );
};

export const getHorizontalPosition = (position) => {
  return (
    (LEFT_POSITIONS.includes(position) && 'start') ||
    (CENTER_POSITIONS.includes(position) && 'center') ||
    (RIGHT_POSITIONS.includes(position) && 'end') ||
    'center'
  );
};

// DEPREICATED
export const getLogoPosition = (position) => {
  switch (position) {
    case 'LEFT':
      return 'flex-start';
    case 'CENTER':
      return 'center';
    case 'RIGHT':
      return 'flex-end';
    default:
      return 'center';
  }
};

export const TEXT_POSITIONS = ['LEFT', 'CENTER', 'RIGHT'];
export const TEXT_VERTICAL_POSITIONS = ['TOP', 'CENTER', 'BOTTOM'];

export const getTextPosition = (position) => {
  switch (position) {
    case TEXT_POSITIONS[0]:
      return 'flex-start';
    case TEXT_POSITIONS[1]:
      return 'center';
    case TEXT_POSITIONS[2]:
      return 'flex-end';
    default:
      return 'center';
  }
};

export const isLogoPositionRight = (position) => position === 'RIGHT';

export const getTextAlignPosition = (config) => {
  switch (config) {
    case TEXT_POSITIONS[0]:
      return 'start';
    case TEXT_POSITIONS[1]:
      return 'center';
    case TEXT_POSITIONS[2]:
      return 'end';
    default:
      return 'center';
  }
};

export const getTextAlignVerticalPosition = (config) => {
  switch (config) {
    case TEXT_VERTICAL_POSITIONS[0]:
      return 'start';
    case TEXT_VERTICAL_POSITIONS[1]:
      return 'center';
    case TEXT_VERTICAL_POSITIONS[2]:
      return 'end';
    default:
      return 'center';
  }
};

export const getNavigationViewPosition = (config) => {
  switch (config) {
    case TEXT_POSITIONS[0]:
      return 'start';
    case TEXT_POSITIONS[1]:
      return 'space-evenly';
    case TEXT_POSITIONS[2]:
      return 'end';
    default:
      return 'space-evenly';
  }
};
