import React, { useState } from 'react';
import { Menu, Button, MenuItem, Typography, Box } from '@material-ui/core';
import { Icon } from '@iconify/react';
import chevronUp from '@iconify/icons-akar-icons/chevron-up';
import chevronDown from '@iconify/icons-akar-icons/chevron-down';
import arrowSort28Filled from '@iconify/icons-fluent/arrow-sort-28-filled';

import { find } from 'lodash';

const renderLabel = (options, sort, sortBy) =>
  find(options, (option) => option.sort === sort && option.sortBy === sortBy);

export const Sort = ({ disabled = false, buttonsFilled, showIcon, options, sort, sortBy, onSortBy, sx }) => {
  const isButtonFilled = buttonsFilled === 'FILLED';
  const label = renderLabel(options, sort, sortBy);
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleSortBy = (sortBy, sort) => {
    handleClose();
    onSortBy(sortBy, sort);
  };

  return (
    <Box sx={{ ...sx }}>
      <Button
        {...(isButtonFilled && { variant: 'contained' })}
        color={isButtonFilled ? 'primary' : 'inherit'}
        disableRipple
        onClick={handleOpen}
        {...(showIcon && { startIcon: <Icon icon={arrowSort28Filled} color="inherit" /> })}
        endIcon={<Icon icon={open ? chevronUp : chevronDown} color="inherit" />}
        sx={{
          minHeight: (theme) => theme.spacing(5.5),
          fontSize: (theme) => theme.typography.body2,
          fontWeight: (theme) => theme.typography.fontWeightMedium,
          padding: (theme) => theme.spacing(1, 2)
        }}
        disabled={disabled}
      >
        Sort By
        {label && (
          <Typography
            component="span"
            variant="subtitle2"
            sx={{ fontSize: 'inherit', paddingLeft: 1, color: isButtonFilled ? 'inherit' : 'text.secondary' }}
          >
            {`| `}&nbsp;{`${label?.name}`}
          </Typography>
        )}
      </Button>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open && !disabled)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            selected={option.sort === sort && option.sortBy === sortBy}
            onClick={() => handleSortBy(option.sortBy, option.sort)}
            sx={{ typography: 'body2' }}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
