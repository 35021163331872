import React, { useMemo } from 'react';
import { styled, useTheme } from '@material-ui/core/styles';
import {
  Stack,
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  useMediaQuery,
  CardHeader
} from '@material-ui/core';
import * as AspectRatioPrimitive from '@radix-ui/react-aspect-ratio';
import { defaultTo, isEmpty } from 'lodash';
import { DEFAULT_PRODUCT_PRICE_SALE_CHANGE_SUPPORTING_TEXT } from '../../utils/constants';
import { DEFAULT_PRODUCT_COLOURS } from '../../theme/palette';
import { getStatusLabel } from '../../utils/getStatusLabel';
import { PRODUCT_PAGE } from '../../utils/pageTypes';
import { fCurrency } from '../../utils/formatNumber';
import missingImageDefault from '../../static/placeholder/product_image.jpg';
import { MLazyLoadImage } from '../core/@react-lazy-load-image-component-extend';
import { varFadeIn, MotionInView } from '../core/animate';
import { trimString } from '../../utils/stringUtils';
import { getTextAlignPosition } from '../../utils/getPositions';
import { ColourChip } from '../core/ColourChip';

export const AspectRatio = AspectRatioPrimitive;

const ProductRoot = styled(Stack)(() => ({
  position: 'relative',
  cursor: 'pointer'
}));

const TagBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1, 0.5),
  height: '30px'
}));

const CardStyle = styled(Card)(() => ({
  boxShadow: 'none',
  borderRadius: 0
}));

const StyledCardMedia = styled(CardMedia)(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusSm
}));

const StyledCardActionArea = styled(CardActionArea)(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusSm
}));

const StyledCardHeader = styled(CardHeader)(() => ({
  padding: 0
}));

export const ProductCardListItem = ({
  isWideView = false,
  product,
  showProductInfo = true,
  currency,
  handleNavigationClick,
  sx,
  config
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const ProductCardListItemViewMemo = useMemo(() => {
    return (
      <ProductCardListItemView
        config={config}
        isMobile={isMobile}
        currency={currency}
        showProductInfo={showProductInfo}
        product={product}
        handleNavigationClick={handleNavigationClick}
        isWideView={isWideView}
        sx={sx}
      />
    );
  }, [isWideView, product, showProductInfo, currency, handleNavigationClick, sx, isMobile, config]);
  return ProductCardListItemViewMemo;
};

export const ProductCardListItemView = ({
  config,
  isWideView = false,
  product,
  showProductInfo = true,
  currency,
  handleNavigationClick,
  isMobile,
  sx
}) => {
  const { name, resource, price, priceSale, priceSaleChange, status, image, colours } = product;
  const {
    showProductTag,
    productTagPosition,
    priceSaleNewLine,
    textPosition,
    prominentTitle,
    showPriceChange,
    showPriceChangePercentage,
    showProductColours
  } = config;
  const priceInMajorUnit = price.amount;
  const priceSaleInMajorUnit = priceSale?.amount;
  const priceSaleChangeAmountInMajorUnit = priceSaleChange?.amount;
  const priceSaleChangePercentage = priceSaleChange?.percent;
  const tagBgColour = config?.tagBackgroundColour || DEFAULT_PRODUCT_COLOURS.tagBackgroundColour;
  const tagTextColour = config?.tagTextColour || DEFAULT_PRODUCT_COLOURS.tagTextColour;
  const saleBgColour = config?.saleBackgroundColour || DEFAULT_PRODUCT_COLOURS.saleBackgroundColour;
  const saleTextColour = config?.saleTextColour || DEFAULT_PRODUCT_COLOURS.saleTextColour;
  const outOfStockBgColour = config?.outOfStockBackgroundColour || DEFAULT_PRODUCT_COLOURS.outOfStockBackgroundColour;
  const outOfStockTextColour = config?.outOfStockTextColour || DEFAULT_PRODUCT_COLOURS.outOfStockTextColour;
  const priceSaleChangeColour = config?.priceSaleChangeColour || DEFAULT_PRODUCT_COLOURS.priceSaleChangeColour;
  const priceSaleChangeSupportingText =
    config?.priceSaleChangeSupportingText || DEFAULT_PRODUCT_PRICE_SALE_CHANGE_SUPPORTING_TEXT;
  const isTagPositionTop = productTagPosition?.includes('TOP');
  const isTagPositionLeft = productTagPosition?.includes('LEFT');
  const textPositionAlignment = getTextAlignPosition(textPosition);
  const statusLabel = getStatusLabel(
    status,
    { saleBgColour, tagBgColour, outOfStockBgColour, saleTextColour, tagTextColour, outOfStockTextColour },
    {
      cursor: 'pointer'
    }
  );
  const priceSaleText = (
    <Typography
      variant={isMobile ? 'body2' : 'body1'}
      sx={{
        color: 'text.disabled',
        textDecoration: 'line-through'
      }}
    >
      {priceSaleInMajorUnit && fCurrency(priceSaleInMajorUnit, currency)}
    </Typography>
  );
  return (
    <ProductRoot {...sx}>
      <MotionInView variants={varFadeIn}>
        <CardStyle
          onClick={() => {
            if (resource) {
              handleNavigationClick(PRODUCT_PAGE, resource);
            }
          }}
        >
          {Boolean(showProductTag && isTagPositionTop) && (
            <StyledCardHeader
              title={
                <TagBox sx={{ display: 'flex', justifyContent: isTagPositionLeft ? 'start' : 'end' }}>
                  {status && statusLabel}
                </TagBox>
              }
            />
          )}
          <StyledCardActionArea>
            <AspectRatio.Root ratio={4 / 5}>
              <StyledCardMedia
                component={MLazyLoadImage}
                source={image || missingImageDefault}
                alt={defaultTo(name, '')}
                imgSx={{
                  height: '100%',
                  width: '100%'
                }}
                sx={{ height: '100%', width: '100%', borderRadius: (theme) => theme.shape.borderRadiusSm }}
              />
            </AspectRatio.Root>
          </StyledCardActionArea>

          {Boolean(showProductTag && status && !isTagPositionTop) && (
            <TagBox sx={{ display: 'flex', justifyContent: isTagPositionLeft ? 'start' : 'end' }}>
              {status && statusLabel}
            </TagBox>
          )}

          {showProductInfo && (
            <CardContent
              component={Stack}
              justifyContent="center"
              spacing={0.5}
              sx={{
                textAlign: textPositionAlignment,
                p: (theme) => theme.spacing(4.5, 2, 0),
                ...(Boolean(!isTagPositionTop && showProductTag && status) && { pt: 2 }),
                ...(Boolean(isMobile && isWideView) && { px: (theme) => theme.spacing(1) }),
                ...(Boolean(isMobile && !isWideView) && { px: 0 })
              }}
            >
              {!isEmpty(name) && (
                <Typography
                  variant={isMobile ? 'body2' : 'body1'}
                  {...(prominentTitle && { sx: { fontWeight: (theme) => theme.typography.fontWeightBold } })}
                >
                  {trimString(50, name)}
                </Typography>
              )}
              <Typography
                {...(!priceSaleNewLine && {
                  component: 'span',
                  sx: {
                    display: 'flex',
                    justifyContent: textPositionAlignment
                  }
                })}
                color="primary"
                variant={isMobile ? 'body2' : 'body1'}
              >
                {priceInMajorUnit && fCurrency(priceInMajorUnit, currency)}
                {!priceSaleNewLine && <>&nbsp;{priceSaleText}</>}
              </Typography>
              {priceSaleNewLine && priceSaleText}
              {Boolean(showPriceChange && priceSaleChangeAmountInMajorUnit) && (
                <Typography variant="body2" sx={{ color: priceSaleChangeColour }}>
                  <>
                    {`${priceSaleChangeSupportingText} ${fCurrency(priceSaleChangeAmountInMajorUnit, currency)}`}&nbsp;
                  </>
                  {Boolean(showPriceChangePercentage && priceSaleChangePercentage) &&
                    `(-${priceSaleChangePercentage}%)`}
                </Typography>
              )}
              {Boolean(showProductColours && !isEmpty(colours)) && (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: textPositionAlignment,
                    pt: (theme) => theme.spacing(1)
                  }}
                >
                  {colours.map((colour, index) => (
                    <ColourChip
                      key={colour}
                      sx={{ backgroundColor: colour, ...(Boolean(index === colours.length - 1) && { mr: 0 }) }}
                    />
                  ))}
                </Box>
              )}
            </CardContent>
          )}
        </CardStyle>
      </MotionInView>
    </ProductRoot>
  );
};
