import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
// material
import { CssBaseline } from '@material-ui/core';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@material-ui/core/styles';
import { buildThemePalette } from './palette';
import { buildThemeShape } from './shape';
import { buildTypography } from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import GlobalStyles from './globalStyles';
import shadows, { customShadows } from './shadows';

// ----------------------------------------------------------------------

ThemeConfig.propTypes = {
  customTheme: PropTypes.object,
  children: PropTypes.node
};

export default function ThemeConfig({ customTheme, settings, children }) {
  const config = useMemo(
    () => ({
      general: {
        showButtonShadow: customTheme?.colours?.shadows?.buttonShadow
      },
      palette: {
        mode: 'light', // DEPRECATE THIS
        ...buildThemePalette(customTheme?.palette)
      },
      shape: buildThemeShape(customTheme?.shape),
      typography: buildTypography(customTheme?.typography),
      breakpoints,
      settings // Add any additional configuration
    }),
    [customTheme, settings]
  );

  config.shadows = shadows(config.palette).light;
  config.customShadows = customShadows(config.palette).light;

  const theme = createTheme(config);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
