import React from 'react';
import { Divider, Stack, Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Sort } from '../core/Sort';
import useMobile from '../../hooks/useMobile';

const SORT_OPTIONS = [
  {
    name: 'Latest',
    sortBy: 'createdAt',
    sort: 'desc'
  },
  {
    name: 'Oldest',
    sortBy: 'createdAt',
    sort: 'asc'
  },
  {
    name: 'Alphabetically: A - Z',
    sortBy: 'name',
    sort: 'asc'
  },
  {
    name: 'Alphabetically: Z - A',
    sortBy: 'name',
    sort: 'desc'
  }
];

const RootStyle = styled(Stack)(() => ({
  width: '100%'
}));

const ToolbarStyle = styled(Stack)(({ theme }) => ({
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5)
}));

export const CollectionListToolbar = ({ config, isCollectionsEmpty, ...other }) => {
  const isMobile = useMobile();
  const { showDivider, sortEnabled, position, showSortIcon, buttonsFilled } = config;
  const isPositionLeft = position === 'LEFT';
  /**
   * To avoid an empty toolbar this will check if all values are available. Right now we only have sorting.
   * This will be an 'OR' check on all possible values.
   */
  const showToolbar = sortEnabled;
  const divider = showDivider ? <Divider /> : <Box />;
  return (
    <>
      {showToolbar && (
        <RootStyle direction="column" spacing={2}>
          {divider}
          <ToolbarStyle
            direction="row"
            spacing={1}
            justifyContent={isPositionLeft ? 'start' : 'end'}
            sx={{ ...(isMobile && { px: (theme) => theme.spacing(2) }) }}
          >
            {Boolean(sortEnabled && !isCollectionsEmpty) && (
              <Sort options={SORT_OPTIONS} {...other} buttonsFilled={buttonsFilled} showIcon={showSortIcon} />
            )}
          </ToolbarStyle>
          {divider}
        </RootStyle>
      )}
    </>
  );
};
