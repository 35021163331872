import { Box } from '@material-ui/core';
import React, { forwardRef, useState } from 'react';
import { styled } from '@material-ui/core/styles';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Loader } from '../Loader';

const Image = styled(LazyLoadImage)(({ theme }) => ({
  objectFit: 'cover',
  borderRadius: theme.shape.borderRadiusSm
}));

const MLazyLoadImage = forwardRef(({ source, src, alt, loaderSx, imgSx, sx, ...other }, ref) => {
  const [loading, setLoading] = useState(true);

  return (
    <Box sx={{ position: 'relative', ...sx, ...(loading && { ...loaderSx }) }} {...other}>
      {loading && <Loader sx={{ position: 'absolute' }} />}
      <Image
        visibleByDefault
        ref={ref}
        src={source || src}
        alt={alt}
        loading="lazy"
        effect="opacity"
        beforeLoad={() => setLoading(true)}
        afterLoad={() => setLoading(false)}
        useIntersectionObserver={false}
        height="100%"
        width="100%"
        style={{ ...imgSx }}
      />
    </Box>
  );
});

export default MLazyLoadImage;
