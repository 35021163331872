import React from 'react';
import PropTypes from 'prop-types';
import Page from './components/core/Page';
import TemplateLayout from './layout/TemplateLayout';
import ThemeConfig from './theme';
import { SYSTEM_PAGE } from './utils/pageTypes';
import { defaultTo } from './utils/nullable';
import { DEFAULT_CONTACT_PAGE_NAME } from './utils/constants';
import ContactView from './components/contact/ContactView';

ContactPageTemplate.propTypes = {
  context: PropTypes.object,
  logo: PropTypes.object,
  config: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
  currenciesLoading: PropTypes.bool.isRequired,
  cartTotal: PropTypes.number.isRequired,
  handleContactSuccess: PropTypes.func.isRequired,
  // Common functions
  handleChangeCurrency: PropTypes.func.isRequired,
  handleNavigationClick: PropTypes.func.isRequired,
  handleGetNavigation: PropTypes.func.isRequired,
  handleGetStoreInformation: PropTypes.func.isRequired,
  handleCreateCustomer: PropTypes.func.isRequired,
  handleOnPageEnter: PropTypes.func
};

export default function ContactPageTemplate({
  context,
  logo,
  config,
  cartTotal,
  currency,
  currencies,
  currenciesLoading,
  handleChangeCurrency,
  handleContactSuccess,
  handleNavigationClick,
  handleGetNavigation,
  handleGetNavigations,
  handleGetStoreInformation,
  handleCreateCustomer,
  handleOnPageEnter
}) {
  const { pagePath } = context;
  const navigation = { type: SYSTEM_PAGE, path: pagePath };
  const pageTitle = defaultTo(config?.contact?.metadata?.page, DEFAULT_CONTACT_PAGE_NAME);
  const pageDescription = defaultTo(config?.contact?.metadata?.description, '');

  return (
    <ThemeConfig customTheme={config?.global?.theme} settings={config?.settings}>
      <Page title={pageTitle} description={pageDescription} onPageEnter={handleOnPageEnter}>
        <TemplateLayout
          logo={logo}
          currency={currency}
          currencies={currencies}
          currenciesLoading={currenciesLoading}
          context={context}
          cartTotal={cartTotal}
          config={{
            ...config?.layout,
            ...config?.global?.theme,
            local: {
              layout: config?.contact?.layout
            }
          }}
          currentNavigation={navigation}
          handleChangeCurrency={handleChangeCurrency}
          handleNavigationClick={handleNavigationClick}
          handleGetNavigation={handleGetNavigation}
          handleGetNavigations={handleGetNavigations}
          handleGetStoreInformation={handleGetStoreInformation}
          handleCreateCustomer={handleCreateCustomer}
        >
          <ContactView context={context} config={config} page={pageTitle} handleContactSuccess={handleContactSuccess} />
        </TemplateLayout>
      </Page>
    </ThemeConfig>
  );
}
