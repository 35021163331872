import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export default function useMobile() {
  const theme = useTheme();
  const isForceMobile = theme.settings?.forceIsMobile;
  const isMobileThreshold = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = isForceMobile || isMobileThreshold;
  return isMobile;
}
