import React from 'react';
import { defaultTo } from 'lodash';
import { Box, Stack, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import Markdown from './Markdown';

const HeadingStack = styled(Stack)(({ theme }) => ({
    padding: theme.spacing(0, 3)
}));

const HeadingText = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary
}));

export const StandardPageHeading = ({ title, description, titleColour, captionColour }) => <HeadingStack spacing={2}>
    <HeadingText
        variant="h4"
        align="center"
        sx={{ fontWeight: 'medium', ...(titleColour && { color: titleColour }) }}
    >
        {title}
    </HeadingText>
    {description && (
        <Box sx={{ color: defaultTo(captionColour, 'text.primary') }}>
            <Markdown children={description} />
        </Box>
    )}
</HeadingStack>