import React, { useState, useRef, useEffect } from 'react';
import Slider from 'react-slick';
import { motion } from 'framer-motion';
import { defaultTo, isEmpty, orderBy } from 'lodash';
import { alpha, useTheme, styled } from '@material-ui/core/styles';
import { Box, Paper, Button, Typography, Stack } from '@material-ui/core';
import * as AspectRatioPrimitive from '@radix-ui/react-aspect-ratio';
import { getHorizontalPosition, getVerticalPosition } from '../../utils/getPositions';
import useMobile from '../../hooks/useMobile';
import { MLazyLoadImage } from '../core/@react-lazy-load-image-component-extend';
import { varFadeInRight, MotionContainer } from '../core/animate';
import { CarouselControlsArrowsIndex } from '../core/carousel';
import placeholderImage from '../../static/placeholder/Image_and_text.jpg';
import LayoutOrientation from '../core/LayoutOrientation';
import { getSelectedImage } from '../../utils/getSelectedImage';

export const AspectRatio = AspectRatioPrimitive;

const HEIGHT_CONSTRAINT_STYLE = {
  minHeight: '400px',
  maxHeight: '850px'
};

const CarouselContent = styled(Box)(({ theme }) => ({
  padding: theme.spacing(10),
  top: 0,
  bottom: 0,
  zIndex: 3,
  width: '100%',
  textAlign: 'left',
  position: 'absolute',
  color: theme.palette.common.white
}));

const CarouselOverlay = styled(Box)({
  top: 0,
  zIndex: 2,
  width: '100%',
  height: '100%',
  position: 'absolute'
});

const CarouselImgStyle = styled(MLazyLoadImage)({
  top: 0,
  width: '100%',
  height: '100%',
  ...HEIGHT_CONSTRAINT_STYLE
});

function CarouselItem({ item, isActive, handleNavigationClick }) {
  const theme = useTheme();
  const isMobile = useMobile();

  const {
    title,
    subtitle,
    showOverlay,
    desktopImage,
    mobileImage,
    contentPosition,
    buttonText,
    navigation,
    titleColour,
    subTitleColour
  } = item;

  const img = getSelectedImage(isMobile, desktopImage, mobileImage);

  return (
    <Paper
      sx={{
        position: 'relative',
        ...HEIGHT_CONSTRAINT_STYLE
      }}
    >
      <AspectRatio.Root ratio={isMobile ? 3 / 4 : 16 / 9}>
        <CarouselImgStyle alt={title} src={img || placeholderImage} imgSx={{ borderRadius: 0 }} />
      </AspectRatio.Root>
      <CarouselOverlay
        sx={{
          ...(showOverlay && {
            backgroundImage: `linear-gradient(to top, ${theme.palette.grey[900]} 0%,${alpha(
              theme.palette.grey[900],
              0
            )} 100%)`
          })
        }}
      />
      <CarouselContent sx={{ ...(isMobile && { padding: (theme) => theme.spacing(3, 3, 8) }) }}>
        <MotionContainer open={isActive} sx={{ height: '100%' }}>
          <LayoutOrientation
            verticalPosition={getVerticalPosition(contentPosition)}
            horizontalPosition={getHorizontalPosition(contentPosition)}
          >
            <motion.div variants={varFadeInRight}>
              <Stack spacing={1} sx={{ textAlign: getHorizontalPosition(contentPosition) }}>
                {!isEmpty(title) && (
                  <Typography
                    variant="h3"
                    sx={{ color: (theme) => defaultTo(titleColour, theme.palette.common.white), maxHeight: '600px' }}
                  >
                    {title}
                  </Typography>
                )}
                {!isEmpty(subtitle) && (
                  <Typography
                    variant="h6"
                    sx={{ color: (theme) => defaultTo(subTitleColour, theme.palette.common.white) }}
                  >
                    {subtitle}
                  </Typography>
                )}
              </Stack>
            </motion.div>

            {!isEmpty(buttonText) && (
              <motion.div variants={varFadeInRight}>
                <Button variant="contained" onClick={() => handleNavigationClick(navigation?.type, navigation?.path)}>
                  {buttonText}
                </Button>
              </motion.div>
            )}
          </LayoutOrientation>
        </MotionContainer>
      </CarouselContent>
    </Paper>
  );
}

function CarouselImageAndText({ component, handleNavigationClick = () => {} }) {
  const { items, speedInSeconds, showDots, showArrows, isDraggable, autorotate, rtl } = component;
  const ordered = orderBy(items, 'order', 'asc');
  const carouselRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(rtl ? items.length - 1 : 0);

  const settings = {
    // DEFAULTS
    speed: 800,
    pauseOnHover: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // CONFIGURABLE
    autoplay: autorotate,
    autoplaySpeed: speedInSeconds * 1000,
    swipe: isDraggable,
    dots: showDots,
    arrows: showArrows,
    rtl,
    beforeChange: (_, next) => setCurrentIndex(next)
  };

  useEffect(() => {
    // Fix for editor not moving from previous state to new state
    if (autorotate) {
      carouselRef.current.slickPlay();
    }
  }, [autorotate]);

  const handlePrevious = () => {
    carouselRef.current.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current.slickNext();
  };

  return (
    <Box
      sx={{
        position: 'relative',
        ...HEIGHT_CONSTRAINT_STYLE
      }}
    >
      {!isEmpty(ordered) && (
        <>
          <Slider ref={carouselRef} {...settings}>
            {ordered.map((item, index) => (
              <CarouselItem
                key={item.id}
                item={item}
                isActive={index === currentIndex}
                handleNavigationClick={handleNavigationClick}
              />
            ))}
          </Slider>

          {showArrows && (
            <CarouselControlsArrowsIndex
              index={currentIndex}
              total={ordered.length}
              onNext={handleNext}
              onPrevious={handlePrevious}
            />
          )}
        </>
      )}
    </Box>
  );
}

export default CarouselImageAndText;
