import React from 'react';
import { Typography } from '@material-ui/core';
import { isEmpty, isNil } from 'lodash';
import {
  DEFAULT_NEXT_STEPS_TITLE,
  DEFAULT_UNKNOWN_ERROR_NEXT_STEPS_MESSAGE,
  fromEmailTemplate
} from '../../../../utils/constants';
import { SummarySection } from '../SummarySection';

export default function NextStepsUnknownErrorView({
  showBorder,
  title,
  message,
  email,
  sx: { title: titleSx, content: contentSx }
}) {
  const isMessageEmpty = isEmpty(message) || isNil(message);
  return (
    <SummarySection showBorder={showBorder} title={title || DEFAULT_NEXT_STEPS_TITLE} sx={titleSx}>
      {isMessageEmpty ? (
        <Typography
          variant="body1"
          dangerouslySetInnerHTML={{
            __html: DEFAULT_UNKNOWN_ERROR_NEXT_STEPS_MESSAGE(email)
          }}
          sx={{ color: 'text.secondary', ...contentSx }}
        />
      ) : (
        <Typography variant="body1" sx={{ color: 'text.secondary', ...contentSx }}>
          {!isMessageEmpty && fromEmailTemplate(message, email)}
        </Typography>
      )}
    </SummarySection>
  );
}
