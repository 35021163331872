import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
// material
import { styled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

const PageRoot = styled(Box)(() => ({
  width: '100%',
  height: '100%'
}));

const Page = forwardRef(({ children, title = '', description = '', onPageEnter = () => {}, ...other }, ref) => {
  useEffect(() => {
    onPageEnter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageRoot ref={ref} {...other}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      {children}
    </PageRoot>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  onPageEnter: PropTypes.func
};

export default Page;
