import React from 'react';
import { Stack, Divider, Typography } from '@material-ui/core';

export const CartVariantView = ({ colour, size }) => (
  <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
    {colour && (
      <Typography variant="body2">
        <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
          Colour:&nbsp;
        </Typography>
        {colour}
      </Typography>
    )}
    {size && (
      <Typography variant="body2">
        <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
          Size:&nbsp;
        </Typography>
        {size}
      </Typography>
    )}
  </Stack>
);
