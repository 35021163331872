import { min } from 'lodash';
import { DEFAULT_DESKTOP_GRID_SIZE } from './constants';

const DESKTOP_NUM_OF_ITEMS_THRESHOLD = 2;

export const getItemsPerRow = (itemsPerRow, totalSize) => {
    if (totalSize === 0) {
        return 0;
    }
    return 12 / min([itemsPerRow, totalSize]);
};

export const getDesktopItemsPerRow = (itemsPerRow, totalSize) => {
    // A default view is total products is 1. To avoid one product taking up an entire screen
    if (totalSize > 0 && totalSize < DESKTOP_NUM_OF_ITEMS_THRESHOLD) {
        return DEFAULT_DESKTOP_GRID_SIZE
    }
    // A default view is configured for more than threshold but count is below threshold
    if (itemsPerRow > DESKTOP_NUM_OF_ITEMS_THRESHOLD && totalSize < itemsPerRow) {
        return DEFAULT_DESKTOP_GRID_SIZE;
    }
    return getItemsPerRow(itemsPerRow, totalSize);
}