import React, { useCallback, useMemo } from 'react';
import {
  Alert,
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Stack,
  Switch
} from '@material-ui/core';
import { alpha, styled } from '@material-ui/core/styles';
import { defaultTo } from 'lodash';
import useMobile from '../../hooks/useMobile';
import {
  CHECKOUT_SELECTION_VIEW_TYPE,
  CHECKOUT_VIEW_TYPE,
  DEFAULT_PRE_PAYMENT_CHECKOUT_ERROR_MESSAGE
} from '../../utils/constants';
import { getEnabledPayments, getPaymentByKey, STRIPE_PAYMENT_PROVIDER_KEY } from '../../utils/payments';
import { SkeletonForm } from '../core/SkeletonForm';
import { StripeExpressCheckoutElement } from './StripeElement';
import { SkeletonSingleOption } from '../core/SkeletonFormOption';
import MTextField from '../core/@material-extend/MTextField';
import { CheckoutTitle } from './components/CheckoutTitle';

const CompactViewStack = styled(Stack)(({ theme }) => ({
  borderRadius: theme.shape.borderRadiusSm
}));

const STANDARD_VIEW_PROPS = {
  borderRadius: 0,
  backgroundColour: 'unset',
  padding: 0
};

export const InformationPage = ({
  loading,
  config,
  form,
  shippingCountries,
  paymentMethods: data,
  hasCheckoutError
}) => {
  const isLoading = loading.options.countries;
  const {
    information: { displayPayments, enable: isError, message }
  } = hasCheckoutError;

  const { general, information, payments } = config;

  const titlePosition = general?.titlePosition;
  const titleSize = general?.titleSize;
  const contactInformationTitle = information?.contactInformationTitle;
  const contactInformationViewType = information?.contactInformationViewType;
  const contactInformationBackgroundColour = information?.contactInformationBackgroundColour;
  const shippingAddressTitle = information?.shippingAddressTitle;
  const shippingAddressViewType = information?.shippingAddressViewType;
  const shippingAddressBackgroundColour = information?.shippingAddressBackgroundColour;
  const billingAddressTitle = information?.billingAddressTitle;
  const billingAddressViewType = information?.billingAddressViewType;
  const billingAddressBackgroundColour = information?.billingAddressBackgroundColour;
  const billingSameAddressOptionTitle = information?.billingSameAddressOptionTitle;
  const selectionView = information?.selectionView;
  const expressCheckoutTitle = payments?.expressCheckoutTitle;

  const { values, getFieldProps, touched, errors } = form;

  const paymentMethods = getEnabledPayments(data);
  const stripe = getPaymentByKey(STRIPE_PAYMENT_PROVIDER_KEY, paymentMethods);
  const paymentRequest = stripe?.paymentRequest;
  const country = getFieldProps('country');
  const address = getFieldProps('address');
  const city = getFieldProps('city');
  const postcode = getFieldProps('postcode');
  const billingCountry = getFieldProps('billingCountry');
  const billingAddress = getFieldProps('billingAddress');
  const billingCity = getFieldProps('billingCity');
  const billingPostcode = getFieldProps('billingPostcode');
  const {
    country: countryTouched,
    address: addressTouched,
    city: cityTouched,
    postcode: postcodeTouched,
    billingCountry: billingCountryTouched,
    billingAddress: billingAddressTouched,
    billingCity: billingCityTouched,
    billingPostcode: billingPostcodeTouched
  } = touched;
  const {
    country: countryErrors,
    address: addressErrors,
    city: cityErrors,
    postcode: postcodeErrors,
    billingCountry: billingCountryErrors,
    billingAddress: billingAddressErrors,
    billingCity: billingCityErrors,
    billingPostcode: billingPostcodeErrors
  } = errors;

  const isMobile = useMobile();
  const { billingAddressSameAsShipping } = values;

  const Title = (props) => <CheckoutTitle position={titlePosition} size={titleSize} {...props} />;

  const displayExpressCheckout = useCallback(() => {
    if (!paymentRequest) {
      return false;
    }
    const expressCheckoutLoading = paymentRequest?.loading || false;
    if (expressCheckoutLoading) {
      return true;
    }
    return displayPayments;
  }, [paymentRequest, displayPayments]);

  const shippingFieldProps = useMemo(() => {
    return {
      country: {
        name: country.name,
        value: country.value,
        onChange: country.onChange
      },
      address: {
        name: address.name,
        value: address.value,
        onChange: address.onChange
      },
      city: {
        name: city.name,
        value: city.value,
        onChange: city.onChange
      },
      postcode: {
        name: postcode.name,
        value: postcode.value,
        onChange: postcode.onChange
      }
    };
  }, [
    country.name,
    country.value,
    country.onChange,
    address.name,
    address.value,
    address.onChange,
    city.name,
    city.value,
    city.onChange,
    postcode.name,
    postcode.value,
    postcode.onChange
  ]);

  const shipppingTouched = useMemo(() => {
    return {
      country: countryTouched,
      address: addressTouched,
      city: cityTouched,
      postcode: postcodeTouched
    };
  }, [countryTouched, addressTouched, cityTouched, postcodeTouched]);

  const shippingErrors = useMemo(() => {
    return {
      country: countryErrors,
      address: addressErrors,
      city: cityErrors,
      postcode: postcodeErrors
    };
  }, [countryErrors, addressErrors, cityErrors, postcodeErrors]);

  const ShippingAddress = useMemo(() => {
    return (
      <AddressInput
        key="shippingAddress"
        fieldProps={shippingFieldProps}
        touched={shipppingTouched}
        errors={shippingErrors}
        shippingCountries={shippingCountries}
        gridSizing={{ xs: isMobile ? 12 : 6 }}
        shape={config?.input}
      />
    );
  }, [isMobile, config?.input, shippingCountries, shippingFieldProps, shipppingTouched, shippingErrors]);

  const billingFieldProps = useMemo(() => {
    return {
      country: {
        name: billingCountry.name,
        value: billingCountry.value,
        onChange: billingCountry.onChange
      },
      address: {
        name: billingAddress.name,
        value: billingAddress.value,
        onChange: billingAddress.onChange
      },
      city: {
        name: billingCity.name,
        value: billingCity.value,
        onChange: billingCity.onChange
      },
      postcode: {
        name: billingPostcode.name,
        value: billingPostcode.value,
        onChange: billingPostcode.onChange
      }
    };
  }, [
    billingCountry.name,
    billingCountry.value,
    billingCountry.onChange,
    billingAddress.name,
    billingAddress.value,
    billingAddress.onChange,
    billingCity.name,
    billingCity.value,
    billingCity.onChange,
    billingPostcode.name,
    billingPostcode.value,
    billingPostcode.onChange
  ]);

  const billingTouched = useMemo(() => {
    return {
      country: billingCountryTouched,
      address: billingAddressTouched,
      city: billingCityTouched,
      postcode: billingPostcodeTouched
    };
  }, [billingCountryTouched, billingAddressTouched, billingCityTouched, billingPostcodeTouched]);

  const billingErrors = useMemo(() => {
    return {
      country: billingCountryErrors,
      address: billingAddressErrors,
      city: billingCityErrors,
      postcode: billingPostcodeErrors
    };
  }, [billingCountryErrors, billingAddressErrors, billingCityErrors, billingPostcodeErrors]);

  const BillingAddress = useMemo(() => {
    return (
      <AddressInput
        key="billingAddress"
        fieldProps={billingFieldProps}
        touched={billingTouched}
        errors={billingErrors}
        shippingCountries={shippingCountries}
        gridSizing={{ xs: isMobile ? 12 : 6 }}
        shape={config?.input}
      />
    );
  }, [isMobile, config?.input, shippingCountries, billingFieldProps, billingTouched, billingErrors]);

  return (
    <Stack spacing={8}>
      {isLoading ? (
        <SkeletonForm />
      ) : (
        <>
          {isError && <PaymentCheckoutError message={message} />}
          {displayExpressCheckout() && (
            <Stack spacing={5}>
              {stripe.paymentRequest.loading ? (
                <SkeletonSingleOption />
              ) : (
                <Stack spacing={1.25}>
                  <Title>{expressCheckoutTitle || 'Quick Checkout'}</Title>
                  <StripeExpressCheckoutElement config={stripe.paymentRequest} />
                </Stack>
              )}
              <Box>
                <Divider
                  textAlign="center"
                  sx={{
                    '&::before, &::after': {
                      borderColor: (theme) => alpha(theme.palette.divider, 0.24)
                    }
                  }}
                >
                  <Chip label="or" sx={{ color: (theme) => theme.palette.divider }} />
                </Divider>
              </Box>
            </Stack>
          )}

          <CompactViewStack
            spacing={3}
            sx={{
              ...(contactInformationViewType === CHECKOUT_VIEW_TYPE[0] && STANDARD_VIEW_PROPS),
              ...(contactInformationViewType === CHECKOUT_VIEW_TYPE[1] && {
                padding: (theme) => theme.spacing(3),
                ...(contactInformationBackgroundColour && { backgroundColor: contactInformationBackgroundColour })
              })
            }}
          >
            <Title>{contactInformationTitle || 'Contact Information'}</Title>
            <Information
              form={form}
              gridSizing={{ xs: isMobile ? 12 : 6 }}
              shape={config?.input}
              config={{
                ...config?.information
              }}
            />
          </CompactViewStack>

          <CompactViewStack
            spacing={3}
            sx={{
              ...(shippingAddressViewType === CHECKOUT_VIEW_TYPE[0] && STANDARD_VIEW_PROPS),
              ...(shippingAddressViewType === CHECKOUT_VIEW_TYPE[1] && {
                padding: (theme) => theme.spacing(3),
                ...(shippingAddressBackgroundColour && { backgroundColor: shippingAddressBackgroundColour })
              })
            }}
          >
            <Title>{shippingAddressTitle || 'Shipping Address'}</Title>
            {ShippingAddress}
            <Box>
              <FormControlLabel
                label={billingSameAddressOptionTitle || 'Billing address same as shipping address'}
                control={
                  selectionView === CHECKOUT_SELECTION_VIEW_TYPE[0] ? (
                    <Checkbox
                      checked={billingAddressSameAsShipping}
                      {...getFieldProps('billingAddressSameAsShipping')}
                    />
                  ) : (
                    <Switch checked={billingAddressSameAsShipping} {...getFieldProps('billingAddressSameAsShipping')} />
                  )
                }
                sx={{ color: 'text.primary' }}
              />
              {touched.billingAddressSameAsShipping && errors.billingAddressSameAsShipping && (
                <FormHelperText error>
                  {touched.billingAddressSameAsShipping && errors.billingAddressSameAsShipping}
                </FormHelperText>
              )}
            </Box>
          </CompactViewStack>

          {!billingAddressSameAsShipping && (
            <CompactViewStack
              spacing={3}
              sx={{
                ...(billingAddressViewType === CHECKOUT_VIEW_TYPE[0] && STANDARD_VIEW_PROPS),
                ...(billingAddressViewType === CHECKOUT_VIEW_TYPE[1] && {
                  padding: (theme) => theme.spacing(3),
                  ...(billingAddressBackgroundColour && { backgroundColor: billingAddressBackgroundColour })
                })
              }}
            >
              <Title>{billingAddressTitle || 'Billing Address'}</Title>
              {BillingAddress}
            </CompactViewStack>
          )}
        </>
      )}
    </Stack>
  );
};

const AddressInput = ({ shape, shippingCountries, fieldProps, touched, errors, gridSizing, ...other }) => (
  <Box {...other}>
    <Grid container spacing={3} direction="column">
      <Grid item>
        <MTextField
          shape={shape}
          key="country"
          select
          fullWidth
          label="Country"
          placeholder="Country"
          {...fieldProps.country}
          SelectProps={{ native: true }}
          error={Boolean(touched.country && errors.country)}
          helperText={touched.country && errors.country}
        >
          {shippingCountries.map((option) => (
            <option key={option.code} value={option.code}>
              {option.label}
            </option>
          ))}
        </MTextField>
      </Grid>

      <Grid item>
        <MTextField
          shape={shape}
          key="address"
          fullWidth
          label="Address"
          {...fieldProps.address}
          error={Boolean(touched.address && errors.address)}
          helperText={touched.address && errors.address}
        />
      </Grid>

      <Grid item>
        <Grid container spacing={2}>
          <Grid item {...gridSizing}>
            <MTextField
              shape={shape}
              key="city"
              fullWidth
              label="City"
              {...fieldProps.city}
              error={Boolean(touched.city && errors.city)}
              helperText={touched.city && errors.city}
            />
          </Grid>
          <Grid item {...gridSizing}>
            <MTextField
              shape={shape}
              key="postcode"
              fullWidth
              label="Postcode"
              {...fieldProps.postcode}
              error={Boolean(touched.postcode && errors.postcode)}
              helperText={touched.postcode && errors.postcode}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Box>
);

const Information = ({
  form: { values, getFieldProps, touched, errors },
  gridSizing,
  shape,
  config: { selectionView, showEmailComminicationCheckbox, emailCommunicationTitle }
}) => {
  return (
    <Box>
      <Grid container spacing={3} direction="column">
        <Grid item>
          <MTextField
            shape={shape}
            key="email"
            fullWidth
            label="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <FormHelperText>This is used for receipt of your purchase</FormHelperText>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item {...gridSizing}>
              <MTextField
                shape={shape}
                key="firstname"
                fullWidth
                label="First Name"
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />
            </Grid>

            <Grid item {...gridSizing}>
              <MTextField
                shape={shape}
                key="lastname"
                fullWidth
                label="Last Name"
                {...getFieldProps('lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Grid>
          </Grid>
        </Grid>
        {showEmailComminicationCheckbox && (
          <Grid item>
            <Box>
              <FormControlLabel
                label={emailCommunicationTitle || 'Receive important news and promotions'}
                control={
                  selectionView === CHECKOUT_SELECTION_VIEW_TYPE[0] ? (
                    <Checkbox
                      checked={values.emailCommunicationAccepted}
                      {...getFieldProps('emailCommunicationAccepted')}
                    />
                  ) : (
                    <Switch
                      checked={values.emailCommunicationAccepted}
                      {...getFieldProps('emailCommunicationAccepted')}
                    />
                  )
                }
                sx={{ color: 'text.primary' }}
              />
              {touched.emailCommunicationAccepted && errors.emailCommunicationAccepted && (
                <FormHelperText error>
                  {touched.emailCommunicationAccepted && errors.emailCommunicationAccepted}
                </FormHelperText>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const PaymentCheckoutError = ({ message }) => (
  <Alert severity="error">{defaultTo(message, DEFAULT_PRE_PAYMENT_CHECKOUT_ERROR_MESSAGE)}</Alert>
);
