import { paramCase } from 'change-case';
import { defaultTo, find } from 'lodash';

// Key used when displaying preview in store editor
export const PAYMENT_METHOD_EXAMPLE_TYPE = 'example';

export const KLARNA_PAYMENT_PROVIDER_KEY = 'klarna';
export const STRIPE_PAYMENT_PROVIDER_KEY = 'stripe';

export const getEnabledPayments = (data) =>
  data.filter((o) => o.enabled || paramCase(defaultTo(o.type, '')) === PAYMENT_METHOD_EXAMPLE_TYPE);
export const getPaymentByKey = (key, data) =>
  find(data, (o) => paramCase(defaultTo(o.key, '')) === paramCase(defaultTo(key, '')));
