import React from 'react';
import { Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Loader } from './Loader';

const LoaderWrapper = styled(Box)({
  height: '200px'
});

export const LargeLoaderComponent = () => (
  <LoaderWrapper>
    <Loader />
  </LoaderWrapper>
);
