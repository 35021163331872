import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import { Divider, Stack } from '@material-ui/core';
import { defaultTo } from './utils/nullable';
import Page from './components/core/Page';
import TemplateLayout from './layout/TemplateLayout';
import {
  SimpleImageAndText,
  HomeCollectionProducts,
  HomeCollectionList,
  HomeFeaturedProduct,
  CarouselImageAndText,
  HomeGroupedImages
} from './components/home';
import ThemeConfig from './theme';
import { SYSTEM_PAGE } from './utils/pageTypes';
import { DEFAULT_HOME_PAGE_NAME } from './utils/constants';

HomePageTemplate.propTypes = {
  /** pagePath:
   * Current location path id e.g. /terms-and-conditions, /:product-name
   * For the home page this is static otherwise this will be a variable.
   * When set within the editor the value will be pre-defined as you navigate the editor.
   * When consumed by hosting app then this will be pulled from the url path. */
  context: PropTypes.object,
  config: PropTypes.object.isRequired,
  logo: PropTypes.object,
  currency: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
  currenciesLoading: PropTypes.bool.isRequired,
  handleGetProduct: PropTypes.func.isRequired,
  handleGetCollectionProducts: PropTypes.func.isRequired,
  handleGetCollections: PropTypes.func.isRequired,
  handleIsAddedToCart: PropTypes.func.isRequired,
  handleBuyNow: PropTypes.func.isRequired,
  handleAddToCart: PropTypes.func.isRequired,
  handleRemoveFromCart: PropTypes.func.isRequired,
  cartTotal: PropTypes.number.isRequired,
  // Common functions
  handleChangeCurrency: PropTypes.func.isRequired,
  handleNavigationClick: PropTypes.func.isRequired,
  handleGetNavigation: PropTypes.func.isRequired,
  handleGetNavigations: PropTypes.func.isRequired,
  handleGetStoreInformation: PropTypes.func.isRequired,
  handleCreateCustomer: PropTypes.func.isRequired,
  handleOnPageEnter: PropTypes.func
};

export default function HomePageTemplate({
  context,
  logo,
  config,
  cartTotal,
  currency: currencyObj,
  currencies,
  currenciesLoading,
  handleBuyNow,
  handleAddToCart,
  handleRemoveFromCart,
  handleGetProduct,
  handleGetCollectionProducts,
  handleGetCollections,
  handleIsAddedToCart,
  handleChangeCurrency,
  handleNavigationClick,
  handleGetNavigation,
  handleGetNavigations,
  handleGetStoreInformation,
  handleCreateCustomer,
  handleOnPageEnter
}) {
  const { pagePath } = context;
  const navigation = { type: SYSTEM_PAGE, path: pagePath };
  const theme = defaultTo(config?.global?.theme, {});
  const settings = defaultTo(config?.settings, {});
  const components = defaultTo(config?.home?.components, []);
  const currency = currencyObj?.symbol;

  const HomePageComponentsMemo = useMemo(() => {
    const ordered = orderBy(components, 'order', 'asc');
    return (
      <HomePageComponents
        config={config}
        components={ordered}
        currency={currency}
        handleBuyNow={handleBuyNow}
        handleAddToCart={handleAddToCart}
        handleRemoveFromCart={handleRemoveFromCart}
        handleIsAddedToCart={handleIsAddedToCart}
        handleGetProduct={handleGetProduct}
        handleGetCollectionProducts={handleGetCollectionProducts}
        handleGetCollections={handleGetCollections}
        handleNavigationClick={handleNavigationClick}
      />
    );
  }, [
    config,
    components,
    currency,
    handleBuyNow,
    handleAddToCart,
    handleRemoveFromCart,
    handleIsAddedToCart,
    handleGetProduct,
    handleGetCollectionProducts,
    handleGetCollections,
    handleNavigationClick
  ]);

  return (
    <ThemeConfig customTheme={theme} settings={settings}>
      <Page
        title={defaultTo(config?.home?.metadata?.page, DEFAULT_HOME_PAGE_NAME)}
        description={defaultTo(config?.home?.metadata?.description, '')}
        onPageEnter={handleOnPageEnter}
      >
        <TemplateLayout
          logo={logo}
          currency={currencyObj}
          currencies={currencies}
          currenciesLoading={currenciesLoading}
          context={context}
          cartTotal={cartTotal}
          config={{
            ...config?.layout,
            ...config?.global?.theme,
            local: {
              layout: config?.home?.layout
            }
          }}
          currentNavigation={navigation}
          handleChangeCurrency={handleChangeCurrency}
          handleNavigationClick={handleNavigationClick}
          handleGetNavigation={handleGetNavigation}
          handleGetNavigations={handleGetNavigations}
          handleGetStoreInformation={handleGetStoreInformation}
          handleCreateCustomer={handleCreateCustomer}
        >
          {HomePageComponentsMemo}
        </TemplateLayout>
      </Page>
    </ThemeConfig>
  );
}

export const HomePageComponents = ({
  config,
  components,
  handleIsAddedToCart,
  handleGetProduct,
  handleGetCollectionProducts,
  handleGetCollections,
  ...other
}) => {
  const { showDivider } = config?.layout?.body;
  return (
    <Stack
      direction="column"
      {...(showDivider && { divider: <Divider sx={{ width: '100%' }} orientation="horizontal" /> })}
    >
      {components.map((component) => {
        switch (component?.key) {
          case 'IMAGE_TEXT':
            return <SimpleImageAndText key={component.id} component={component} {...other} />;
          case 'CAROUSEL':
            return <CarouselImageAndText key={component.id} component={component} {...other} />;
          case 'GROUPED_IMAGES':
            return <HomeGroupedImages key={component.id} component={component} {...other} />;
          case 'COLLECTION':
            return (
              <HomeCollectionProducts
                key={component.id}
                config={config}
                component={component}
                handleGetCollectionProducts={handleGetCollectionProducts}
                {...other}
              />
            );
          case 'COLLECTION_LIST':
            return (
              <HomeCollectionList
                key={component.id}
                component={component}
                handleGetCollections={handleGetCollections}
                {...other}
              />
            );
          case 'FEATURED_PRODUCT':
            return (
              <HomeFeaturedProduct
                key={component.id}
                config={config}
                component={component}
                handleGetProduct={handleGetProduct}
                handleIsAddedToCart={handleIsAddedToCart}
                {...other}
              />
            );
          default:
            return null;
        }
      })}
    </Stack>
  );
};
