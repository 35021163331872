import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Stack, Box, Container } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { styled } from '@material-ui/core/styles';
import { defaultTo } from './utils/nullable';
import useMobile from './hooks/useMobile';
import Page from './components/core/Page';
import TemplateLayout from './layout/TemplateLayout';
import ThemeConfig from './theme';
import { CUSTOM_PAGE } from './utils/pageTypes';
import Markdown from './components/core/Markdown';
import {
  MOBILE_STACK_SPACING,
  DESKTOP_STACK_SPACING,
  DESKTOP_CONTAINER_TOP_BOTTOM_PADDING,
  MOBILE_CONTAINER_TOP_BOTTOM_PADDING
} from './utils/constants';
import { StandardPageHeading } from './components/core/StandardPageHeading';

const Content = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(DESKTOP_CONTAINER_TOP_BOTTOM_PADDING),
  marginBottom: theme.spacing(DESKTOP_CONTAINER_TOP_BOTTOM_PADDING)
}));

CustomPageTemplate.propTypes = {
  context: PropTypes.object,
  logo: PropTypes.object,
  config: PropTypes.object.isRequired,
  cartTotal: PropTypes.number.isRequired,
  currency: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
  currenciesLoading: PropTypes.bool.isRequired,
  page: PropTypes.object.isRequired,
  // Common functions
  handleChangeCurrency: PropTypes.func.isRequired,
  handleNavigationClick: PropTypes.func.isRequired,
  handleGetNavigation: PropTypes.func.isRequired,
  handleGetNavigations: PropTypes.func.isRequired,
  handleGetStoreInformation: PropTypes.func.isRequired,
  handleCreateCustomer: PropTypes.func.isRequired,
  handleOnPageEnter: PropTypes.func
};

export default function CustomPageTemplate({
  context,
  logo,
  config,
  page,
  cartTotal,
  currency,
  currencies,
  currenciesLoading,
  handleChangeCurrency,
  handleNavigationClick,
  handleGetNavigation,
  handleGetNavigations,
  handleGetStoreInformation,
  handleCreateCustomer,
  handleOnPageEnter
}) {
  const { pagePath } = context;
  const { title, body } = page;
  const { showDivider } = config?.layout?.body;
  const { showTitle, titleColour, widthView } = config?.custom.general;
  const navigation = { type: CUSTOM_PAGE, path: pagePath };

  // METADAT
  const { page: customPageTitle, description: customPageDescription } = config?.custom.metadata;
  const pageTitle = defaultTo(customPageTitle, title);
  const pageDescription = defaultTo(customPageDescription, '');

  const ComponentView = () => {
    const isMobile = useMobile();
    const stackSpacing = isMobile ? MOBILE_STACK_SPACING : DESKTOP_STACK_SPACING;
    const isNarrowView = !isMobile && widthView === 'STANDARD';

    return (
      <Content
        spacing={stackSpacing}
        {...(showDivider ? { divider: <Divider sx={{ width: '100%' }} orientation="horizontal" /> } : null)}
        direction="column"
        alignItems="center"
        sx={{
          ...(isMobile && {
            marginTop: (theme) => theme.spacing(MOBILE_CONTAINER_TOP_BOTTOM_PADDING),
            marginBottom: (theme) => theme.spacing(MOBILE_CONTAINER_TOP_BOTTOM_PADDING)
          })
        }}
      >
        {showTitle && <StandardPageHeading title={pageTitle} titleColour={titleColour} />}
        <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'center' }}>
          {!isEmpty(body) && (
            <Box
              sx={{
                width: '100%',
                p: (theme) => theme.spacing(6),
                ...(isMobile && { p: (theme) => theme.spacing(6, 3) }),
                ...(isNarrowView && { width: 680, p: (theme) => theme.spacing(6, 0) })
              }}
            >
              <Markdown children={body} />
            </Box>
          )}
        </Container>
      </Content>
    );
  };

  return (
    <ThemeConfig customTheme={config?.global?.theme} settings={config?.settings}>
      <Page title={pageTitle} description={pageDescription} onPageEnter={handleOnPageEnter}>
        <TemplateLayout
          logo={logo}
          currency={currency}
          currencies={currencies}
          currenciesLoading={currenciesLoading}
          context={context}
          cartTotal={cartTotal}
          config={{
            ...config?.layout,
            ...config?.global?.theme,
            local: {
              layout: config?.custom?.layout
            }
          }}
          currentNavigation={navigation}
          handleChangeCurrency={handleChangeCurrency}
          handleNavigationClick={handleNavigationClick}
          handleGetNavigation={handleGetNavigation}
          handleGetNavigations={handleGetNavigations}
          handleGetStoreInformation={handleGetStoreInformation}
          handleCreateCustomer={handleCreateCustomer}
        >
          <ComponentView />
        </TemplateLayout>
      </Page>
    </ThemeConfig>
  );
}
