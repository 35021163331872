import React from 'react';
import { Box, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, Stack, Typography } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { isEmpty } from 'lodash';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
import { alpha, styled } from '@material-ui/core/styles';
import useMobile from '../../hooks/useMobile';
import { fCurrency } from '../../utils/formatNumber';
import EmptyContent from '../core/EmptyContent';
import { isZero } from '../../utils/money';
import { SkeletonFormOption } from '../core/SkeletonFormOption';
import {
  DEFAULT_SHIPPING_OPTION_MISSING_TITLE,
  DEFAULT_SHIPPING_OPTION_MISSING_DESCRIPTION
} from '../../utils/constants';
import { CheckoutTitle } from './components/CheckoutTitle';
import { isInvalid } from '../../utils/nullable';

const ShippingOption = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2.5),
  justifyContent: 'space-between',
  borderRadius: theme.shape.textfieldBorderRadius,
  transition: theme.transitions.create('all'),
  border: `solid 1px ${alpha(theme.palette.border, 0.32)}`
}));

export const ShippingPage = ({ config, loading, form, shippingOptions, currency: currencyRoot }) => {
  const general = config?.general;
  const shipping = config?.shipping;
  const messaging = config?.messaging;
  const isLoading = loading.options.shipping;
  const { symbol: currency } = currencyRoot;

  const titlePosition = general?.titlePosition;
  const titleSize = general?.titleSize;
  const shippingOptionsTitle = shipping?.shippingOptionsTitle;
  const { setFieldValue, getFieldProps, touched, errors } = form;

  const isMobile = useMobile();
  const gridSizing = {
    xs: isMobile ? 12 : 6
  };

  const Title = (props) => <CheckoutTitle position={titlePosition} size={titleSize} {...props} />;

  const ShippingView = () =>
    isLoading ? (
      <SkeletonFormOption />
    ) : (
      <>
        <Stack spacing={2}>
          <Title>{shippingOptionsTitle || 'Shipping Options'}</Title>
          <RadioGroup
            {...getFieldProps('shipping')}
            onChange={(e) => setFieldValue('shipping', Number(e.target.value))}
          >
            <Box>
              <Grid container spacing={2} direction="column">
                <ShippingOptions options={shippingOptions} currency={currency} {...gridSizing} />
              </Grid>
            </Box>
          </RadioGroup>
          {Boolean(touched.shipping && errors.shipping) && (
            <FormHelperText error>{touched.shipping && errors.shipping}</FormHelperText>
          )}
        </Stack>
      </>
    );

  return (
    <>
      {isEmpty(shippingOptions) && !isLoading ? (
        <EmptyContent
          title={isInvalid(messaging?.title) ? DEFAULT_SHIPPING_OPTION_MISSING_TITLE : messaging?.title}
          description={
            isInvalid(messaging?.description) ? DEFAULT_SHIPPING_OPTION_MISSING_DESCRIPTION : messaging?.description
          }
          variant="h5"
        />
      ) : (
        <ShippingView />
      )}
    </>
  );
};

const ShippingOptions = ({ options, currency, ...other }) => {
  return options.map((option, index) => {
    const {
      key,
      price: { amount },
      title,
      description
    } = option;
    return (
      <Grid key={index} item {...other}>
        <ShippingOption>
          <FormControlLabel
            key={key}
            value={key}
            control={<Radio checkedIcon={<Icon icon={checkmarkCircle2Fill} />} />}
            label={
              <Box sx={{ ml: 1 }}>
                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                  {title}
                </Typography>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  {isZero(amount) ? 'Free' : fCurrency(amount, currency)}
                </Typography>
                {description && (
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {description}
                  </Typography>
                )}
              </Box>
            }
            sx={{ py: 3, flexGrow: 1, marginRight: 0, width: '100%' }}
          />
        </ShippingOption>
      </Grid>
    );
  });
};
