import React, { useState } from 'react';
import { defaultTo, find, isEmpty, isEqual, orderBy } from 'lodash';
import { paramCase } from 'change-case';
import { styled } from '@material-ui/core/styles';
import { Box, Button, Menu, MenuItem, Typography } from '@material-ui/core';
import { DEFAULT_CURRENCY_LABEL_TEXT } from '../../utils/constants';
import { getTextAlignPosition } from '../../utils/getPositions';
import MTextField from '../core/@material-extend/MTextField';
import inputStyle from './InputStyle';

const DEFAULT_CURRENCY_WIDTH = 150;
const DEFAULT_CURRENCY_BUTTON_WIDTH = 140;

const RootStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2, 1.5)
}));

const getCurrencyLabel = (option, showCurrencySymbol) => {
  if (isEqual(option.symbol, option.code)) {
    return option.code;
  }
  if (showCurrencySymbol) {
    return `(${option.symbol})  ${option.code}`;
  }
  return option.code;
};

const StoreCurrencyTextFieldPicker = ({
  fullWidth = false,
  config,
  primaryColour,
  loading,
  currency,
  options,
  onChangeCurrency = () => {}
}) => {
  const { code } = currency;
  const { title, position, textPosition, input, showTitle, showCurrencySymbol } = config;

  const handleChange = (value) => {
    const optionsOrEmpty = defaultTo(options, []);
    const found = find(optionsOrEmpty, (o) => paramCase(defaultTo(o.code, '')) === paramCase(defaultTo(value, '')));
    if (found) {
      const obj = {
        code: found.code,
        symbol: found.symbol
      };
      onChangeCurrency(obj);
    }
  };

  return (
    <RootStyle>
      <Box sx={{ padding: 0, textAlign: getTextAlignPosition(position), ...(fullWidth && { width: '100%' }) }}>
        <MTextField
          fullWidth
          select
          shape={input}
          disabled={loading}
          label={showTitle ? title || DEFAULT_CURRENCY_LABEL_TEXT : null}
          value={code}
          onChange={(e) => handleChange(e.target.value)}
          sx={{
            minWidth: DEFAULT_CURRENCY_WIDTH,
            width: fullWidth ? '100%' : 'auto',
            ...inputStyle(primaryColour)
          }}
          InputProps={{
            style: {
              ...(fullWidth && { width: '100%' }),
              textAlign: getTextAlignPosition(textPosition),
              color: primaryColour
            }
          }}
          InputLabelProps={{
            style: {
              color: primaryColour
            }
          }}
        >
          {defaultTo(options, []).map((option) => (
            <MenuItem key={option.code} value={option.code} sx={{ justifyContent: getTextAlignPosition(textPosition) }}>
              <Typography variant="body2">{getCurrencyLabel(option, showCurrencySymbol)}</Typography>
            </MenuItem>
          ))}
        </MTextField>
      </Box>
    </RootStyle>
  );
};

const StoreCurrencyButtonPicker = ({
  fullWidth = false,
  primaryColour,
  config,
  loading,
  currency,
  options,
  onChangeCurrency = () => {}
}) => {
  const { title, position, textPosition, showTitle, buttonsFilled, showCurrencySymbol } = config;
  const isButtonFilled = buttonsFilled === 'FILLED';

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleChange = (value) => {
    const optionsOrEmpty = defaultTo(options, []);
    const found = find(optionsOrEmpty, (o) => paramCase(defaultTo(o.code, '')) === paramCase(defaultTo(value, '')));

    if (found) {
      const obj = {
        code: found.code,
        symbol: found.symbol
      };
      onChangeCurrency(obj);
    }
    handleClose();
  };

  return (
    <RootStyle>
      <Box
        sx={{
          display: 'flex',
          padding: 0,
          justifyContent: getTextAlignPosition(position),
          ...(fullWidth && { width: '100%' })
        }}
      >
        <Box sx={{ ...(fullWidth && { width: '100%' }) }}>
          {showTitle && (
            <Typography
              variant="body2"
              sx={{
                mb: 1,
                textAlign: getTextAlignPosition(textPosition),
                color: primaryColour
              }}
            >
              {title || DEFAULT_CURRENCY_LABEL_TEXT}
            </Typography>
          )}
          <Button
            fullWidth={fullWidth}
            variant={isButtonFilled ? 'contained' : 'outlined'}
            color="primary"
            disableRipple
            onClick={handleOpen}
            sx={{
              minHeight: 2.5,
              padding: (theme) => theme.spacing(1),
              minWidth: fullWidth ? '100%' : DEFAULT_CURRENCY_BUTTON_WIDTH,
              width: 'auto'
            }}
            disabled={loading}
          >
            {getCurrencyLabel(currency, showCurrencySymbol)}
          </Button>
        </Box>
        <Menu
          keepMounted
          anchorEl={open}
          open={Boolean(open && !loading)}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
          PaperProps={{
            style: {
              minWidth: DEFAULT_CURRENCY_BUTTON_WIDTH
            }
          }}
        >
          {defaultTo(options, []).map((option) => (
            <MenuItem
              key={option.code}
              value={option.code}
              sx={{ justifyContent: getTextAlignPosition(textPosition) }}
              onClick={() => handleChange(option.code)}
            >
              <Typography variant="body2">{getCurrencyLabel(option, showCurrencySymbol)}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </RootStyle>
  );
};

export const StoreCurrencyPicker = ({ capability, config, currency, options, ...others }) => {
  const { enabled, type } = config;
  const sorted = orderBy(options, (o) => o.code, 'asc');

  if (!enabled || !capability || isEmpty(defaultTo(options, []))) {
    return null;
  }
  return type === 'BUTTON' ? (
    <StoreCurrencyButtonPicker config={config} currency={currency} options={sorted} {...others} />
  ) : (
    <StoreCurrencyTextFieldPicker config={config} currency={currency} options={sorted} {...others} />
  );
};
