// material
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { debounce, defaultTo, findIndex, isEmpty, isEqual, compact, filter } from 'lodash';
import {
  Box,
  Drawer,
  Collapse,
  Stack,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Slider,
  Chip
} from '@material-ui/core';
import { styled, alpha } from '@material-ui/core/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { paramCase } from 'change-case';
import { Icon } from '@iconify/react';
import CloseIcon from '@material-ui/icons/Close';
import plusIcon from '@iconify/icons-akar-icons/plus';
import minusIcon from '@iconify/icons-akar-icons/minus';
import {
  DEFAULT_FILTER_CLEAR_HEADING,
  DEFAULT_FILTER_HEADING,
  DEFAULT_FILTER_ITEM_EXPANDED,
  DRAWER_WIDTH,
  FILTERS_PRICES_KEY
} from '../../utils/constants';
import useMobile from '../../hooks/useMobile';
import { fCurrencyFromMinorUnits, formatted } from '../../utils/formatNumber';

const AccordionStyle = styled(Accordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    borderRadius: 0,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    padding: theme.spacing(0),
    width: '100%'
  }
}));

const CollapseStyle = styled(Collapse)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius
}));

const SelectedFiltersContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 0)
}));

const SelectedFilterChip = styled(Chip)(({ theme }) => ({
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1)
}));

const CustomDivider = ({ colour, ...other }) => (
  <Divider {...other} sx={{ ...(colour && { borderColor: alpha(colour, 0.24) }) }} />
);

const getInitialValues = (data) => {
  return {
    colours: getInitialColours(data),
    sizes: getInitialSize(data),
    status: getInitialStatus(data),
    prices: getInitialPrices(data)
  };
};

const getInitialColours = (data) => {
  return data.colours?.options.map((option) => {
    return {
      id: option.id,
      name: option.name,
      count: option.count,
      value: false
    };
  });
};

const getInitialSize = (data) => {
  return data.sizes?.options.map((option) => {
    return {
      id: option.id,
      name: option.name,
      count: option.count,
      value: false
    };
  });
};

const getInitialStatus = (data) => {
  return data.status?.options.map((option) => {
    return {
      id: option.id,
      name: option.name,
      count: option.count,
      value: false
    };
  });
};

const getIsPricesActive = (prices) => {
  if (!prices) {
    return false;
  }
  const initalPrices = [prices?.min, prices?.max];
  return Boolean(!isEmpty(prices) && !isEqual(initalPrices, prices?.value));
};

const getInitialPrices = (data) => {
  if (isEmpty(data.prices)) {
    return null;
  }
  return {
    id: FILTERS_PRICES_KEY,
    name: FILTERS_PRICES_KEY,
    min: Number(data.prices.min.amountInMinorUnits),
    minInMajorUnits: String(data.prices.min.amount),
    max: Number(data.prices.max.amountInMinorUnits),
    maxInMajorUnits: String(data.prices.max.amount),
    value: [Number(data.prices.min.amountInMinorUnits), Number(data.prices.max.amountInMinorUnits)]
  };
};

export const FilterDrawer = ({
  productsLoading: loading,
  data,
  currency,
  config,
  open,
  containerRef,
  handleToggleFilter,
  handleChangeFilters
}) => {
  const isFirstRun = useRef(true);
  const isMobile = useMobile();

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: getInitialValues(data)
  });

  const { values, setValues, setFieldValue, getFieldProps } = formik;

  const handleChangeFiltersProcessing = useCallback(
    (event) => {
      try {
        const status = filter(event?.status, { value: true }).map((filter) => filter.name);
        const sizes = filter(event?.sizes, { value: true }).map((filter) => filter.name);
        const colours = filter(event?.colours, { value: true }).map((filter) => filter.name);
        const prices = event?.prices;
        const isPricesActive = getIsPricesActive(prices);
        const activeFilters = {
          ...(!isEmpty(status) && { status }),
          ...(!isEmpty(sizes) && { sizes }),
          ...(!isEmpty(colours) && { colours }),
          ...(isPricesActive && { pricesMin: prices?.value[0] }),
          ...(isPricesActive && { pricesMax: prices?.value[1] })
        };
        handleChangeFilters(activeFilters);
      } catch (e) {
        console.error(e);
      }
    },
    [handleChangeFilters]
  );

  const handleDispatchFormEvent = useCallback(debounce(handleChangeFiltersProcessing, 500), []);

  useEffect(() => {
    const initial = getInitialValues(data);
    if (isFirstRun.current && isEqual(initial, values)) {
      isFirstRun.current = false;
      return;
    }
    handleDispatchFormEvent(values);
  }, [data, values, handleDispatchFormEvent]);

  const handleUpdateForm = useCallback(
    (propKey, id, newValue) => {
      const options = getFieldProps(propKey).value;
      if (!id) {
        setFieldValue(propKey, {
          ...options,
          value: newValue
        });
      } else {
        const index = findIndex(options, { id });
        if (index >= 0) {
          const arr = [...options];
          arr[index].value = newValue;
          setFieldValue(propKey, arr);
        }
      }
    },
    [setFieldValue, getFieldProps]
  );

  const handleClearForm = useCallback(() => {
    const initial = getInitialValues(data);
    if (!isEqual(initial, values)) {
      setValues({
        ...getInitialValues(data)
      });
    }
  }, [values, data, setValues]);

  const handleResetFilter = useCallback(
    (propKey, id) => {
      const initial = getInitialValues(data)[propKey];
      if (id) {
        const index = findIndex(initial, { id });
        const reset = initial[index];
        const update = [...values[propKey]];
        const updateIndex = findIndex(update, { id });
        update[updateIndex] = reset;
        setFieldValue(propKey, update);
        return;
      }
      setFieldValue(propKey, initial);
    },
    [data, values, setFieldValue]
  );

  const FilterContentView = (
    <FilterContent
      loading={loading}
      data={values}
      currency={currency}
      config={config}
      handleUpdateForm={handleUpdateForm}
      handleClearForm={handleClearForm}
      handleResetFilter={handleResetFilter}
    />
  );

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate>
        {!isMobile && (
          <DesktopDrawer config={config} open={open}>
            {FilterContentView}
          </DesktopDrawer>
        )}
        <MobileDrawer
          config={config}
          open={isMobile && open}
          containerRef={containerRef}
          handleToggleFilter={handleToggleFilter}
        >
          {FilterContentView}
        </MobileDrawer>
      </Form>
    </FormikProvider>
  );
};

export const DesktopDrawer = ({ config, open, children }) => {
  const { backgroundColour, filterDesktopFullHeight, showBorder } = config;
  return (
    <Box
      sx={{
        ...(open && { margin: (theme) => theme.spacing(0, 3) }),
        ...(filterDesktopFullHeight && { height: '100%' })
      }}
    >
      <CollapseStyle
        orientation="horizontal"
        timeout={250}
        in={open}
        sx={{
          ...(showBorder && { border: (theme) => `1px solid ${alpha(theme.palette.border, 0.32)}` }),
          ...(filterDesktopFullHeight && { height: '100%' }),
          ...(backgroundColour && { backgroundColor: backgroundColour })
        }}
      >
        {children}
      </CollapseStyle>
    </Box>
  );
};

export const MobileDrawer = ({ config, open, containerRef, handleToggleFilter, children }) => {
  const { backgroundColour, isFilterLeft } = config;
  return (
    <Drawer
      anchor={isFilterLeft ? 'left' : 'right'}
      container={containerRef.current}
      disableAutoFocus
      disableEnforceFocus
      variant="temporary"
      open={open}
      onClose={handleToggleFilter}
      PaperProps={{
        style: {
          position: 'absolute',
          ...(backgroundColour && { backgroundColor: backgroundColour })
        }
      }}
      BackdropProps={{ style: { position: 'absolute' } }}
      ModalProps={{
        style: {
          position: 'absolute'
        },
        keepMounted: true
      }}
      sx={{
        '& .MuiDrawer-paper': {
          position: 'absolute',
          boxSizing: 'border-box',
          width: DRAWER_WIDTH
        }
      }}
    >
      <Stack direction="column" spacing={2}>
        <Box sx={{ padding: (theme) => theme.spacing(2) }}>
          <IconButton onClick={handleToggleFilter}>
            <CloseIcon />
          </IconButton>
        </Box>
        {children}
      </Stack>
    </Drawer>
  );
};

const FilterContent = ({
  loading,
  data,
  currency,
  config: {
    heading,
    clearButtonHeading,
    enableStatus,
    enableSize,
    enableColour,
    enablePrice,
    showFilterResultCount,
    titleColour,
    contrastColour,
    buttonColour,
    showDivider,
    filterItemDefaultExpanded
  },
  handleUpdateForm,
  handleClearForm,
  handleRemoveFilter,
  handleResetFilter
}) => {
  const status = data?.status;
  const sizes = data?.sizes;
  const colours = data?.colours;
  const prices = data?.prices;
  const common = {
    config: {
      contrastColour,
      showFilterResultCount,
      filterItemDefaultExpanded
    },
    handleUpdateForm
  };
  return (
    <Box sx={{ width: DRAWER_WIDTH }}>
      <Stack direction="column" spacing={2} sx={{ p: (theme) => theme.spacing(3) }}>
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Typography variant="h6" sx={{ color: titleColour || 'text.primary' }}>
            {heading || DEFAULT_FILTER_HEADING}
          </Typography>
          <Typography
            variant="body2"
            {...(!loading && { onClick: handleClearForm })}
            sx={{
              fontWeight: (theme) => theme.typography.fontWeightBold,
              ...(loading
                ? {
                    color: (theme) => theme.palette.action.disabled
                  }
                : {
                    color: (theme) => defaultTo(buttonColour, theme.palette.primary.main),
                    cursor: 'pointer'
                  })
            }}
          >
            {clearButtonHeading || DEFAULT_FILTER_CLEAR_HEADING}
          </Typography>
        </Stack>
        <Stack
          {...(showDivider && {
            divider: <CustomDivider colour={contrastColour} />
          })}
          sx={{ ...(contrastColour && { color: contrastColour }) }}
        >
          {!isEmpty(compact([status, sizes, colours, prices])) && (
            <SelectedFilters
              loading={loading}
              currency={currency}
              data={data}
              handleRemoveFilter={handleRemoveFilter}
              handleResetFilter={handleResetFilter}
            />
          )}
          {Boolean(enableStatus && !isEmpty(status)) && (
            <GenericIterableFilterItem loading={loading} propKey="status" title="Status" options={status} {...common} />
          )}
          {Boolean(enableSize && !isEmpty(sizes)) && (
            <GenericIterableFilterItem loading={loading} propKey="sizes" title="Size" options={sizes} {...common} />
          )}
          {Boolean(enableColour && !isEmpty(colours)) && (
            <GenericIterableFilterItem
              loading={loading}
              propKey="colours"
              title="Colour"
              options={colours}
              {...common}
            />
          )}
          {Boolean(enablePrice && !isEmpty(prices)) && (
            <PriceFilterItem loading={loading} propKey="prices" currency={currency} options={prices} {...common} />
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

const SelectedFilters = ({ loading, currency, data, handleResetFilter }) => {
  const status = data?.status;
  const sizes = data?.sizes;
  const colours = data?.colours;
  const prices = data?.prices;
  const isPricesActive = getIsPricesActive(prices);

  const getKey = (propKey, id) => `filtered-${paramCase(propKey)}-${id}`;

  return (
    <SelectedFiltersContainer>
      {!isEmpty(status) &&
        filter(status, { value: true }).map((filter) => (
          <Box key={getKey('status', filter.id)} component="span">
            <SelectedFilterChip
              disabled={loading}
              label={filter.name}
              onDelete={() => handleResetFilter('status', filter.id)}
              color="primary"
            />
          </Box>
        ))}
      {!isEmpty(sizes) &&
        filter(sizes, { value: true }).map((filter) => (
          <Box key={getKey('sizes', filter.id)} component="span">
            <SelectedFilterChip
              disabled={loading}
              label={filter.name}
              onDelete={() => handleResetFilter('sizes', filter.id)}
              color="primary"
            />
          </Box>
        ))}
      {!isEmpty(colours) &&
        filter(colours, { value: true }).map((filter) => (
          <Box key={getKey('colours', filter.id)} component="span">
            <SelectedFilterChip
              disabled={loading}
              label={filter.name}
              onDelete={() => handleResetFilter('colours', filter.id)}
              color="primary"
            />
          </Box>
        ))}
      {isPricesActive && (
        <Box key="prices" component="span">
          <SelectedFilterChip
            disabled={loading}
            label={`${fCurrencyFromMinorUnits(prices.value[0], currency)} - ${fCurrencyFromMinorUnits(
              prices.value[1],
              currency
            )}`}
            onDelete={() => handleResetFilter('prices')}
            color="primary"
          />
        </Box>
      )}
    </SelectedFiltersContainer>
  );
};

const PriceFilterItem = ({
  loading,
  propKey,
  currency,
  options,
  config: { contrastColour, filterItemDefaultExpanded },
  handleUpdateForm
}) => {
  const defaultStep = 1;
  const { min, minInMajorUnits, max, maxInMajorUnits, value } = options;
  const [state, setState] = useState(value);

  useEffect(() => {
    setState((prev) => {
      if (!isEqual(value, prev)) {
        return value;
      }
      return prev;
    });
  }, [value]);

  const handleUpdatePrice = useCallback(debounce(handleUpdateForm, 1000), []);

  return (
    <FilterItem colour={contrastColour} filterItemDefaultExpanded={filterItemDefaultExpanded} title="Price">
      <Box sx={{ width: '100%' }}>
        <Slider
          disabled={loading}
          step={defaultStep}
          marks={[
            {
              value: min,
              label: (
                <Typography variant="caption" sx={{ fontSize: '13px', color: contrastColour }}>
                  {formatted(minInMajorUnits)}
                </Typography>
              )
            },
            {
              value: max,
              label: (
                <Typography variant="caption" sx={{ fontSize: '13px', color: contrastColour }}>
                  {formatted(maxInMajorUnits)}
                </Typography>
              )
            }
          ]}
          min={min}
          max={max}
          value={state}
          onChange={(_, newValue) => {
            setState(newValue);
            handleUpdatePrice(propKey, null, newValue);
          }}
          valueLabelDisplay="auto"
          getAriaValueText={(value) => fCurrencyFromMinorUnits(value, currency)}
          valueLabelFormat={(value) => fCurrencyFromMinorUnits(value, currency)}
          sx={{ ...(!isEmpty(contrastColour) && { color: contrastColour }) }}
        />
      </Box>
    </FilterItem>
  );
};

const GenericIterableFilterItem = ({
  loading,
  propKey,
  title,
  options,
  config: { contrastColour, showFilterResultCount, filterItemDefaultExpanded },
  handleUpdateForm
}) => {
  return (
    <FilterItem title={title} colour={contrastColour} filterItemDefaultExpanded={filterItemDefaultExpanded}>
      <FormGroup>
        {options.map((option) => {
          const key = `${paramCase(propKey)}-${paramCase(option.id)}`;
          return (
            <GenericStatefulFilterItem
              key={key}
              loading={loading}
              option={option}
              propKey={propKey}
              contrastColour={contrastColour}
              showFilterResultCount={showFilterResultCount}
              handleUpdateForm={handleUpdateForm}
            />
          );
        })}
      </FormGroup>
    </FilterItem>
  );
};

const GenericStatefulFilterItem = ({
  loading,
  propKey,
  option,
  contrastColour,
  showFilterResultCount,
  handleUpdateForm
}) => {
  const { value } = option;
  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={loading}
          color="primary"
          checked={value}
          onChange={(e) => {
            handleUpdateForm(propKey, option.id, e.target.checked);
          }}
          sx={{ ...(contrastColour && { color: contrastColour }) }}
        />
      }
      label={
        <Typography
          component="span"
          variant="body2"
          sx={{ ...(contrastColour && { color: contrastColour }), display: 'flex' }}
        >
          {option.name}
          {showFilterResultCount && (
            <>
              &nbsp;
              <Typography
                variant="body2"
                sx={{ color: (theme) => defaultTo(contrastColour, theme.palette.text.secondary) }}
              >
                {`(${option.count})`}
              </Typography>
            </>
          )}
        </Typography>
      }
    />
  );
};

const FilterItem = ({ title, colour, filterItemDefaultExpanded, children }) => {
  const defaultItemExpanded = defaultTo(filterItemDefaultExpanded, DEFAULT_FILTER_ITEM_EXPANDED);
  const [expanded, setExpanded] = useState(defaultItemExpanded);

  const iconProps = {
    width: 20,
    height: 20,
    color: 'inherit'
  };

  const handleChange = (_, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <Box>
      <AccordionStyle defaultExpanded={defaultItemExpanded} disableGutters elevation={0} onChange={handleChange}>
        <AccordionSummary
          expandIcon={
            <Box component="span" sx={{ color: (theme) => colour || theme.palette.primary.main }}>
              {expanded ? <Icon icon={minusIcon} {...iconProps} /> : <Icon icon={plusIcon} {...iconProps} />}
            </Box>
          }
          sx={{ padding: (theme) => theme.spacing(0) }}
        >
          <Box sx={{ display: 'flex', width: '100%', padding: (theme) => theme.spacing(1, 0) }}>
            <Typography
              variant="body1"
              sx={{
                flexGrow: 1,
                ...(colour && { color: colour }),
                fontWeight: (theme) => theme.typography.fontWeightBold,
                padding: (theme) => theme.spacing(0, 1.25)
              }}
            >
              {title}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </AccordionStyle>
    </Box>
  );
};
