// ----------------------------------------------------------------------

export const DEFAULT_SHAPE = {
  borderRadius: 8,
  borderRadiusSm: 12,
  borderRadiusMd: 16,
  borderRadiusCircle: '50%'
};

export const SQUARED_SHAPE = {
  borderRadius: 0,
  borderRadiusSm: 0,
  borderRadiusMd: 0,
  borderRadiusCircle: '0%'
};

// Buttons
export const DEFAULT_BUTTON_SHAPE = {
  buttonBorderRadius: 8
};

export const SQUARE_BUTTON_SHAPE = {
  buttonBorderRadius: 0
};

export const OVAL_BUTTON_SHAPE = {
  buttonBorderRadius: 24
};

// Textfields
export const DEFAULT_TEXTFIELD_SHAPE = {
  textfieldBorderRadius: 8
};

export const SQUARE_TEXTFIELD_SHAPE = {
  textfieldBorderRadius: 0
};

// TAGS
export const DEFAULT_TAGS_SHAPE = {
  tagsBorderRadius: 8
};

export const SQUARE_TAGS_SHAPE = {
  tagsBorderRadius: 0
};

export const OVAL_TAGS_SHAPE = {
  tagsBorderRadius: 16
};

// COLOUR INDICATORS
export const DEFAULT_COLOUR_INDICATOR_SHAPE = {
  colourIndicatorsBorderRadius: '100%'
};

export const SQUARED_COLOUR_INDICATOR_SHAPE = {
  colourIndicatorsBorderRadius: '0%'
};

const getTagsIndicatorShape = (config) => {
  if (config === 'SQUARED') {
    return SQUARE_TAGS_SHAPE;
  }
  if (config === 'OVAL') {
    return OVAL_TAGS_SHAPE;
  }
  return DEFAULT_TAGS_SHAPE;
};

const getButtonShape = (config) => {
  if (config === 'SQUARED') {
    return SQUARE_BUTTON_SHAPE;
  }
  if (config === 'OVAL') {
    return OVAL_BUTTON_SHAPE;
  }
  return DEFAULT_BUTTON_SHAPE;
};

export const buildThemeShape = (config) => {
  if (!config) {
    return {
      ...DEFAULT_BUTTON_SHAPE,
      ...DEFAULT_TEXTFIELD_SHAPE,
      ...DEFAULT_TAGS_SHAPE,
      ...DEFAULT_SHAPE
    };
  }

  return {
    isButtonSquare: config.buttonShapeType === 'SQUARED',
    isButtonOval: config.buttonShapeType === 'OVAL',
    ...(config.defaultShapeType === 'SQUARED' ? SQUARED_SHAPE : DEFAULT_SHAPE),
    ...(config.colourIndicatorShapeType === 'SQUARED'
      ? SQUARED_COLOUR_INDICATOR_SHAPE
      : DEFAULT_COLOUR_INDICATOR_SHAPE),
    ...getButtonShape(config.buttonShapeType),
    ...(config.textfieldShapeType === 'SQUARED' ? SQUARE_TEXTFIELD_SHAPE : DEFAULT_TEXTFIELD_SHAPE),
    ...getTagsIndicatorShape(config.tagShapeType)
  };
};
