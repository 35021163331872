import React from 'react';
import { Icon } from '@iconify/react';
import { Stack, Box, Button, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import shoppingCartOutline from '@iconify/icons-eva/shopping-cart-outline';
import { isEmpty } from 'lodash';
import { HOME_PATH, SYSTEM_PAGE, CONTACT_PATH } from '../../utils/pageTypes';
import {
  OrderMessageView,
  DiscountMessageView,
  NextStepsMessageView,
  OrderInformationView,
  ContactDetailsView,
  NextStepsPaymentErrorView,
  NextStepsPostPaymentErrorView,
  OrderMessagePaymentErrorView,
  OrderMessagePostPaymentErrorView,
  OrderMessageUnknownErrorView,
  NextStepsUnknownErrorView
} from './complete';
import useMobile from '../../hooks/useMobile';
import { Loader } from '../core/Loader';
import OrderMessageProcessingView from './complete/processing/OrderMessageProcessingView';
import { getTextAlignPosition, TEXT_POSITIONS } from '../../utils/getPositions';
import { DEFAULT_FONT_SIZES } from '../../theme/typography';
import {
  CHECKOUT_SUCCESS_STATUS,
  CHECKOUT_PROCESSING_STATUS,
  CHECKOUT_PAYMENT_FAILURE_STATUS,
  CHECKOUT_POST_PAYMENT_FAILURE_STATUS,
  CHECKOUT_UNKNOWN_FAILURE_STATUS,
  DEFAULT_CHECKOUT_LOADING_MESSAGE,
  DEFAULT_CHECKOUT_BACK_HOME_BUTTON_TITLE,
  DEFAULT_CHECKOUT_CONTACT_US_PREFIX_MESSAGE,
  GENERIC_FONT_SIZE,
  TEXT_VIEW_DIRECTION
} from '../../utils/constants';

const ClickableText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: 'pointer'
}));

export const CheckoutCompleteSummary = ({ checkoutStage, config: configRoot, handleNavigationClick }) => {
  const isMobile = useMobile();
  const config = configRoot?.checkout?.summary;
  const { titlePosition, titleSize, contentPosition, checkoutLoadingMessage, homeButtonTitle, contactUsPrefixMessage } =
    configRoot?.checkout?.general;

  const styles = {
    heading: {
      justifyContent: getTextAlignPosition(titlePosition),
      textAlign: getTextAlignPosition(titlePosition)
    },
    title: {
      textAlign: getTextAlignPosition(titlePosition),
      ...(titleSize === GENERIC_FONT_SIZE[1] && { fontSize: DEFAULT_FONT_SIZES.h4 })
    },
    content: {
      textAlign: getTextAlignPosition(contentPosition)
    }
  };

  const headingProps = (headingView) => {
    return {
      ...(headingView === TEXT_VIEW_DIRECTION[0]
        ? {
            direction: titlePosition === TEXT_POSITIONS[2] ? 'row-reverse' : 'row',
            alignItems: 'center'
          }
        : {
            direction: 'column',
            alignItems: getTextAlignPosition(titlePosition)
          })
    };
  };

  const { loading, summary, status } = checkoutStage;

  const ContactUsView = () => {
    return (
      <Typography variant="body2" sx={{ ...styles.content }}>
        {contactUsPrefixMessage || DEFAULT_CHECKOUT_CONTACT_US_PREFIX_MESSAGE}
        <ClickableText
          variant="body2"
          component="span"
          onClick={() => handleNavigationClick(SYSTEM_PAGE, CONTACT_PATH)}
        >
          &nbsp;Contact Us
        </ClickableText>
      </Typography>
    );
  };

  const ActionButtons = ({ title, isMobile, handleNavigationClick }) => (
    <Box sx={{ width: '100%', textAlign: 'right' }}>
      <Button
        {...(isMobile && { fullWidth: true })}
        variant="contained"
        endIcon={<Icon icon={shoppingCartOutline} />}
        onClick={() => handleNavigationClick(SYSTEM_PAGE, HOME_PATH)}
      >
        {title || DEFAULT_CHECKOUT_BACK_HOME_BUTTON_TITLE}
      </Button>
    </Box>
  );

  const CheckoutSuccessComponents = ({ config, summary, headingComponent }) => {
    const {
      showDiscountMessage,
      discount,
      showOrderInfo,
      orderInfoTitle,
      orderInfoCurrencyView,
      showContactDetails,
      contactDetailsTitle,
      showNextSteps,
      nextStepsTitle,
      nextStepsMessage,
      // Borders
      showDiscountBorder,
      showNextStepsBorder,
      showContactDetailsBorder,
      showOrderInfoBorder
    } = config;
    const { customer } = summary;
    return (
      <>
        <Stack spacing={4}>
          {headingComponent}
          {showDiscountMessage && !isEmpty(discount) && (
            <DiscountMessageView showBorder={showDiscountBorder} body={discount} />
          )}
          {showNextSteps && (
            <NextStepsMessageView
              showBorder={showNextStepsBorder}
              title={nextStepsTitle}
              message={nextStepsMessage}
              sx={styles}
            />
          )}
          {showContactDetails && (
            <ContactDetailsView
              showBorder={showContactDetailsBorder}
              title={contactDetailsTitle}
              customer={customer}
              sx={styles}
            />
          )}
          {showOrderInfo && (
            <OrderInformationView
              currencyView={orderInfoCurrencyView}
              showBorder={showOrderInfoBorder}
              title={orderInfoTitle}
              summary={summary}
              sx={styles}
            />
          )}
          <ContactUsView />
          <ActionButtons title={homeButtonTitle} isMobile={isMobile} handleNavigationClick={handleNavigationClick} />
        </Stack>
      </>
    );
  };

  const CheckoutFailureComponents = ({ children }) => {
    return (
      <>
        <Stack spacing={4}>
          {children}
          <ContactUsView />
          <ActionButtons title={homeButtonTitle} isMobile={isMobile} handleNavigationClick={handleNavigationClick} />
        </Stack>
      </>
    );
  };

  const CheckoutSuccessView = () => {
    const { headingView, heading, title, message, showHeaderIcon, showSummaryBorder } = config.success;
    const { id, customer } = summary;
    return (
      <>
        <CheckoutSuccessComponents
          config={config.success}
          summary={summary}
          headingComponent={
            <OrderMessageView
              headingProps={headingProps(headingView)}
              heading={heading}
              orderId={id}
              title={title}
              message={message}
              customer={customer}
              showBorder={showSummaryBorder}
              showHeaderIcon={showHeaderIcon}
              sx={styles}
            />
          }
        />
      </>
    );
  };

  const CheckoutProcessingView = () => {
    const { headingView, heading, title, message, showHeaderIcon, showSummaryBorder } = config.processing;
    const { id, customer } = summary;
    return (
      <>
        <CheckoutSuccessComponents
          config={config.processing}
          summary={summary}
          headingComponent={
            <OrderMessageProcessingView
              orderId={id}
              heading={heading}
              headingProps={headingProps(headingView)}
              title={title}
              message={message}
              customer={customer}
              showHeaderIcon={showHeaderIcon}
              showBorder={showSummaryBorder}
              sx={styles}
            />
          }
        />
      </>
    );
  };

  const CheckoutPaymentErrorView = () => {
    const {
      showHeaderIcon,
      heading,
      title,
      message,
      headingView,
      showNextSteps,
      nextStepsTitle,
      nextStepsMessage,
      showNextStepsBorder,
      showSummaryBorder
    } = config.paymentFailure;
    return (
      <CheckoutFailureComponents>
        <OrderMessagePaymentErrorView
          heading={heading}
          title={title}
          message={message}
          headingProps={headingProps(headingView)}
          showHeaderIcon={showHeaderIcon}
          showBorder={showSummaryBorder}
          sx={styles}
        />
        {showNextSteps && (
          <NextStepsPaymentErrorView
            showBorder={showNextStepsBorder}
            title={nextStepsTitle}
            message={nextStepsMessage}
            sx={styles}
          />
        )}
      </CheckoutFailureComponents>
    );
  };

  const CheckoutPostPaymentErrorView = () => {
    const {
      showHeaderIcon,
      heading,
      title,
      message,
      headingView,
      showNextSteps,
      nextStepsTitle,
      nextStepsMessage,
      showNextStepsBorder,
      showSummaryBorder
    } = config.postPaymentFailure;
    const { orderId: id, supportEmail } = summary;

    return (
      <CheckoutFailureComponents>
        <OrderMessagePostPaymentErrorView
          id={id}
          heading={heading}
          title={title}
          message={message}
          headingProps={headingProps(headingView)}
          showHeaderIcon={showHeaderIcon}
          showBorder={showSummaryBorder}
          sx={styles}
        />
        {showNextSteps && (
          <NextStepsPostPaymentErrorView
            showBorder={showNextStepsBorder}
            title={nextStepsTitle}
            message={nextStepsMessage}
            email={supportEmail}
            sx={styles}
          />
        )}
      </CheckoutFailureComponents>
    );
  };

  const CheckoutUnknownErrorView = () => {
    const {
      showHeaderIcon,
      heading,
      title,
      message,
      headingView,
      showNextSteps,
      nextStepsTitle,
      nextStepsMessage,
      showNextStepsBorder,
      showSummaryBorder
    } = config.unknownFailure;
    const { supportEmail } = summary;
    return (
      <CheckoutFailureComponents>
        <OrderMessageUnknownErrorView
          heading={heading}
          title={title}
          message={message}
          headingProps={headingProps(headingView)}
          showHeaderIcon={showHeaderIcon}
          showBorder={showSummaryBorder}
          sx={styles}
        />
        {showNextSteps && (
          <NextStepsUnknownErrorView
            showBorder={showNextStepsBorder}
            title={nextStepsTitle}
            message={nextStepsMessage}
            email={supportEmail}
            sx={styles}
          />
        )}
      </CheckoutFailureComponents>
    );
  };

  const CheckoutCompleteSummaryView = () => {
    switch (status) {
      case CHECKOUT_SUCCESS_STATUS:
        return <CheckoutSuccessView />;
      case CHECKOUT_PROCESSING_STATUS:
        return <CheckoutProcessingView />;
      case CHECKOUT_PAYMENT_FAILURE_STATUS:
        return <CheckoutPaymentErrorView />;
      case CHECKOUT_POST_PAYMENT_FAILURE_STATUS:
        return <CheckoutPostPaymentErrorView />;
      case CHECKOUT_UNKNOWN_FAILURE_STATUS:
        return <CheckoutUnknownErrorView />;
      default:
        return <CheckoutPostPaymentErrorView />;
    }
  };

  return loading ? (
    <Loader message={checkoutLoadingMessage || DEFAULT_CHECKOUT_LOADING_MESSAGE} sx={{ height: '200px' }} />
  ) : (
    <CheckoutCompleteSummaryView />
  );
};
