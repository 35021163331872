// Page names
export const DEFAULT_PRODUCTS_PAGE_NAME = 'All Products';
export const DEFAULT_HOME_PAGE_NAME = 'Home';
export const DEFAULT_PRODUCT_PAGE_NAME = 'Product';
export const DEFAULT_COLLECTION_LIST_PAGE_NAME = 'All Collections';
export const DEFAULT_COLLECTION_PAGE_NAME = 'Collection';
export const DEFAULT_CART_PAGE_NAME = 'Cart';
export const DEFAULT_CHECKOUT_PAGE_NAME = 'Checkout';
export const DEFAULT_CHECKOUT_PAGE_TITLE = 'Cart';
export const DEFAULT_CHECKOUT_PAGE_MOBILE_TITLE = 'Cart Summary';
export const DEFAULT_CONTACT_PAGE_NAME = 'Contact Us';
export const DEFAULT_ERROR_404_PAGE_NAME = '404';
export const DEFAULT_ERROR_500_PAGE_NAME = '500';
export const DEFAULT_PRODUCTS_RECENTLY_VIEWED_TITLE = 'Recently Viewed Products';
