import React from 'react';
import { Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

export const LinkHeaderText = styled((props) => <Typography variant="body1" {...props} />)(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  // fontSize: 13,
  fontWeight: 600,
  letterSpacing: 1
}));
