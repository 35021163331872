import React from 'react';
import PropTypes from 'prop-types';
import Page from './components/core/Page';
import TemplateLayout from './layout/TemplateLayout';
import { defaultTo } from './utils/nullable';
import ThemeConfig from './theme';
import { DEFAULT_ERROR_404_PAGE_DESCRIPTION, DEFAULT_ERROR_404_PAGE_NAME } from './utils/constants';
import GenericErrorPage from './components/error/GenericErrorPage';
import { SYSTEM_PAGE, HOME_PATH } from './utils/pageTypes';

Error404PageTemplate.propTypes = {
  context: PropTypes.object,
  logo: PropTypes.object,
  cartTotal: PropTypes.number.isRequired,
  config: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
  currenciesLoading: PropTypes.bool.isRequired,
  // Common functions
  handleChangeCurrency: PropTypes.func.isRequired,
  handleNavigationClick: PropTypes.func.isRequired,
  handleGetNavigation: PropTypes.func.isRequired,
  handleGetNavigations: PropTypes.func.isRequired,
  handleGetStoreInformation: PropTypes.func.isRequired,
  handleCreateCustomer: PropTypes.func.isRequired,
  handleOnPageEnter: PropTypes.func
};

export default function Error404PageTemplate({
  context,
  logo,
  cartTotal,
  config,
  currency,
  currencies,
  currenciesLoading,
  handleChangeCurrency,
  handleNavigationClick,
  handleGetNavigation,
  handleGetNavigations,
  handleGetStoreInformation,
  handleCreateCustomer,
  handleOnPageEnter
}) {
  const page = defaultTo(config?.error?.notFound?.metadata?.page, DEFAULT_ERROR_404_PAGE_NAME);
  const pageDescription = defaultTo(config?.error?.notFound?.messages?.description, DEFAULT_ERROR_404_PAGE_DESCRIPTION);

  return (
    <ThemeConfig customTheme={config?.global?.theme} settings={config?.settings}>
      <Page title={page} description={pageDescription} onPageEnter={handleOnPageEnter}>
        <TemplateLayout
          logo={logo}
          currency={currency}
          currencies={currencies}
          currenciesLoading={currenciesLoading}
          context={context}
          cartTotal={cartTotal}
          config={{
            ...config?.layout,
            ...config?.global?.theme,
            local: {
              layout: config?.error?.notFound?.layout
            }
          }}
          handleChangeCurrency={handleChangeCurrency}
          handleNavigationClick={handleNavigationClick}
          handleGetNavigation={handleGetNavigation}
          handleGetNavigations={handleGetNavigations}
          handleGetStoreInformation={handleGetStoreInformation}
          handleCreateCustomer={handleCreateCustomer}
        >
          <GenericErrorPage
            title={page}
            description={pageDescription}
            buttonText={config?.error?.notFound?.messages?.buttonText}
            handleNavigationClick={() => handleNavigationClick(SYSTEM_PAGE, HOME_PATH)}
          />
        </TemplateLayout>
      </Page>
    </ThemeConfig>
  );
}
