// ----------------------------------------------------------------------

import { defaultTo } from 'lodash';

export function pxToRem(value) {
  return `${value / 16}rem`;
}

function responsiveFontSizes({ sm, md, lg }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm.toFixed(2))
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md.toFixed(2))
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg.toFixed(2))
    }
  };
}

// const DEFAULT_FONT_PRIMARY = 'Public Sans, sans-serif'; // Google Font
// export const DEFAULT_FONT_PRIMARY = 'CircularStd, sans-serif'; // Local Font
export const DEFAULT_FONT_PRIMARY = 'CircularStd'; // Local Font

export const DEFAULT_FONT_SIZES = {
  h1: 40,
  h2: 32,
  h3: 24,
  h4: 20,
  h5: 18,
  h6: 17,
  textLarge: 16,
  textMedium: 14,
  textSmall: 12
};

export const buildTypography = (config) => {
  const sizes = defaultTo(config?.sizes, DEFAULT_FONT_SIZES);
  return {
    fontFamily: config?.fontFamily || DEFAULT_FONT_PRIMARY,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontWeight: 700,
      lineHeight: 80 / 64,
      fontSize: pxToRem(sizes.h1),
      // ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 })
      ...responsiveFontSizes({ sm: sizes.h1 * 1.3, md: sizes.h1 * 1.45, lg: sizes.h1 * 1.6 })
    },
    h2: {
      fontWeight: 700,
      lineHeight: 64 / 48,
      fontSize: pxToRem(sizes.h2),
      // ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 })
      ...responsiveFontSizes({ sm: sizes.h2 * 1.25, md: sizes.h2 * 1.375, lg: sizes.h2 * 1.5 })
    },
    h3: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(sizes.h3),
      // ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 })
      ...responsiveFontSizes({ sm: sizes.h3 * 1.08, md: sizes.h3 * 1.25, lg: sizes.h3 * 1.33 })
    },
    h4: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(sizes.h4),
      // ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 })
      ...responsiveFontSizes({ sm: sizes.h4, md: sizes.h4 * 1.2, lg: sizes.h4 * 1.2 })
    },
    h5: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(sizes.h5),
      // ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 })
      ...responsiveFontSizes({ sm: sizes.h5 * 1.05, md: sizes.h5 * 1.11, lg: sizes.h5 * 1.11 })
    },
    h6: {
      fontWeight: 700,
      lineHeight: 28 / 18,
      fontSize: pxToRem(sizes.h6),
      // ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 })
      ...responsiveFontSizes({ sm: sizes.h6 * 1.05, md: sizes.h6 * 1.05, lg: sizes.h6 * 1.05 })
    },
    subtitle1: {
      fontWeight: 600,
      lineHeight: 1.5,
      fontSize: pxToRem(sizes.textLarge)
    },
    subtitle2: {
      fontWeight: 600,
      lineHeight: 22 / 14,
      fontSize: pxToRem(sizes.textMedium)
    },
    body1: {
      lineHeight: 1.5,
      fontSize: pxToRem(sizes.textLarge)
    },
    body2: {
      lineHeight: 22 / 14,
      fontSize: pxToRem(sizes.textMedium)
    },
    caption: {
      lineHeight: 1.5,
      fontSize: pxToRem(sizes.textSmall)
    },
    overline: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(sizes.textSmall),
      letterSpacing: 1.1,
      textTransform: 'unset'
      // textTransform: 'uppercase'
    },
    button: {
      fontWeight: 700,
      lineHeight: 24 / 14,
      fontSize: pxToRem(sizes.textMedium),
      textTransform: 'unset'
      // textTransform: 'capitalize'
    }
  };
};
