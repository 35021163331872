// ----------------------------------------------------------------------

export default function Button(theme) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          ...(!theme.general.showButtonShadow && { boxShadow: 'none !important' }),
          padding: theme.shape.isButtonOval ? theme.spacing(1.3, 3) : theme.spacing(1.3, 2),
          whiteSpace: 'unset',
          minHeight: 48,
          borderRadius: theme.shape.buttonBorderRadius,
          '&:hover': {
            boxShadow: 'none'
          }
        },
        sizeLarge: {
          height: 48
        },
        // contained
        containedInherit: {
          color: theme.palette.grey[800],
          ...(theme.general.showButtonShadow && {
            boxShadow: theme.customShadows.z8
          }),
          '&:hover': {
            backgroundColor: theme.palette.grey[400]
          }
        },
        ...(theme.general.showButtonShadow && {
          containedPrimary: {
            boxShadow: theme.customShadows.primary
          },
          containedSecondary: {
            boxShadow: theme.customShadows.secondary
          },
          containedInfo: {
            boxShadow: theme.customShadows.info
          },
          containedSuccess: {
            boxShadow: theme.customShadows.success
          },
          containedWarning: {
            boxShadow: theme.customShadows.warning
          },
          containedError: {
            boxShadow: theme.customShadows.error
          }
        }),
        // outlined
        outlinedInherit: {
          border: `1px solid ${theme.palette.grey[500_32]}`,
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        },
        textInherit: {
          '&:hover': {
            backgroundColor: theme.palette.action.hover
          }
        }
      }
    }
  };
}
