import React, { useCallback } from 'react';
import { calculateTotal, getCartTotal } from '../../utils/cart';
import { SHIPPING_STEP } from '../../utils/checkoutUtils';
import { CartSummaryTable } from './CartSummaryTable';

// --------------------------

export const CartCalculation = ({
  config,
  loading,
  loadingDiscount,
  form,
  products,
  currency,
  shippingOptions,
  discountStore,
  handleDiscountCheck,
  onClearDiscount
}) => {
  const { values, getFieldProps, touched, errors } = form;
  const { activeStep, shipping: shippingKey } = values;
  const discountProps = getFieldProps('discount');

  const getSubtotal = useCallback(() => getCartTotal(products), [products]);

  const getShipppingAmount = useCallback(() => {
    const option = shippingOptions.find((_shipping) => _shipping.key === shippingKey);
    return option?.price?.amount;
  }, [shippingOptions, shippingKey]);

  const { isValidDiscount, discountAmount } = discountStore;
  const calcSubTotal = getSubtotal();
  const calcShippingAmount = getShipppingAmount();
  const discount = isValidDiscount ? discountAmount : null;
  const calcTotal = calculateTotal(calcSubTotal, calcShippingAmount, discount);
  const props = {
    discount: {
      value: discountProps.value,
      name: discountProps.name,
      onChange: discountProps.onChange,
      onBlur: discountProps.onBlur,
      touched: touched.discount,
      errors: errors.discount
    }
  };

  return (
    <CartSummaryTable
      currency={currency}
      config={config}
      loading={{
        table: loading.countries,
        discount: loadingDiscount
      }}
      showDiscount
      isShippingStep={activeStep >= SHIPPING_STEP}
      isValidDiscount={isValidDiscount}
      discount={discount}
      shipping={calcShippingAmount}
      subtotal={calcSubTotal}
      total={calcTotal}
      products={products}
      onValidateDiscount={handleDiscountCheck}
      onClearDiscount={onClearDiscount}
      props={props}
    />
  );
};
