import React, { useRef, useState, useCallback, useMemo, useEffect } from 'react';
import { defaultTo, isEmpty } from 'lodash';
import { Stack, Divider, Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import useMobile from '../../hooks/useMobile';
import Page from '../core/Page';
import TemplateLayout from '../../layout/TemplateLayout';
import ThemeConfig from '../../theme';
import { ProductsTable } from './ProductsTable';
import { ProductsToolbar } from './ProductsToolbar';
import { ProductsRecentlyViewedTable } from './ProductsRecentlyViewedTable';
import {
  MOBILE_STACK_SPACING,
  DESKTOP_STACK_SPACING,
  DEFAULT_FILTER_DESKTOP_EXPANDED,
  DESKTOP_CONTAINER_TOP_BOTTOM_PADDING,
  MOBILE_CONTAINER_TOP_BOTTOM_PADDING
} from '../../utils/constants';
import { FilterDrawer } from './FilterDrawer';
import { StandardPageHeading } from '../core/StandardPageHeading';

// --------

const Content = styled(Stack)(({ theme }) => ({
  position: 'relative',
  marginTop: theme.spacing(DESKTOP_CONTAINER_TOP_BOTTOM_PADDING),
  marginBottom: theme.spacing(DESKTOP_CONTAINER_TOP_BOTTOM_PADDING)
}));

export default function GeneralProductsTemplate(props) {
  const {
    logo,
    currency,
    currencies,
    currenciesLoading,
    context,
    navigation,
    config: { theme, layout, settings, metadata, local },
    cartTotal,
    handleNavigationClick,
    handleOnPageEnter,
    ...other
  } = props;
  const ref = useRef();
  const pageTitle = metadata?.page;
  const pageDescription = metadata?.description;

  return (
    <ThemeConfig customTheme={theme} settings={settings}>
      <Page title={pageTitle} description={pageDescription} onPageEnter={handleOnPageEnter}>
        <TemplateLayout
          logo={logo}
          ref={ref}
          currency={currency}
          currencies={currencies}
          currenciesLoading={currenciesLoading}
          context={context}
          cartTotal={cartTotal}
          config={{
            local,
            ...layout,
            ...theme
          }}
          currentNavigation={navigation}
          handleNavigationClick={handleNavigationClick}
          {...other}
        >
          <GenericProductsView currency={currency} containerRef={ref} {...props} />
        </TemplateLayout>
      </Page>
    </ThemeConfig>
  );
}

const GenericProductsView = ({
  containerRef,
  title,
  description,
  config: { layout, root, theme, messages },
  currency: currencyObj,
  loading = false,
  products,
  recentlyViewedProducts,
  queryParams,
  filters,
  handleLoadMore,
  handleChangeFilters,
  handleChangeOrder,
  handleNavigationClick
}) => {
  const isMobile = useMobile();
  const currency = currencyObj?.symbol;
  const { showDivider } = layout?.body;
  const { general, table, toolbar, filter: filterConfig, recentlyViewed } = root;
  const titleColour = general?.titleColour;
  const captionColour = general?.captionColour;
  const { toolbarEnabled, filterEnabled } = toolbar;
  const { filterDesktopExpandedByDefault, filterPosition } = filterConfig;
  const isFilterLeft = filterPosition === 'LEFT';
  const filterOpenInitial = defaultTo(filterDesktopExpandedByDefault, DEFAULT_FILTER_DESKTOP_EXPANDED);
  const [filterOpen, setFilterOpen] = useState(filterOpenInitial);
  const { hasNext, total, data, filters: filtersApplied } = products;
  const { sort, sortBy } = queryParams;
  const showRecentlyViewed = !isEmpty(recentlyViewedProducts) && !isEmpty(data) && recentlyViewed.enableRecentlyViewed;
  const stackSpacing = isMobile ? MOBILE_STACK_SPACING : DESKTOP_STACK_SPACING;
  const isFiltersReady = Boolean(toolbarEnabled && filterEnabled && !filters.loading && !isEmpty(filters.data));
  const isProductsEmpty = !loading && isEmpty(data);

  useEffect(() => {
    if (isMobile) {
      setFilterOpen(false);
    }
  }, [isMobile, setFilterOpen]);

  const handleToggleFilter = useCallback(() => setFilterOpen((prev) => !prev), [setFilterOpen]);

  const ProductsTableMemo = useMemo(() => {
    return (
      <ProductsTable
        config={{
          messaging: messages?.products,
          ...table,
          ...theme.colours.product
        }}
        currency={currency}
        loading={loading}
        products={data}
        hasNext={hasNext}
        total={total}
        handleLoadMore={handleLoadMore}
        handleNavigationClick={handleNavigationClick}
      />
    );
  }, [
    table,
    messages?.products,
    theme.colours.product,
    currency,
    loading,
    data,
    hasNext,
    total,
    handleLoadMore,
    handleNavigationClick
  ]);

  const ProductsRecentlyViewedTableMemo = useMemo(() => {
    return (
      showRecentlyViewed && (
        <ProductsRecentlyViewedTable
          config={{
            recentlyViewed,
            ...theme.colours.product
          }}
          currency={currency}
          products={recentlyViewedProducts}
          handleNavigationClick={handleNavigationClick}
        />
      )
    );
  }, [
    showRecentlyViewed,
    recentlyViewed,
    theme.colours.product,
    currency,
    recentlyViewedProducts,
    handleNavigationClick
  ]);

  return (
    <Content
      spacing={stackSpacing}
      direction="column"
      sx={{
        ...(isMobile && {
          marginTop: (theme) => theme.spacing(MOBILE_CONTAINER_TOP_BOTTOM_PADDING),
          marginBottom: (theme) => theme.spacing(MOBILE_CONTAINER_TOP_BOTTOM_PADDING)
        })
      }}
    >
      <StandardPageHeading
        title={title}
        description={description}
        titleColour={titleColour}
        captionColour={captionColour}
      />
      <ProductsToolbar
        config={{
          showDivider,
          ...toolbar,
          filterEnabled: isFiltersReady
        }}
        loading={loading}
        isProductsEmpty={isProductsEmpty}
        filters={filtersApplied}
        handleToggleFilter={handleToggleFilter}
        sort={sort}
        sortBy={sortBy}
        onSortBy={handleChangeOrder}
        sx={{ color: (theme) => theme.palette.text.primary }}
      />
      <Box sx={{ display: 'flex', flexDirection: isFilterLeft ? 'row' : 'row-reverse' }}>
        {isFiltersReady && (
          <FilterDrawer
            currency={currency}
            config={{
              ...filterConfig,
              isFilterLeft
            }}
            productsLoading={loading}
            data={filters.data}
            open={filterOpen}
            containerRef={containerRef}
            handleToggleFilter={handleToggleFilter}
            handleChangeFilters={handleChangeFilters}
          />
        )}
        {ProductsTableMemo}
      </Box>
      {showRecentlyViewed && showDivider ? <Divider /> : <Box />}
      {ProductsRecentlyViewedTableMemo}
    </Content>
  );
};
