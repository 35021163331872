import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import { defaultTo, isEmpty, replace } from 'lodash';
import { getTextAlignPosition, getTextAlignVerticalPosition } from '../../utils/getPositions';
import { ANNOUNCEMENT_BAR_POSITION } from '../../utils/constants';

const RootStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  boxShadow: 'none',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(1, 2)
}));

// When announcement bar is set to sticky this is to stop content hiding behind the bar
const AnnouncementBarPlaceholder = styled(Box)(({ theme }) => ({
  display: 'flex',
  boxShadow: 'none',
  padding: theme.spacing(1),
  opacity: 0
}));

export default function AnnouncementBar({ showAnnouncement, announcementHeight, isPreview, config }) {
  const {
    title,
    backgroundColour,
    textColour,
    textSize,
    contentPosition,
    contentVerticalPosition,
    showProminent,
    position: positionRoot
  } = config;
  if (!positionRoot) {
    return null;
  }
  // Fix - In preview (core app) its difficult to view sticky so we revert to absolute positioning.
  const position = defaultTo(isPreview, false) ? replace(positionRoot, '_STICKY', '') : positionRoot;
  const isSticky = position.includes('STICKY');
  const isStickyTop = position === ANNOUNCEMENT_BAR_POSITION[1];
  const isStickyBottom = position === ANNOUNCEMENT_BAR_POSITION[3];
  const content = (
    <Typography
      variant="body2"
      sx={{
        textAlign: 'center',
        userSelect: 'none',
        textWrap: 'noWrap',
        ...(showProminent && { fontWeight: (theme) => theme.typography.fontWeightBold }),
        ...(!isEmpty(textColour) && { color: textColour }),
        ...(textSize && { fontSize: `${textSize}px` }),
        ...(contentVerticalPosition && { alignContent: getTextAlignVerticalPosition(contentVerticalPosition) })
      }}
    >
      {title}
    </Typography>
  );

  return (
    <>
      {showAnnouncement && (
        <>
          {isSticky && (
            <AnnouncementBarPlaceholder sx={{ height: announcementHeight }}>{content}</AnnouncementBarPlaceholder>
          )}
          <RootStyle
            sx={{
              height: announcementHeight,
              justifyContent: getTextAlignPosition(contentPosition),
              zIndex: 1000,
              ...(!isEmpty(backgroundColour) && { backgroundColor: backgroundColour }),
              ...(isSticky && {
                position: 'fixed',
                width: '100%'
              }),
              ...(isStickyTop && { top: 0 }),
              ...(isStickyBottom && { bottom: 0 })
            }}
          >
            {content}
          </RootStyle>
        </>
      )}
    </>
  );
}
