import React, { useEffect } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { Elements, PaymentElement, PaymentRequestButtonElement, useElements, useStripe } from '@stripe/react-stripe-js';

export const StripeElement = ({ config, loader, handleUpdateStripeContext }) => {
  const theme = useTheme();
  const isLoading = config?.loading || false;
  const secret = config?.secret;
  const client = config?.client;

  if (isLoading) {
    return loader;
  }
  return !secret ? null : (
    <Box sx={{ marginBottom: '30px' }}>
      <Elements
        key={secret}
        options={{
          clientSecret: secret,
          appearance: {
            labels: 'floating',
            variables: {
              fontWeightNormal: 400,
              fontWeightMedium: 600,
              fontWeightBold: 700,
              colorPrimary: theme.palette.primary.main,
              colorBackground: theme.palette.background.paper,
              colorText: theme.palette.text.secondary,
              colorDanger: theme.palette.error.main,
              spacingUnit: theme.spacing(1),
              borderRadius: `${theme.shape.textfieldBorderRadius}px`
            },
            rules: {
              '.Label--floating': {
                color: 'var(--colorPrimary)'
              },
              '.Input': {
                padding: theme.spacing(1, 2),
                boxShadow: 'none'
              },
              '.Input:hover': {
                boxShadow: 'none'
              },
              '.Input:focus': {
                border: '2px solid var(--colorPrimary)',
                boxShadow: 'none'
              },
              '.Input:disabled': {
                backgroundColor: theme.palette.action.disabledBackground,
                boxShadow: 'none'
              },
              '.Tab': {
                padding: theme.spacing(2.5, 2.5),
                boxShadow: 'none'
              },
              '.Tab:disabled': {
                backgroundColor: theme.palette.action.disabledBackground,
                boxShadow: 'none'
              },
              '.Tab:focus': {
                boxShadow: 'none'
              },
              '.Tab--selected:focus': {
                boxShadow: 'none'
              }
            }
          }
        }}
        stripe={client}
      >
        <PaymentElementView handleUpdateStripeContext={handleUpdateStripeContext} />
      </Elements>
    </Box>
  );
};

const PaymentElementView = ({ handleUpdateStripeContext }) => {
  const elements = useElements();
  const stripe = useStripe();

  useEffect(() => {
    if (elements && stripe) {
      handleUpdateStripeContext(elements, stripe);
    }
  }, [elements, stripe, handleUpdateStripeContext]);

  return (
    <PaymentElement
      id="payment-element"
      options={{
        layout: 'tabs',
        paymentMethodOrder: ['card', 'apple_pay', 'google_pay']
      }}
    />
  );
};

export const StripeExpressCheckoutElement = ({ config }) => {
  const client = config?.client;
  const data = config?.data;
  return !client ? null : (
    <Elements stripe={client}>
      {data && (
        <PaymentRequestButtonElement
          options={{
            paymentRequest: data
          }}
        />
      )}
    </Elements>
  );
};
