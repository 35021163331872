import React from 'react';
import { Box } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { KLARNA_PAYMENTS_CONTAINER_ID } from '../../../utils/constants';
import { getEnabledPayments } from '../../../utils/payments';
import { StandardPaymentMethod } from './StandardPaymentMethod';
import { PaymentLoadingView } from './PaymentLoadingView';

export const KlarnaPaymentMethod = ({ option, ...other }) => {
  const { key } = option;
  if (!option.methods || isEmpty(option.methods)) {
    return null;
  }
  return getEnabledPayments(option.methods).map((method) => {
    const { name, id, loading } = method;
    return (
      <StandardPaymentMethod
        {...other}
        key={id}
        option={{
          id,
          key: id,
          title: option.title,
          description: name,
          services: [],
          icon: key
        }}
      >
        <Box id={`${KLARNA_PAYMENTS_CONTAINER_ID}-${id}`}>{loading && PaymentLoadingView}</Box>
      </StandardPaymentMethod>
    );
  });
};
