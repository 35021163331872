import currency from 'currency.js';
import { replace } from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

// const formatted = (number) => numeral(number).format(Number.isInteger(number) ? `0,0` : `0,0.00`);
export const formatted = (number) => numeral(number).format(`0,0.00`);

const isLetters = (symbol) => new RegExp('^[a-zA-Z]*$').test(symbol);

export function nfCurrency(number, symbol) {
  return `${symbol}${formatted(number)}`;
}

export function nfCurrencyCode(number, code) {
  return `${code} ${formatted(number)}`;
}

export function fCurrency(number, symbol) {
  if (symbol && isLetters(symbol)) {
    return nfCurrencyCode(number, symbol);
  }
  return `${symbol}${formatted(number)}`;
}

export function fCurrencyFromMinorUnits(number, symbol) {
  return currency(number, {
    symbol: isLetters(symbol) ? `${symbol} ` : symbol,
    fromCents: true,
    precision: 2
  }).format();
}

export function fCurrencyCode(number, code) {
  return `${code} ${formatted(number)}`;
}

export function fNumberCurrency(number, currency) {
  return `${currency}${numeral(number).format('0,0.00')}`;
}
export function fNumberPercentage(number) {
  return `${numeral(number).format('0,0.00')}%`;
}

export function fPercent(number) {
  return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
  return numeral(number).format();
}

export function fShortenNumber(number) {
  return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}
