import React from 'react';
import { Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { GENERIC_FONT_SIZE } from '../../../utils/constants';
import { getTextAlignPosition } from '../../../utils/getPositions';
import { DEFAULT_FONT_SIZES } from '../../../theme/typography';

const TitleStyle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: theme.typography.fontWeightBold
}));

export const CheckoutTitle = ({ position, size, sx, ...other }) => (
  <TitleStyle
    variant="body1"
    sx={{
      textAlign: getTextAlignPosition(position),
      ...(size === GENERIC_FONT_SIZE[1] && { fontSize: DEFAULT_FONT_SIZES.h4 }),
      ...sx
    }}
    {...other}
  />
);
