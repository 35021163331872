import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { defaultTo } from './utils/nullable';
import { COLLECTION_PAGE } from './utils/pageTypes';
import GeneralProductsTemplate from './components/products/GenericProductsTemplate';
import { DEFAULT_COLLECTION_PAGE_NAME } from './utils/constants';

ProductsCollectionTemplate.propTypes = {
  /** pagePath:
   * Current location path id e.g. /terms-and-conditions, /:product-name
   * For the home page this is static otherwise this will be a variable.
   * When set within the editor the value will be pre-defined as you navigate the editor.
   * When consumed by hosting app then this will be pulled from the url path. */
  context: PropTypes.object,
  logo: PropTypes.object,
  collection: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
  currenciesLoading: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  products: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  recentlyViewedProducts: PropTypes.array,
  cartTotal: PropTypes.number.isRequired,
  queryParams: PropTypes.object.isRequired,
  handleLoadMore: PropTypes.func.isRequired,
  handleChangeOrder: PropTypes.func.isRequired,
  handleChangeFilters: PropTypes.func.isRequired,
  // Common functions
  handleChangeCurrency: PropTypes.func.isRequired,
  handleNavigationClick: PropTypes.func.isRequired,
  handleGetNavigation: PropTypes.func.isRequired,
  handleGetNavigations: PropTypes.func.isRequired,
  handleGetStoreInformation: PropTypes.func.isRequired,
  handleCreateCustomer: PropTypes.func.isRequired,
  handleOnPageEnter: PropTypes.func
};

export default function ProductsCollectionTemplate({ context, collection, cartTotal, config, ...other }) {
  const { pagePath } = context;
  const navigation = { type: COLLECTION_PAGE, path: pagePath };
  const { name, description: collectionDescription } = collection;
  const title = defaultTo(name, DEFAULT_COLLECTION_PAGE_NAME);

  return (
    <GeneralProductsTemplate
      context={context}
      title={title}
      {...(!isEmpty(collectionDescription) && { description: collectionDescription })}
      navigation={navigation}
      cartTotal={cartTotal}
      config={{
        root: config?.collection,
        theme: config?.global?.theme,
        messages: config?.global?.messages,
        layout: config?.layout,
        settings: config?.settings,
        metadata: {
          page: title
        },
        local: {
          layout: config?.collection?.layout
        }
      }}
      {...other}
    />
  );
}
