import React from 'react';
import { Typography } from '@material-ui/core';
import warningFilled from '@iconify/icons-ep/warning-filled';
import {
  DEFAULT_UNKNOWN_ERROR_ORDER_MESSAGE_BODY,
  DEFAULT_UNKNOWN_ERROR_ORDER_MESSAGE_HEADER,
  DEFAULT_UNKNOWN_ERROR_ORDER_MESSAGE_TITLE
} from '../../../../utils/constants';
import { SummarySection } from '../SummarySection';
import { FailureDetails } from './OrderMessagePaymentErrorView';

export default function OrderMessageUnknownErrorView({
  headingProps,
  showBorder,
  showHeaderIcon,
  heading,
  title,
  message,
  sx
}) {
  const { title: titleSx, content: contentSx } = sx;
  return (
    <SummarySection
      showBorder={showBorder}
      header={
        <FailureDetails
          headingProps={headingProps}
          showHeaderIcon={showHeaderIcon}
          icon={warningFilled}
          heading={heading || DEFAULT_UNKNOWN_ERROR_ORDER_MESSAGE_HEADER}
          sx={sx}
        />
      }
      title={title || DEFAULT_UNKNOWN_ERROR_ORDER_MESSAGE_TITLE}
      sx={titleSx}
    >
      <Typography variant="body1" sx={{ color: 'text.secondary', ...contentSx }}>
        {message || DEFAULT_UNKNOWN_ERROR_ORDER_MESSAGE_BODY}
      </Typography>
    </SummarySection>
  );
}
