import { Box } from '@material-ui/core';
import { alpha, styled } from '@material-ui/core/styles';

export const PaymentMethodView = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 2.5),
  justifyContent: 'space-between',
  borderRadius: theme.shape.textfieldBorderRadius,
  transition: theme.transitions.create('all'),
  border: `solid 1px ${alpha(theme.palette.border, 0.32)}`,
  flexWrap: 'wrap'
}));
