import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
// material
import { TextField } from '@material-ui/core';
import { TEXTFIELD_SHAPE } from '../../../utils/constants';

// ----------------------------------------------------------------------

const MTextField = forwardRef(({ shape, children, ...other }, ref) => (
  <TextField
    ref={ref}
    {...(shape === TEXTFIELD_SHAPE[0] && { variant: 'standard' })}
    {...(shape === TEXTFIELD_SHAPE[1] && { variant: 'filled' })}
    {...(shape === TEXTFIELD_SHAPE[2] && { variant: 'outlined' })}
    {...other}
  >
    {children}
  </TextField>
));

MTextField.propTypes = {
  children: PropTypes.node
};

export default MTextField;
