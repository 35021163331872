import React from 'react';

export default function IllustrationBrowser({ colour }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      aria-hidden="true"
      focusable="false"
      // width="1.32em"
      // height="1em"
      height="100%"
      width="100%"
      style={{
        msTransform: 'rotate(360deg)',
        WebkitTransform: 'rotate(360deg)',
        transform: 'rotate(360deg)'
      }}
      preserveAspectRatio="xMidYMid meet"
      viewBox="0 0 42 32"
    >
      <g fill={colour}>
        <path d="M41.5 10a.5.5 0 0 0-.5.5v20a.5.5 0 0 1-.5.5h-39a.5.5 0 0 1-.5-.5v-20a.5.5 0 0 0-1 0v20c0 .827.673 1.5 1.5 1.5h39c.827 0 1.5-.673 1.5-1.5v-20a.5.5 0 0 0-.5-.5z" />
        <path d="M40.5 0h-39C.673 0 0 .673 0 1.5v6a.5.5 0 0 0 .5.5h41a.5.5 0 0 0 .5-.5v-6c0-.827-.673-1.5-1.5-1.5zm.5 7H1V1.5a.5.5 0 0 1 .5-.5h39a.5.5 0 0 1 .5.5V7z" />
        <path d="M17.5 28a.5.5 0 0 0 .5-.5v-16a.5.5 0 0 0-.5-.5h-13a.5.5 0 0 0-.5.5v16a.5.5 0 0 0 .5.5h13zM5 12h12v15H5V12z" />
        <path d="M22.5 15h15a.5.5 0 0 0 0-1h-15a.5.5 0 0 0 0 1z" />
        <path d="M22.5 20h15a.5.5 0 0 0 0-1h-15a.5.5 0 0 0 0 1z" />
        <path d="M22.5 25h15a.5.5 0 0 0 0-1h-15a.5.5 0 0 0 0 1z" />
        <circle cx={4} cy={4} r={1} />
        <circle cx={8} cy={4} r={1} />
        <circle cx={12} cy={4} r={1} />
      </g>
    </svg>
  );
}
