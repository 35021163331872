import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { styled } from '@material-ui/core/styles';
import { Stack, Box, Button, Grid, Container } from '@material-ui/core';
import { isEmpty, isEqual } from 'lodash';
import { isInvalid } from '../../utils/nullable';
import { COLLECTION_PAGE } from '../../utils/pageTypes';
import { getDesktopItemsPerRow, getItemsPerRow } from '../../utils/getItemsPerRow';
import useMobile from '../../hooks/useMobile';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import {
  COLLECTION_PLACEHOLDER_HEIGHT,
  DEFAULT_DESKTOP_GRID_SIZE,
  DEFAULT_PRODUCTS_MISSING_DESCRIPTION,
  DEFAULT_PRODUCTS_MISSING_TITLE,
  DESKTOP_STACK_SPACING,
  MOBILE_STACK_SPACING,
  PRODUCTS_TABLE_VIEW_TYPE,
  VERTICAL_VIEW_TYPE,
  homeDefaultContainerSpacing
} from '../../utils/constants';
import { ProductCardListItem } from '../products/ProductCardListItem';
import { ComponentHeader } from './ComponentHeader';
import EmptyContent from '../core/EmptyContent';

const PlaceholderView = styled(Box)(() => ({
  height: COLLECTION_PLACEHOLDER_HEIGHT
}));

function HomeCollectionProducts({
  config: rootConfig,
  component,
  currency,
  handleGetCollectionProducts,
  handleNavigationClick = () => {}
}) {
  const {
    resource,
    numberOfProducts,
    title,
    caption,
    titleColour,
    captionColour,
    showProductInfo,
    desktopProductRows,
    mobileProductRows,
    buttonText,
    verticalViewType,
    productsViewType
  } = component;
  const config = rootConfig?.global?.theme?.colours?.product;
  const isMountedRef = useIsMountedRef();
  // Reference to avoid reloading (via editor) when resource is unchanged
  const loadedResourceRef = useRef({
    resource: null,
    size: numberOfProducts,
    currency: null
  });
  const [products, setProducts] = useState(null);
  const isMobile = useMobile();

  const showVerticalSpacing = verticalViewType === VERTICAL_VIEW_TYPE[0];
  const isWideView = productsViewType === PRODUCTS_TABLE_VIEW_TYPE[1];
  const showHeader = !isEmpty(caption) || !isEmpty(title);

  const getCollectionProducts = useCallback(() => {
    const shouldGetProducts =
      isMountedRef.current &&
      Boolean(
        !isEqual(loadedResourceRef.current.resource, resource) ||
          !isEqual(loadedResourceRef.current.size, numberOfProducts)
      );
    const currencyUnchanged = loadedResourceRef.current.currency === currency;
    if (currencyUnchanged && !shouldGetProducts) {
      return;
    }
    loadedResourceRef.current = {
      resource,
      size: numberOfProducts,
      currency
    };
    handleGetCollectionProducts(resource, numberOfProducts).then((_products) => {
      setProducts(_products);
    });
  }, [currency, resource, numberOfProducts, isMountedRef, handleGetCollectionProducts]);

  useEffect(() => {
    getCollectionProducts();
  }, [getCollectionProducts]);

  const getMobileRows = useCallback(
    () => getItemsPerRow(mobileProductRows, numberOfProducts),
    [mobileProductRows, numberOfProducts]
  );
  const getDesktopRows = useCallback(
    () =>
      desktopProductRows ? getDesktopItemsPerRow(desktopProductRows, numberOfProducts) : DEFAULT_DESKTOP_GRID_SIZE,
    [desktopProductRows, numberOfProducts]
  );

  const ProductsViewMemo = useMemo(() => {
    const messaging = config?.global?.messages?.products;
    const gridSizing = {
      xs: isMobile ? getMobileRows() : getDesktopRows()
    };

    return isEmpty(products) ? (
      <PlaceholderView>
        <EmptyContent
          title={isInvalid(messaging?.title) ? DEFAULT_PRODUCTS_MISSING_TITLE : messaging?.title}
          description={
            isInvalid(messaging?.description) ? DEFAULT_PRODUCTS_MISSING_DESCRIPTION : messaging?.description
          }
          sx={{ height: '100%' }}
        />
      </PlaceholderView>
    ) : (
      <Box>
        <Grid container direction="row" spacing={isWideView ? 0 : 3}>
          {products.slice(0, numberOfProducts).map((_product) => (
            <Grid key={_product.id} item {...gridSizing}>
              <ProductCardListItem
                config={{
                  ...config,
                  ...component
                }}
                currency={currency}
                showProductInfo={showProductInfo}
                product={_product}
                handleNavigationClick={handleNavigationClick}
                isWideView={isWideView}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }, [
    component,
    currency,
    isMobile,
    config,
    products,
    isWideView,
    showProductInfo,
    numberOfProducts,
    handleNavigationClick,
    getMobileRows,
    getDesktopRows
  ]);

  return (
    <Box sx={{ margin: (theme) => homeDefaultContainerSpacing(isWideView, showHeader, showVerticalSpacing, theme) }}>
      <Container
        maxWidth={false}
        sx={{
          '&.MuiContainer-root': {
            ...(isWideView && { paddingLeft: 0, paddingRight: 0 })
          }
        }}
      >
        <Stack spacing={isMobile ? MOBILE_STACK_SPACING : DESKTOP_STACK_SPACING}>
          {showHeader && (
            <ComponentHeader caption={caption} title={title} titleColour={titleColour} captionColour={captionColour} />
          )}

          {ProductsViewMemo}

          {!isEmpty(buttonText) && (
            <Box sx={{ width: '100%', textAlign: 'center' }}>
              <Button
                variant="contained"
                sx={{ margin: (theme) => theme.spacing(1) }}
                onClick={() => {
                  if (resource) {
                    handleNavigationClick(COLLECTION_PAGE, resource);
                  }
                }}
              >
                {buttonText}
              </Button>
            </Box>
          )}
        </Stack>
      </Container>
    </Box>
  );
}

export default HomeCollectionProducts;
