import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Divider, Stack, Container } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import useMobile from './hooks/useMobile';
import Page from './components/core/Page';
import TemplateLayout from './layout/TemplateLayout';
import { ProductsRecentlyViewedTable } from './components/products/ProductsRecentlyViewedTable';
import ThemeConfig from './theme';
import { SYSTEM_PAGE } from './utils/pageTypes';
import {
  DEFAULT_CART_PAGE_NAME,
  DESKTOP_CONTAINER_TOP_BOTTOM_PADDING,
  DESKTOP_CONTAINER_LEFT_RIGHT_PADDING,
  MOBILE_CONTAINER_TOP_BOTTOM_PADDING,
  MOBILE_CONTAINER_LEFT_RIGHT_PADDING,
  MOBILE_STACK_SPACING,
  DESKTOP_STACK_SPACING
} from './utils/constants';
import { CartCard } from './components/cart/CartCard';
import { StandardPageHeading } from './components/core/StandardPageHeading';
import { defaultTo } from './utils/nullable';
import { LargeLoaderComponent } from './components/core/LargeLoaderComponent';

const Content = styled(Stack)(({ theme }) => ({
  margin: theme.spacing(DESKTOP_CONTAINER_TOP_BOTTOM_PADDING, 0)
}));

const TableContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(0, DESKTOP_CONTAINER_LEFT_RIGHT_PADDING)
}));

CartPageTemplate.propTypes = {
  /** pagePath:
   * Current location path id e.g. /terms-and-conditions, /:product-name
   * For the home page this is static otherwise this will be a variable.
   * When set within the editor the value will be pre-defined as you navigate the editor.
   * When consumed by hosting app then this will be pulled from the url path. */
  context: PropTypes.object,
  logo: PropTypes.object,
  config: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
  currenciesLoading: PropTypes.bool.isRequired,
  products: PropTypes.array.isRequired,
  recentlyViewedProducts: PropTypes.array.isRequired,
  cartTotal: PropTypes.number.isRequired,
  messagingPlacements: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  onIncreaseQuantity: PropTypes.func.isRequired,
  onDecreaseQuantity: PropTypes.func.isRequired,
  // Common functions
  handleChangeCurrency: PropTypes.func.isRequired,
  handleNavigationClick: PropTypes.func.isRequired,
  handleGetNavigation: PropTypes.func.isRequired,
  handleGetNavigations: PropTypes.func.isRequired,
  handleGetStoreInformation: PropTypes.func.isRequired,
  handleCreateCustomer: PropTypes.func.isRequired,
  handleOnPageEnter: PropTypes.func
};

export default function CartPageTemplate({
  context,
  logo,
  config,
  cartTotal,
  currency: currenctObj,
  currencies,
  currenciesLoading,
  handleChangeCurrency,
  handleNavigationClick,
  handleGetNavigation,
  handleGetNavigations,
  handleGetStoreInformation,
  handleCreateCustomer,
  handleOnPageEnter,
  ...other
}) {
  const { pagePath } = context;
  const navigation = { type: SYSTEM_PAGE, path: pagePath };
  const pageTitle = defaultTo(config?.cart?.metadata?.page, DEFAULT_CART_PAGE_NAME);
  const pageDescription = defaultTo(config?.cart?.metadata?.description, '');
  const currencySymbol = currenctObj?.symbol;

  return (
    <ThemeConfig customTheme={config?.global?.theme} settings={config?.settings}>
      <Page title={pageTitle} description={pageDescription} onPageEnter={handleOnPageEnter}>
        <TemplateLayout
          logo={logo}
          currency={currenctObj}
          currencies={currencies}
          currenciesLoading={currenciesLoading}
          context={context}
          cartTotal={cartTotal}
          config={{
            ...config?.layout,
            ...config?.global?.theme,
            local: {
              layout: config?.cart?.layout
            }
          }}
          currentNavigation={navigation}
          handleChangeCurrency={handleChangeCurrency}
          handleNavigationClick={handleNavigationClick}
          handleGetNavigation={handleGetNavigation}
          handleGetNavigations={handleGetNavigations}
          handleGetStoreInformation={handleGetStoreInformation}
          handleCreateCustomer={handleCreateCustomer}
        >
          <CartPageView
            currency={currencySymbol}
            title={pageTitle}
            config={config}
            loading={currenciesLoading}
            handleNavigationClick={handleNavigationClick}
            {...other}
          />
        </TemplateLayout>
      </Page>
    </ThemeConfig>
  );
}

const CartPageView = ({
  title,
  config,
  currency,
  products,
  loading,
  recentlyViewedProducts,
  messagingPlacements,
  onDelete,
  onIncreaseQuantity,
  onDecreaseQuantity,
  handleNavigationClick
}) => {
  const isMobile = useMobile();

  const CartViewMemo = useMemo(() => {
    const stackSpacing = isMobile ? MOBILE_STACK_SPACING : DESKTOP_STACK_SPACING;
    const { showDivider } = config?.layout?.body;
    const cart = config?.cart;
    const messaging = config?.global?.messages?.cart;
    const titleColour = cart?.general?.titleColour;
    const common = {
      onDelete,
      onIncreaseQuantity,
      onDecreaseQuantity
    };

    return (
      <Content
        spacing={stackSpacing}
        {...(showDivider ? { divider: <Divider sx={{ width: '100%' }} orientation="horizontal" /> } : null)}
        direction="column"
        alignItems="center"
        {...(isMobile && { sx: { margin: (theme) => theme.spacing(MOBILE_CONTAINER_TOP_BOTTOM_PADDING, 0) } })}
      >
        <StandardPageHeading title={title} titleColour={titleColour} />
        <TableContainer
          maxWidth="lg"
          {...(isMobile && { sx: { padding: (theme) => theme.spacing(0, MOBILE_CONTAINER_LEFT_RIGHT_PADDING) } })}
        >
          {loading ? (
            <LargeLoaderComponent />
          ) : (
            <CartCard
              config={{
                messaging,
                ...cart
              }}
              currency={currency}
              handleNavigationClick={handleNavigationClick}
              products={products}
              messagingPlacements={messagingPlacements}
              {...common}
            />
          )}
        </TableContainer>
        {!isEmpty(recentlyViewedProducts) && !isEmpty(products) && cart.recentlyViewed.enableRecentlyViewed && (
          <ProductsRecentlyViewedTable
            config={{
              recentlyViewed: cart.recentlyViewed,
              ...config?.global?.theme.colours.product
            }}
            currency={currency}
            products={recentlyViewedProducts}
            handleNavigationClick={handleNavigationClick}
            {...common}
          />
        )}
      </Content>
    );
  }, [
    isMobile,
    title,
    config,
    currency,
    loading,
    products,
    recentlyViewedProducts,
    messagingPlacements,
    onDelete,
    onIncreaseQuantity,
    onDecreaseQuantity,
    handleNavigationClick
  ]);

  return CartViewMemo;
};
