// material
import React from 'react';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

export const NavigationText = ({ children, isActive, textColour, sx }) => {
  const theme = useTheme();
  const colour = isActive ? theme.palette.primary.main : textColour;
  return (
    <Typography variant="body2" color={colour} {...sx}>
      {children}
    </Typography>
  );
};
