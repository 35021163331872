import React from 'react';
import { Stack, Skeleton } from '@material-ui/core';

export const FormHeadingText = ({ sx }) => <Skeleton variant="text" sx={{ height: 35, width: '50%', ...sx }} />;

const FormInputSkeleton = ({ sx }) => <Skeleton variant="rectangular" sx={{ height: 50, ...sx }} />;

export const SkeletonForm = (props) => (
  <Stack spacing={2} direction="column" {...props}>
    <FormHeadingText />
    <Stack spacing={2} direction="column">
      <FormInputSkeleton />
      <Stack spacing={2} direction="row">
        <FormInputSkeleton sx={{ width: '50%' }} />
        <FormInputSkeleton sx={{ width: '50%' }} />
      </Stack>
      <FormInputSkeleton />
    </Stack>
  </Stack>
);
