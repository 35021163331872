import React from 'react';
import { Typography } from '@material-ui/core';
import { DEFAULT_NEXT_STEPS_TITLE, DEFAULT_PAYMENT_ERROR_NEXT_STEPS_MESSAGE } from '../../../../utils/constants';
import { SummarySection } from '../SummarySection';

export default function NextStepsPaymentErrorView({
  showBorder,
  title,
  message,
  sx: { title: titleSx, content: contentSx }
}) {
  return (
    <SummarySection showBorder={showBorder} title={title || DEFAULT_NEXT_STEPS_TITLE} sx={titleSx}>
      <Typography variant="body1" sx={{ color: 'text.secondary', ...contentSx }}>
        {message || DEFAULT_PAYMENT_ERROR_NEXT_STEPS_MESSAGE}
      </Typography>
    </SummarySection>
  );
}
