import React from 'react';
import PropTypes from 'prop-types';
import { defaultTo } from './utils/nullable';
import { PRODUCTS_PATH, SYSTEM_PAGE } from './utils/pageTypes';
import GeneralProductsTemplate from './components/products/GenericProductsTemplate';
import { DEFAULT_PRODUCTS_PAGE_NAME } from './utils/constants';

ProductsListTemplate.propTypes = {
  context: PropTypes.object,
  logo: PropTypes.object,
  config: PropTypes.object.isRequired,
  currency: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
  currenciesLoading: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  products: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  recentlyViewedProducts: PropTypes.array,
  queryParams: PropTypes.object.isRequired,
  cartTotal: PropTypes.number.isRequired,
  handleLoadMore: PropTypes.func.isRequired,
  handleChangeOrder: PropTypes.func.isRequired,
  handleChangeFilters: PropTypes.func.isRequired,
  // Common functions
  handleChangeCurrency: PropTypes.func.isRequired,
  handleNavigationClick: PropTypes.func.isRequired,
  handleGetNavigation: PropTypes.func.isRequired,
  handleGetNavigations: PropTypes.func.isRequired,
  handleGetStoreInformation: PropTypes.func.isRequired,
  handleCreateCustomer: PropTypes.func.isRequired,
  handleOnPageEnter: PropTypes.func
};

export default function ProductsListTemplate({ context, config, cartTotal, ...other }) {
  const navigation = { type: SYSTEM_PAGE, path: PRODUCTS_PATH };
  const pageTitle = defaultTo(config?.products?.metadata?.page, DEFAULT_PRODUCTS_PAGE_NAME);
  const pageDescription = defaultTo(config?.products?.metadata?.description, '');

  return (
    <GeneralProductsTemplate
      context={context}
      cartTotal={cartTotal}
      title={pageTitle}
      navigation={navigation}
      config={{
        root: config?.products,
        theme: config?.global?.theme,
        messages: config?.global?.messages,
        layout: config?.layout,
        settings: config?.settings,
        metadata: {
          page: pageTitle,
          description: pageDescription
        },
        local: {
          layout: config?.products?.layout
        }
      }}
      {...other}
    />
  );
}
