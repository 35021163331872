import React, { forwardRef, useCallback, useRef } from 'react';
import { Box, Divider } from '@material-ui/core';
import { defaultTo, isEmpty } from 'lodash';
import { styled } from '@material-ui/core/styles';
import {
  APPBAR_DESKTOP,
  APPBAR_DESKTOP_STANDARD,
  APPBAR_DESKTOP_COMPACT,
  APPBAR_MOBILE,
  APPBAR_ANNOUNCEMENT
} from '../components/global/header-components/util/HeaderConstant';
import useMobile from '../hooks/useMobile';
import AnnouncementBar from '../components/global/AnnouncementBar';
import Footer from '../components/global/Footer';
import Header from '../components/global/Header';
import { ANNOUNCEMENT_BAR_POSITION, HEADER_APPEARANCE, NAVIGATION_VIEW_TYPE } from '../utils/constants';
import { getOverrideOrDefault } from './util/layoutUtil';

const RootLayout = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper
}));

const MainContent = styled(Box)({
  flexGrow: 1
});

const TemplateLayout = forwardRef(
  (
    {
      logo,
      context,
      cartTotal,
      config,
      currentNavigation,
      currency,
      currencies,
      currenciesLoading,
      handleChangeCurrency,
      handleGetNavigation,
      handleGetNavigations,
      handleNavigationClick,
      handleGetStoreInformation,
      handleCreateCustomer,
      children
    },
    overridableRef
  ) => {
    // Overridable with another ref
    const internalRef = useRef();
    const isMobile = useMobile();
    const { capability } = context;
    const isPreview = context?.isPreview;
    const mobileLogo = logo?.mobileLogo;
    const desktopLogo = logo?.desktopLogo;
    const {
      local,
      header: globalHeader,
      footer: globalFooter,
      announcement,
      appearance,
      currency: currencyConfig
    } = config;
    const localHeader = local?.layout?.header;
    const localFooter = local?.layout?.footer;
    const header = getOverrideOrDefault(globalHeader, localHeader);
    const footer = getOverrideOrDefault(globalFooter, localFooter);

    // Announcement
    const { title, position, height: announcementHeightRoot } = announcement;
    const showAnnouncement = !isEmpty(title);
    const announcementHeight = APPBAR_ANNOUNCEMENT + defaultTo(announcementHeightRoot, 0);

    // Header
    const { appearance: headerAppearance, viewType, showHeaderDivider } = header;
    const isAnnouncementTop = position.includes(ANNOUNCEMENT_BAR_POSITION[0]);
    const isAnnouncementBottom = position.includes(ANNOUNCEMENT_BAR_POSITION[2]);
    const isStandardView = viewType === NAVIGATION_VIEW_TYPE[0];
    const ref = defaultTo(overridableRef, internalRef);

    const getHeaderHeightPadding = useCallback(() => {
      if (headerAppearance !== HEADER_APPEARANCE[1]) {
        return 0;
      }
      // Only Standard Sticky requires padding
      if (isMobile) {
        return APPBAR_MOBILE;
      }
      if (isStandardView) {
        // Top / bottom portion
        return APPBAR_DESKTOP + APPBAR_DESKTOP_STANDARD;
      }
      // Compact view
      return APPBAR_DESKTOP_COMPACT;
    }, [isMobile, isStandardView, headerAppearance]);

    const announcementConfig = {
      showAnnouncement,
      announcementHeight,
      isPreview,
      config: announcement
    };

    return (
      <RootLayout ref={ref}>
        {isAnnouncementTop && <AnnouncementBar {...announcementConfig} />}
        <Header
          isPreview={isPreview}
          capability={capability}
          currency={currency}
          currencies={currencies}
          currenciesLoading={currenciesLoading}
          cartTotal={cartTotal}
          containerRef={ref}
          logo={{
            standard: desktopLogo,
            mobile: mobileLogo
          }}
          config={{
            showAnnouncement: showAnnouncement && isAnnouncementTop,
            announcementHeight,
            header,
            appearance,
            currency: currencyConfig
          }}
          currentNavigation={currentNavigation}
          handleChangeCurrency={handleChangeCurrency}
          handleGetNavigation={handleGetNavigation}
          handleNavigationClick={handleNavigationClick}
        />
        {showHeaderDivider && <Divider sx={{ width: '100%' }} />}
        <MainContent sx={{ pt: `${getHeaderHeightPadding()}px` }}>{children}</MainContent>
        <Footer
          capability={capability}
          currency={currency}
          currencies={currencies}
          currenciesLoading={currenciesLoading}
          logo={{
            standard: desktopLogo,
            mobile: mobileLogo
          }}
          config={{
            footer,
            appearance,
            currency: currencyConfig
          }}
          handleChangeCurrency={handleChangeCurrency}
          handleGetNavigations={handleGetNavigations}
          handleGetStoreInformation={handleGetStoreInformation}
          handleNavigationClick={handleNavigationClick}
          handleCreateCustomer={handleCreateCustomer}
        />
        {isAnnouncementBottom && <AnnouncementBar {...announcementConfig} />}
      </RootLayout>
    );
  }
);

export default TemplateLayout;
