import React from 'react';
import { isEmpty } from 'lodash';
import { Stack, Divider, Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import useMobile from '../../hooks/useMobile';
import Page from '../core/Page';
import TemplateLayout from '../../layout/TemplateLayout';
import ThemeConfig from '../../theme';
import { CollectionListTable } from './CollectionListTable';
import { CollectionListToolbar } from './CollectionListToolbar';
import { ProductsRecentlyViewedTable } from '../products/ProductsRecentlyViewedTable';
import { DESKTOP_CONTAINER_TOP_BOTTOM_PADDING, MOBILE_CONTAINER_TOP_BOTTOM_PADDING } from '../../utils/constants';
import { StandardPageHeading } from '../core/StandardPageHeading';

const Content = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(DESKTOP_CONTAINER_TOP_BOTTOM_PADDING),
  marginBottom: theme.spacing(DESKTOP_CONTAINER_TOP_BOTTOM_PADDING)
}));

export default function CollectionList(props) {
  const {
    context,
    logo,
    config,
    navigation,
    cartTotal,
    handleOnPageEnter,
    currency,
    currencies,
    currenciesLoading,
    ...other
  } = props;
  const { theme, layout, settings, metadata, local } = config;
  const pageTitle = metadata?.page;
  const pageDescription = metadata?.description;

  return (
    <ThemeConfig customTheme={theme} settings={settings}>
      <Page title={pageTitle} description={pageDescription} onPageEnter={handleOnPageEnter}>
        <TemplateLayout
          logo={logo}
          currency={currency}
          currencies={currencies}
          currenciesLoading={currenciesLoading}
          context={context}
          cartTotal={cartTotal}
          config={{
            local,
            pageTitle,
            ...layout,
            ...theme
          }}
          currentNavigation={navigation}
          {...other}
        >
          <CollectionListView currency={currency} {...props} />
        </TemplateLayout>
      </Page>
    </ThemeConfig>
  );
}

const CollectionListView = ({
  config: { layout, root, theme, messages, metadata },
  currency: currencyObj,
  loading = false,
  collections,
  recentlyViewedProducts,
  queryParams,
  handleLoadMore,
  handleChangeOrder,
  ...other
}) => {
  const { page } = metadata;
  const currency = currencyObj?.symbol;
  const isMobile = useMobile();
  const { general, table, toolbar, recentlyViewed } = root;
  const titleColour = general?.titleColour;
  const { hasNext, total, data } = collections;
  const { sort, sortBy } = queryParams;
  const showRecentlyViewed = !isEmpty(recentlyViewedProducts) && !isEmpty(data) && recentlyViewed.enableRecentlyViewed;
  const isCollectionsEmpty = !loading && isEmpty(data);
  const { showDivider } = layout?.body;

  return (
    <Content
      spacing={10}
      direction="column"
      sx={{
        ...(isMobile && {
          marginTop: (theme) => theme.spacing(MOBILE_CONTAINER_TOP_BOTTOM_PADDING),
          marginBottom: (theme) => theme.spacing(MOBILE_CONTAINER_TOP_BOTTOM_PADDING)
        })
      }}
    >
      <StandardPageHeading title={page} titleColour={titleColour} />
      <CollectionListToolbar
        config={{ showDivider, ...toolbar }}
        isCollectionsEmpty={isCollectionsEmpty}
        sort={sort}
        sortBy={sortBy}
        onSortBy={handleChangeOrder}
        sx={{ color: (theme) => theme.palette.text.primary }}
      />
      <CollectionListTable
        config={{
          messaging: messages?.collections,
          ...table
        }}
        isCollectionsEmpty={isCollectionsEmpty}
        loading={loading}
        collections={data}
        hasNext={hasNext}
        total={total}
        handleLoadMore={handleLoadMore}
        {...other}
      />
      {showRecentlyViewed && showDivider ? <Divider /> : <Box />}
      {showRecentlyViewed && (
        <ProductsRecentlyViewedTable
          config={{
            recentlyViewed,
            ...theme.colours.product
          }}
          currency={currency}
          products={recentlyViewedProducts}
          {...other}
        />
      )}
    </Content>
  );
};
