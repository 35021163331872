import PropTypes from 'prop-types';
import React from 'react';
import { Box, StepConnector } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import { withStyles } from '@material-ui/styles';

export const QontoConnector = withStyles((theme) => {
  return {
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)'
    },
    active: {
      '& $line': {
        borderColor: theme.palette.primary.main
      }
    },
    completed: {
      '& $line': {
        borderColor: theme.palette.primary.main
      }
    },
    line: {
      borderColor: '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1
    }
  };
})(StepConnector);

// ------------------------------------------

const RootStyle = styled(Box)(() => ({
  height: 22,
  display: 'flex',
  color: '#eaeaf0',
  alignItems: 'center'
}));

const RoundDot = styled(Box)(() => ({
  width: 8,
  height: 8,
  // borderRadius: theme.shape.borderRadiusCircle,
  borderRadius: '100%', // Not configurable
  backgroundColor: 'currentColor'
}));

const StyledCheck = styled(Check)(({ theme }) => ({
  color: theme.palette.primary.main,
  zIndex: 1,
  fontSize: 18
}));

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool
};

export function QontoStepIcon(props) {
  const { active, completed } = props;
  return (
    <RootStyle sx={{ ...(active && { color: (theme) => theme.palette.primary.main }) }}>
      {completed ? <StyledCheck /> : <RoundDot />}
    </RootStyle>
  );
}
