import { alpha, darken, lighten } from '@material-ui/core/styles';
import { defaultTo } from 'lodash';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// DEFAULT COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

const DEFAULT_PRIMARY = {
  lighter: '#F7CED6',
  light: '#EF9EAC',
  main: '#E76D83',
  dark: '#9A4957',
  darker: '#4D242C',
  contrastText: '#fff'
};
const DEFAULT_SECONDARY = {
  lighter: '#DCEEFF',
  light: '#73BBFF',
  main: '#2D99FF',
  dark: '#1E66AA',
  darker: '#0F3355',
  contrastText: '#fff'
};
const DEFAULT_INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff'
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
  contrastText: GREY[800]
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800]
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
  contrastText: '#fff'
};

const DEFAULT_GRADIENTS = {
  primary: createGradient(DEFAULT_PRIMARY.light, DEFAULT_PRIMARY.main),
  info: createGradient(DEFAULT_INFO.light, DEFAULT_INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4']
};

const DEFAULT_COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...DEFAULT_PRIMARY },
  secondary: { ...DEFAULT_SECONDARY },
  info: { ...DEFAULT_INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  grey: GREY,
  gradients: DEFAULT_GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500],
  border: GREY[500],
  skeleton: GREY[200],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

const buildGradients = (primary, secondary, info, error) => {
  return {
    primary: createGradient(primary.light, primary.main),
    secondary: createGradient(secondary.light, secondary.main),
    info: createGradient(info.light, info.main),
    success: createGradient(SUCCESS.light, SUCCESS.main),
    warning: createGradient(WARNING.light, WARNING.main),
    error: createGradient(error.light, error.main)
  };
};

const buildPrimary = (config) => {
  if (!config.primaryColour) {
    return DEFAULT_PRIMARY;
  }
  return {
    lighter: lighten(config.primaryColour, 0.7),
    light: lighten(config.primaryColour, 0.4),
    main: config.primaryColour,
    dark: darken(config.primaryColour, 0.4),
    darker: darken(config.primaryColour, 0.6),
    contrastText: config.primaryContrastColour
  };
};
const buildSecondary = (config) => {
  if (!config.secondaryColour) {
    return DEFAULT_SECONDARY;
  }
  return {
    lighter: lighten(config.secondaryColour, 0.7),
    light: lighten(config.secondaryColour, 0.4),
    main: config.secondaryColour,
    dark: darken(config.secondaryColour, 0.4),
    darker: darken(config.secondaryColour, 0.6),
    contrastText: config.secondaryContrastColour
  };
};
const buildInfo = (config) => {
  if (!config.info) {
    return DEFAULT_INFO;
  }
  return {
    lighter: lighten(config.infoColour, 0.7),
    light: lighten(config.infoColour, 0.4),
    main: config.infoColour,
    dark: darken(config.infoColour, 0.4),
    darker: darken(config.infoColour, 0.6),
    contrastText: config.infoContrastColour
  };
};

const buildError = (config) => {
  if (!config.errorColour) {
    return ERROR;
  }
  return {
    lighter: lighten(config.errorColour, 0.7),
    light: lighten(config.errorColour, 0.4),
    main: config.errorColour,
    dark: darken(config.errorColour, 0.4),
    darker: darken(config.errorColour, 0.6),
    contrastText: config.errorContrastColour
  };
};

const buildCommon = (config) => {
  const primary = buildPrimary(config);
  const secondary = buildSecondary(config);
  const info = buildInfo(config);
  const error = buildError(config);
  return {
    common: {
      black: defaultTo(config.blackColour, DEFAULT_COMMON.common.black),
      white: defaultTo(config.whiteColour, DEFAULT_COMMON.common.white)
    },
    primary: { ...primary },
    secondary: { ...secondary },
    divider: defaultTo(config.dividerColour, DEFAULT_COMMON.divider),
    border: defaultTo(config.borderColour, DEFAULT_COMMON.border),
    skeleton: defaultTo(config.skeletonColour, DEFAULT_COMMON.skeleton),
    success: { ...SUCCESS },
    info: { ...info },
    warning: { ...WARNING },
    error: { ...error },
    grey: GREY,
    gradients: buildGradients(primary, secondary, info, error),
    chart: CHART_COLORS,
    action: {
      hover: GREY[500_8],
      selected: GREY[500_16],
      disabled: GREY[500_80],
      disabledBackground: GREY[500_24],
      focus: GREY[500_24],
      hoverOpacity: 0.08,
      disabledOpacity: 0.48
    }
  };
};

export const buildThemePalette = (config) => {
  if (!config) {
    return DEFAULT_PALETTE;
  }
  return {
    ...buildCommon(config),
    text: {
      primary: defaultTo(config.textPrimaryColour, DEFAULT_PALETTE.text.primary),
      secondary: defaultTo(config.textSecondaryColour, DEFAULT_PALETTE.text.secondary),
      disabled: defaultTo(config.textDisabledColour, DEFAULT_PALETTE.text.disabled)
    },
    background: {
      paper: defaultTo(config.backgroundCoreColour, DEFAULT_PALETTE.background.paper),
      default: '#fff',
      neutral: defaultTo(config.backgroundNeutralColour, DEFAULT_PALETTE.background.neutral),
      neutralContrast: defaultTo(config.backgroundNeutralContrastColour, DEFAULT_PALETTE.background.neutralContrast)
    }
  };
};

export const DEFAULT_PALETTE = {
  ...DEFAULT_COMMON,
  text: { primary: GREY[800], secondary: GREY[600], disabled: GREY[500] },
  background: { paper: '#fff', default: '#fff', neutral: GREY[200], neutralContrast: GREY[600] },
  action: { active: GREY[600], ...DEFAULT_COMMON.action }
};

export const DEFAULT_PRODUCT_COLOURS = {
  priceSaleChangeColour: SUCCESS.main,
  saleBackgroundColour: SUCCESS.main,
  tagBackgroundColour: DEFAULT_INFO.main,
  outOfStockBackgroundColour: ERROR.main,
  saleTextColour: DEFAULT_COMMON.common.white,
  tagTextColour: DEFAULT_COMMON.common.white,
  outOfStockTextColour: DEFAULT_COMMON.common.white
};
