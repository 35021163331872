// material
import React from 'react';
import { StandardHeaderBar } from './StandardHeaderBar';
import { APPBAR_MOBILE } from './util/HeaderConstant';
import HeaderToolbarStyle from './styled/HeaderToolbarStyle';

const HeaderMobileView = (props) => {
  const { logoPositionMobile } = props;

  return (
    <>
      <HeaderToolbarStyle sx={{ padding: 0, px: 3, margin: 0, height: APPBAR_MOBILE }}>
        <StandardHeaderBar {...props} logoPosition={logoPositionMobile} />
      </HeaderToolbarStyle>
    </>
  );
};

export default HeaderMobileView;
