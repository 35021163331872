import React, { useMemo } from 'react';
import { Box, FormHelperText, Grid, RadioGroup, Stack } from '@material-ui/core';
import { isEmpty, random } from 'lodash';
import { paramCase } from 'change-case';
import useMobile from '../../hooks/useMobile';
import {
  DEFAULT_PAYMENT_OPTION_MISSING_TITLE,
  DEFAULT_PAYMENT_OPTION_MISSING_DESCRIPTION
} from '../../utils/constants';
import { getEnabledPayments, KLARNA_PAYMENT_PROVIDER_KEY, STRIPE_PAYMENT_PROVIDER_KEY } from '../../utils/payments';
import { Loader } from '../core/Loader';
import EmptyContent from '../core/EmptyContent';
import { DiscountInput } from './DiscountInput';
import { CheckoutTitle } from './components/CheckoutTitle';
import { isInvalid } from '../../utils/nullable';
import { PrePaymentCheckoutError } from './payment/PrePaymentCheckoutError';
import { KlarnaPaymentMethod } from './payment/KlarnaPaymentMethod';
import { StripePaymentMethod } from './payment/StripePaymentMethod';
import { StandardPaymentMethod } from './payment/StandardPaymentMethod';

// checkoutError - Pre payment checkout exception. (Not post checkout exception )
export const PaymentPage = ({
  config: { input, general, payments, preview, messaging },
  form,
  loading: loadingData,
  loadingDiscount,
  paymentMethods: data,
  discountStore,
  handleDiscountCheck: onValidateDiscount,
  onClearDiscount,
  hasCheckoutError,
  handleChangePaymentOption,
  handleUpdateStripeContext
}) => {
  const titlePosition = general?.titlePosition;
  const titleSize = general?.titleSize;
  const paymentMethodsTitle = payments?.paymentMethodsTitle;
  const { setFieldValue, getFieldProps, touched, errors } = form;

  const discountProps = getFieldProps('discount');
  const { isValidDiscount } = discountStore;

  const {
    payments: { enable: isError, message }
  } = hasCheckoutError;
  const {
    options: { payments: loading }
  } = loadingData;

  const isMobile = useMobile();
  const showDiscount = preview.showDiscount && isMobile;
  const gridSizing = {
    xs: 12
  };
  const paymentMethods = getEnabledPayments(data);

  const Title = (props) => <CheckoutTitle position={titlePosition} size={titleSize} {...props} />;

  const EmptyPaymentMethods = (
    <EmptyContent
      title={isInvalid(messaging?.title) ? DEFAULT_PAYMENT_OPTION_MISSING_TITLE : messaging?.title}
      description={
        isInvalid(messaging?.description) ? DEFAULT_PAYMENT_OPTION_MISSING_DESCRIPTION : messaging?.description
      }
      variant="h6"
      sx={{ ...(loading && { display: 'none' }) }}
    />
  );

  const DiscountMemo = useMemo(() => {
    return (
      <Stack spacing={2}>
        <CheckoutTitle position={titlePosition} size={titleSize}>
          Discount Code
        </CheckoutTitle>
        <DiscountInput
          input={input}
          isValidDiscount={isValidDiscount}
          discountProps={{
            value: discountProps.value,
            name: discountProps.name,
            onChange: discountProps.onChange,
            onBlur: discountProps.onBlur
          }}
          touched={touched.discount}
          errors={errors.discount}
          loading={loadingDiscount}
          validateDiscount={onValidateDiscount}
          onClickClearDiscount={onClearDiscount}
        />
      </Stack>
    );
  }, [
    titlePosition,
    titleSize,
    input,
    isValidDiscount,
    discountProps.value,
    discountProps.name,
    discountProps.onChange,
    discountProps.onBlur,
    touched.discount,
    errors.discount,
    loadingDiscount,
    onValidateDiscount,
    onClearDiscount
  ]);

  return (
    <>
      {loading && <Loader message="Just a moment, almost there..." sx={{ height: '200px' }} />}
      {isEmpty(paymentMethods) ? (
        EmptyPaymentMethods
      ) : (
        <>
          <Stack spacing={2} sx={{ ...(loading && { display: 'none' }) }}>
            {isError && <PrePaymentCheckoutError message={message} />}
            <Stack spacing={8} sx={{ ...(loading && { display: 'none' }) }}>
              {showDiscount && DiscountMemo}
              <Stack spacing={2}>
                <Title>{paymentMethodsTitle || 'Payment Methods'}</Title>
                <RadioGroup
                  {...getFieldProps('payment')}
                  onChange={(e) => {
                    const paymentKey = e.target.value;
                    setFieldValue('payment', paymentKey);
                    handleChangePaymentOption(paymentKey);
                  }}
                >
                  <Box>
                    <Grid container spacing={2} direction="column">
                      <PaymentMethods
                        config={payments}
                        options={paymentMethods}
                        form={form}
                        handleUpdateStripeContext={handleUpdateStripeContext}
                        size={gridSizing}
                      />
                    </Grid>
                  </Box>
                </RadioGroup>
                {Boolean(touched.payment && errors.payment) && (
                  <FormHelperText error>{touched.payment && errors.payment}</FormHelperText>
                )}
              </Stack>
            </Stack>
          </Stack>
        </>
      )}
    </>
  );
};

const PaymentMethods = ({ options, ...other }) => {
  return options.map((option) => {
    switch (paramCase(option.key)) {
      case paramCase(KLARNA_PAYMENT_PROVIDER_KEY):
        return <KlarnaPaymentMethod key={option.key} option={option} {...other} />;
      case paramCase(STRIPE_PAYMENT_PROVIDER_KEY): {
        const stripeKey = option?.element?.secret || random(1, 1000, false);
        return <StripePaymentMethod key={stripeKey} option={option} {...other} />;
      }
      default:
        return <StandardPaymentMethod key={option.key} option={option} {...other} />;
    }
  });
};
