import React from 'react';
import { styled } from '@material-ui/core/styles';
import { upperCase } from 'lodash';
import Label from '../components/core/Label';

const StatusLabel = styled(Label)(({ theme }) => ({
  height: '100%',
  padding: theme.spacing(0, 2)
}));

export const getStatusLabel = (status, config, sx) => {
  return (
    <StatusLabel
      sx={{
        backgroundColor: getStatusBackgroundColour(status, config),
        color: getStatusTextColour(status, config),
        fontSize: (theme) => theme.typography.caption,
        fontWeight: (theme) => theme.typography.fontWeightBold,
        ...sx
      }}
    >
      {upperCase(status)}
    </StatusLabel>
  );
};

const getStatusBackgroundColour = (status, config) => {
  const { saleBgColour, tagBgColour, outOfStockBgColour } = config;
  switch (status) {
    case 'SALE':
      return saleBgColour;
    case 'NEW':
      return tagBgColour;
    case 'OUT_OF_STOCK':
      return outOfStockBgColour;
    default:
      return tagBgColour;
  }
};

const getStatusTextColour = (status, config) => {
  const { saleTextColour, tagTextColour, outOfStockTextColour } = config;
  switch (status) {
    case 'SALE':
      return saleTextColour;
    case 'NEW':
      return tagTextColour;
    case 'OUT_OF_STOCK':
      return outOfStockTextColour;
    default:
      return tagTextColour;
  }
};
