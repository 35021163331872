import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import arrowLeftFill from '@iconify/icons-eva/arrow-left-fill';
import arrowRightFill from '@iconify/icons-eva/arrow-right-fill';
import roundKeyboardArrowLeft from '@iconify/icons-ic/round-keyboard-arrow-left';
import roundKeyboardArrowRight from '@iconify/icons-ic/round-keyboard-arrow-right';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
//
import { MIconButton } from '../../@material-extend';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 20,
  height: 20
};

const RootStyle = styled(Box)(({ theme }) => ({
  zIndex: 9,
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  color: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.grey[900], 0.48)
}));

const ArrowStyle = styled(MIconButton)(({ theme }) => ({
  padding: 6,
  opacity: 0.48,
  color: theme.palette.common.white,
  '&:hover': { opacity: 1 }
}));

// ----------------------------------------------------------------------

CarouselControlsArrowsIndex.propTypes = {
  index: PropTypes.number,
  total: PropTypes.number,
  arrowLine: PropTypes.bool,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func
};

export const PreviousArrowButton = ({ arrowLine = true, onPrevious, sx }) => {
  return (
    <ArrowStyle size="small" sx={sx} onClick={onPrevious}>
      {arrowLine ? <Icon icon={roundKeyboardArrowLeft} {...ICON_SIZE} /> : <Icon icon={arrowLeftFill} {...ICON_SIZE} />}
    </ArrowStyle>
  );
};

export const NextArrowButton = ({ arrowLine = true, onNext, sx }) => {
  return (
    <ArrowStyle size="small" sx={sx} onClick={onNext}>
      {arrowLine ? (
        <Icon icon={roundKeyboardArrowRight} {...ICON_SIZE} />
      ) : (
        <Icon icon={arrowRightFill} {...ICON_SIZE} />
      )}
    </ArrowStyle>
  );
};

export default function CarouselControlsArrowsIndex({ arrowLine, index, total, onNext, onPrevious, ...other }) {
  return (
    <RootStyle {...other}>
      <PreviousArrowButton arrowLine={arrowLine} onPrevious={onPrevious} />

      <Typography variant="subtitle2">
        {index + 1}/{total}
      </Typography>

      <NextArrowButton arrowLine={arrowLine} onNext={onNext} />
    </RootStyle>
  );
}
