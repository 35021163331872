import { styled } from '@material-ui/core/styles';
import { Toolbar } from '@material-ui/core';
import { APPBAR_DESKTOP } from '../util/HeaderConstant';

const HeaderToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APPBAR_DESKTOP,
  padding: theme.spacing(0, 1)
}));

export default HeaderToolbarStyle;
