import React from 'react';
import { Box } from '@material-ui/core';
import { alpha, styled } from '@material-ui/core/styles';
import Markdown from '../../core/Markdown';

const BoxStyled = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.textfieldBorderRadius,
  border: `solid 1px ${alpha(theme.palette.border, 0.32)}`
}));

export default function DiscountMessageView({ showBorder, body }) {
  return (
    <BoxStyled sx={{ ...(showBorder === false && { border: 'none' }) }}>
      <Markdown children={body} />
    </BoxStyled>
  );
}
