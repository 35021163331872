import React from 'react';
import { Stack, Box, Divider } from '@material-ui/core';
import { GENERIC_CURRENCY_VIEW } from '../../../utils/constants';
import { fCurrency, fCurrencyCode } from '../../../utils/formatNumber';
import { isZero } from '../../../utils/money';
import { SummarySection, TextHeadingStyle, TextStyle } from './SummarySection';

export default function OrderInformationView({
  currencyView,
  showBorder,
  summary: { currency, shipping, billing, shippingOption, paymentMethod },
  title,
  sx: { title: titleSx, content: contentSx }
}) {
  const { code, symbol } = currency;
  const {
    address: shippingAddress,
    city: shippingCity,
    postcode: shippingPostcode,
    country: shippingCountry
  } = shipping;
  const { address: billingAddress, city: billingCity, postcode: billingPostcode, country: billingCountry } = billing;
  const { name: shippingName, description: shippingDescription, cost: shippingCost } = shippingOption;
  const formattedShippingCost =
    currencyView === GENERIC_CURRENCY_VIEW[0] ? fCurrencyCode(shippingCost, code) : fCurrency(shippingCost, symbol);

  return (
    <SummarySection showBorder={showBorder} title={title || 'Order Information'} sx={titleSx}>
      <Stack spacing={2} direction="column" sx={contentSx}>
        <Box>
          <TextHeadingStyle variant="subtitle1">Shipping Address</TextHeadingStyle>
          <TextStyle variant="body1">{`${shippingAddress}, ${shippingCity}, ${shippingPostcode}, ${shippingCountry}`}</TextStyle>
        </Box>

        <Box>
          <TextHeadingStyle variant="subtitle1">Billing Address</TextHeadingStyle>
          <TextStyle variant="body1">{`${billingAddress}, ${billingCity}, ${billingPostcode}, ${billingCountry}`}</TextStyle>
        </Box>

        <Divider />

        <Box>
          <TextHeadingStyle variant="subtitle1">Shipping Option</TextHeadingStyle>
          <TextStyle variant="body1">{shippingName}</TextStyle>
          <TextStyle variant="body1">{shippingDescription}</TextStyle>
          <TextStyle variant="body1">{isZero(shippingCost) ? 'Free' : formattedShippingCost}</TextStyle>
        </Box>

        <Box>
          <TextHeadingStyle variant="subtitle1">Payment Method</TextHeadingStyle>
          <TextStyle variant="body1">{paymentMethod}</TextStyle>
        </Box>
      </Stack>
    </SummarySection>
  );
}
