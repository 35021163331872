import React, { useMemo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Stack,
  Box,
  Typography,
  Badge
} from '@material-ui/core';
import { Icon } from '@iconify/react';
import imageFill from '@iconify/icons-bi/image-fill';
import shoppingCartOutline from '@iconify/icons-eva/shopping-cart-outline';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import { alpha, styled, useTheme } from '@material-ui/core/styles';
import { defaultTo, isEmpty } from 'lodash';
import { DEFAULT_FONT_SIZES } from '../../theme/typography';
import { TEXT_POSITIONS } from '../../utils/getPositions';
import useMobile from '../../hooks/useMobile';
import { fCurrency, nfCurrency, nfCurrencyCode } from '../../utils/formatNumber';
import { isZero } from '../../utils/money';
import { MLazyLoadImage } from '../core/@react-lazy-load-image-component-extend';
import {
  DEFAULT_CHECKOUT_PAGE_TITLE,
  DEFAULT_CHECKOUT_PAGE_MOBILE_TITLE,
  DESKTOP_MAX_CHECKOUT_COMPONENT_WIDTH,
  MOBILE_CONTAINER_LEFT_RIGHT_PADDING,
  GENERIC_FONT_SIZE,
  GENERIC_CURRENCY_VIEW,
  CART_PREVIEW_VIEW_TYPE
} from '../../utils/constants';
import { SkeletonCart } from '../core/SkeletonCart';
import { DiscountInput } from './DiscountInput';

const Z_INDEX_TOP_VALUE = 100;

const Content = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5.5),
  width: '100%',
  maxWidth: DESKTOP_MAX_CHECKOUT_COMPONENT_WIDTH
}));

const StackStyle = styled(Stack)({
  zIndex: Z_INDEX_TOP_VALUE
});

const AccordionStyle = styled(Accordion)(({ theme }) => ({
  '&.MuiAccordion-root': {
    zIndex: Z_INDEX_TOP_VALUE,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    padding: theme.spacing(0),
    width: '100%',
    borderRadius: 0,
    '&::before': {
      top: 1
    }
  }
}));

const AccordionSummaryStyle = styled(AccordionSummary)(({ theme }) => ({
  padding: theme.spacing(0, MOBILE_CONTAINER_LEFT_RIGHT_PADDING)
}));

const AccordionDetailsStyle = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(MOBILE_CONTAINER_LEFT_RIGHT_PADDING, MOBILE_CONTAINER_LEFT_RIGHT_PADDING)
}));

const getContrastColor = (theme, colour) => (isEmpty(colour) ? theme.palette.primary.contrastText : colour);

const CustomDivider = ({ colour, ...other }) => (
  <Divider {...other} sx={{ zIndex: Z_INDEX_TOP_VALUE, borderColor: alpha(colour, 0.24) }} />
);

export const CartSummaryTable = ({
  config,
  currency: currencyRoot,
  loading,
  showDiscount: showDiscountRoot,
  isShippingStep,
  isValidDiscount,
  discount: discountAmount,
  shipping,
  subtotal,
  total,
  products,
  onValidateDiscount,
  onClearDiscount,
  props
}) => {
  const theme = useTheme();
  const isMobile = useMobile();
  const currency = defaultTo(currencyRoot?.symbol, '');
  const currencyCode = defaultTo(currencyRoot?.code, '');
  const {
    title,
    mobileTitle,
    showHeader,
    showIcon,
    showHorizontalDivider,
    showDiscount: showDiscountConfig,
    primaryColour,
    secondaryColour,
    mobileHeaderBgColour,
    mobileHeaderContrastColour,
    stickyHeader,
    viewType,
    position,
    totalAmountFontSize,
    totalAmountCurrencyView
  } = config.checkout.preview;
  const { input } = config?.global?.theme?.appearance;
  const defaultColour = primaryColour || theme.palette.primary.main;
  const contrastColour = getContrastColor(theme, secondaryColour);
  const mobileHeaderBg = mobileHeaderBgColour || defaultColour;
  const mobileHeaderContrast = mobileHeaderContrastColour || contrastColour;
  const showDiscount = showDiscountConfig && Boolean(showDiscountRoot && !isMobile);
  const isStandardView = viewType === CART_PREVIEW_VIEW_TYPE[0];

  const DiscountMemo = useMemo(() => {
    return (
      showDiscount && (
        <DiscountInput
          input={input}
          isValidDiscount={isValidDiscount}
          discountProps={{
            value: props?.discount.value,
            name: props?.discount.name,
            onChange: props?.discount.onChange,
            onBlur: props?.discount.onBlur
          }}
          touched={props?.discount.touched}
          errors={props?.discount.errors}
          loading={loading.discount}
          contrastColour={contrastColour}
          validateDiscount={onValidateDiscount}
          onClickClearDiscount={onClearDiscount}
        />
      )
    );
  }, [
    input,
    showDiscount,
    isValidDiscount,
    props?.discount,
    loading.discount,
    contrastColour,
    onValidateDiscount,
    onClearDiscount
  ]);

  const CartProductsMemo = useMemo(() => {
    return (
      <CartProducts
        position={position}
        isMobile={isMobile}
        currency={currency}
        products={products}
        primaryColour={defaultColour}
        contrastColour={contrastColour}
      />
    );
  }, [isMobile, currency, products, defaultColour, contrastColour, position]);

  const CheckoutSummaryMemo = useMemo(() => {
    return (
      <CheckoutSummary
        isMobile={isMobile}
        isShippingStep={isShippingStep}
        currency={currency}
        subTotal={subtotal}
        {...(isValidDiscount && { discount: discountAmount })}
        shipping={shipping}
        contrastColour={contrastColour}
      />
    );
  }, [isMobile, isShippingStep, isValidDiscount, discountAmount, subtotal, shipping, currency, contrastColour]);

  const CheckoutSummaryTotalMemo = useMemo(() => {
    const formattedTotal =
      totalAmountCurrencyView === GENERIC_CURRENCY_VIEW[0]
        ? nfCurrencyCode(total, currencyCode)
        : nfCurrency(total, currency);
    return (
      <TotalRow
        title="Total"
        value={formattedTotal}
        contrastColour={contrastColour}
        sx={{ zIndex: Z_INDEX_TOP_VALUE }}
        fontSx={{ ...(totalAmountFontSize === GENERIC_FONT_SIZE[1] && { fontSize: DEFAULT_FONT_SIZES.h4 }) }}
      />
    );
  }, [total, currency, currencyCode, totalAmountFontSize, totalAmountCurrencyView, contrastColour]);

  const CalculationView = (
    <Content sx={{ zIndex: Z_INDEX_TOP_VALUE, ...(isMobile && { padding: 0 }) }}>
      {loading.table && <SkeletonCart colour={defaultColour} />}
      {!loading.table && !isEmpty(products) && (
        <StackStyle
          spacing={isMobile ? 2 : 3}
          divider={showHorizontalDivider ? <CustomDivider colour={contrastColour} /> : <Box />}
          sx={{ ...(stickyHeader && isStandardView && { position: 'sticky', top: theme.spacing(3) }) }}
        >
          {!isMobile && showHeader && (
            <Stack
              spacing={2}
              alignItems="center"
              direction={position === TEXT_POSITIONS[0] ? 'row' : 'row-reverse'}
              justifyContent={position === TEXT_POSITIONS[0] ? 'start' : 'end'}
            >
              {showIcon && (
                <Icon
                  color={contrastColour}
                  icon={shoppingCartOutline}
                  width={30}
                  height={30}
                  style={{ zIndex: Z_INDEX_TOP_VALUE }}
                />
              )}
              <Typography variant="h4" sx={{ zIndex: Z_INDEX_TOP_VALUE, color: contrastColour }}>
                {isEmpty(title) ? DEFAULT_CHECKOUT_PAGE_TITLE : title}
              </Typography>
            </Stack>
          )}
          {CartProductsMemo}
          {DiscountMemo}
          {CheckoutSummaryMemo}
          {CheckoutSummaryTotalMemo}
        </StackStyle>
      )}
      {!loading.table && isEmpty(products) && null}
    </Content>
  );

  const MobileCalculationView = () => {
    return (
      <AccordionStyle disableGutters elevation={0}>
        <AccordionSummaryStyle
          expandIcon={<Icon icon={arrowIosDownwardFill} width={20} height={20} color="inherit" />}
          sx={{ backgroundColor: mobileHeaderBg, color: mobileHeaderContrast }}
        >
          <Box
            sx={{
              display: 'flex',
              zIndex: Z_INDEX_TOP_VALUE,
              width: '100%',
              paddingRight: (theme) => theme.spacing(1)
            }}
          >
            {showHeader && (
              <>
                {showIcon && <Icon color={mobileHeaderContrast} icon={shoppingCartOutline} width={20} height={20} />}
                <Typography
                  variant="subtitle2"
                  sx={{
                    flexGrow: 1,
                    color: mobileHeaderContrast,
                    fontWeight: (theme) => theme.typography.fontWeightRegular,
                    ...(showIcon && { padding: (theme) => theme.spacing(0, 1.25) })
                  }}
                >
                  {isEmpty(mobileTitle) ? DEFAULT_CHECKOUT_PAGE_MOBILE_TITLE : mobileTitle}
                </Typography>
              </>
            )}
            <Typography
              variant="subtitle2"
              sx={{ color: mobileHeaderContrast, fontWeight: (theme) => theme.typography.fontWeightRegular }}
            >
              {total && nfCurrency(total, currency)}
            </Typography>
          </Box>
        </AccordionSummaryStyle>
        <AccordionDetailsStyle>{CalculationView}</AccordionDetailsStyle>
      </AccordionStyle>
    );
  };

  return isMobile ? <MobileCalculationView /> : CalculationView;
};

const ResponsiveMobileTypogrpahy = ({ children, sx }) => {
  const isMobile = useMobile();
  return (
    <Typography
      variant={isMobile ? 'subtitle2' : 'body2'}
      sx={{ fontWeight: (theme) => theme.typography.fontWeightRegular, ...sx }}
    >
      {children}
    </Typography>
  );
};

const MissingProductImage = ({ isMobile, sx }) => {
  const size = isMobile ? 35 : 45;
  return (
    <Box sx={{ ...sx }}>
      <Icon icon={imageFill} width={size} height={size} />
    </Box>
  );
};

const CartProducts = ({ position, isMobile, currency, products, primaryColour, contrastColour }) => {
  const typographySx = {
    color: contrastColour
  };
  const isLeftPosition = position === TEXT_POSITIONS[0];
  return products.map((product) => {
    const { id, variantId, name, price, size, colour, image, quantity } = product;
    const priceInMajorUnit = price.amount;
    const key = defaultTo(variantId, id);
    return (
      <StackStyle
        key={key}
        spacing={2}
        direction={isLeftPosition ? 'row' : 'row-reverse'}
        justifyContent="space-between"
        sx={{ textAlign: isLeftPosition ? 'start' : 'end' }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'start',
            flexDirection: isLeftPosition ? 'row' : 'row-reverse'
          }}
        >
          <Badge
            sx={{
              '& .MuiBadge-badge': {
                color: primaryColour,
                backgroundColor: contrastColour
              }
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: isLeftPosition ? 'left' : 'right'
            }}
            badgeContent={quantity}
            max={99}
          >
            {isEmpty(image) ? (
              <MissingProductImage
                isMobile={isMobile}
                sx={{
                  padding: (theme) => (isLeftPosition ? theme.spacing(0, 2, 0, 0) : theme.spacing(0, 0, 0, 2)),
                  color: contrastColour
                }}
              />
            ) : (
              <MLazyLoadImage
                alt={defaultTo(name, 'product image')}
                src={image}
                sx={{
                  width: 64,
                  height: 64,
                  ...(isLeftPosition
                    ? { marginRight: (theme) => theme.spacing(2) }
                    : { marginLeft: (theme) => theme.spacing(2) })
                }}
              />
            )}
          </Badge>
          <Box>
            <ResponsiveMobileTypogrpahy
              sx={{ fontWeight: (theme) => theme.typography.fontWeightBold, ...typographySx }}
            >
              {name}
            </ResponsiveMobileTypogrpahy>
            <StackStyle
              direction={isMobile ? 'column' : 'row'}
              {...(!isMobile && {
                divider: <CustomDivider orientation="vertical" colour={contrastColour} flexItem />,
                spacing: 2
              })}
            >
              {size && (
                <ResponsiveMobileTypogrpahy sx={{ ...typographySx }}>Size:&nbsp;{size}</ResponsiveMobileTypogrpahy>
              )}
              {colour && (
                <ResponsiveMobileTypogrpahy sx={{ ...typographySx }}>Colour:&nbsp;{colour}</ResponsiveMobileTypogrpahy>
              )}
            </StackStyle>
            <ResponsiveMobileTypogrpahy sx={{ ...typographySx }}>
              {fCurrency(priceInMajorUnit, currency)}
            </ResponsiveMobileTypogrpahy>
          </Box>
        </Box>
      </StackStyle>
    );
  });
};

const CheckoutSummary = ({ isMobile, isShippingStep, subTotal, discount, shipping, contrastColour, ...other }) => {
  return (
    <StackStyle spacing={isMobile ? 1 : 2}>
      <SummaryRow contrastColour={contrastColour} title="Sub Total" value={subTotal} {...other} />
      {discount && <SummaryRow contrastColour={contrastColour} title="Discount" value={discount} {...other} />}
      <SummaryRow
        contrastColour={contrastColour}
        title="Shipping"
        {...(isShippingStep && { value: shipping })}
        placeholder={isShippingStep ? '-' : 'Calculated at the next step'}
        {...other}
      />
    </StackStyle>
  );
};

const calculateDisplayValue = (currency, value) => (isZero(value) ? 'Free' : fCurrency(value, currency));

const SummaryRow = ({ currency, title, value, placeholder = '-', contrastColour, sx }) => {
  const formatted = value ? calculateDisplayValue(currency, value) : placeholder;
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', ...sx }}>
      <ResponsiveMobileTypogrpahy sx={{ color: contrastColour }}>{title}</ResponsiveMobileTypogrpahy>
      <ResponsiveMobileTypogrpahy sx={{ color: contrastColour }}>{formatted}</ResponsiveMobileTypogrpahy>
    </Box>
  );
};

const TotalRow = ({ title, value, contrastColour, sx, fontSx }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', ...sx }}>
      <ResponsiveMobileTypogrpahy sx={{ color: contrastColour, ...fontSx }}>{title}</ResponsiveMobileTypogrpahy>
      <ResponsiveMobileTypogrpahy sx={{ color: contrastColour, ...fontSx }}>{value}</ResponsiveMobileTypogrpahy>
    </Box>
  );
};
