import React from 'react';
import { Divider, Stack, Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { Sort } from '../core/Sort';
import { Filter } from '../core/Filter';
import useMobile from '../../hooks/useMobile';

const SORT_OPTIONS = [
  {
    name: 'Price: Low - High',
    sortBy: 'price',
    sort: 'asc'
  },
  {
    name: 'Price: High - Low',
    sortBy: 'price',
    sort: 'desc'
  },
  {
    name: 'Latest',
    sortBy: 'createdAt',
    sort: 'desc'
  },
  {
    name: 'Oldest',
    sortBy: 'createdAt',
    sort: 'asc'
  },
  {
    name: 'Alphabetically: A - Z',
    sortBy: 'name',
    sort: 'asc'
  },
  {
    name: 'Alphabetically: Z - A',
    sortBy: 'name',
    sort: 'desc'
  }
];

const RootStyle = styled(Stack)(() => ({
  width: '100%'
}));

const ToolbarStyle = styled(Stack)(({ theme }) => ({
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5)
}));

export const ProductsToolbar = ({
  loading,
  isProductsEmpty,
  filters,
  config,
  handleToggleFilter,
  sort,
  sortBy,
  onSortBy,
  sx
}) => {
  const isMobile = useMobile();
  const {
    showDivider,
    toolbarEnabled: showToolbarOverride,
    sortEnabled,
    filterEnabled,
    buttonsFilled,
    position,
    showFilterIcon,
    showSortIcon,
    filterButtonIndicator
  } = config;
  const filterCount = filters.length;
  const isPositionLeft = position === 'LEFT';
  /**
   * To avoid an empty toolbar this will check if all values are available.
   */
  const showToolbar = showToolbarOverride && Boolean(sortEnabled || filterEnabled);
  const divider = showDivider ? <Divider /> : <Box />;
  return (
    <>
      {showToolbar ? (
        <RootStyle direction="column" spacing={2}>
          {divider}
          <ToolbarStyle
            direction="row"
            spacing={1}
            justifyContent={isPositionLeft ? 'start' : 'end'}
            sx={{ ...(isMobile && { px: (theme) => theme.spacing(2) }) }}
          >
            {filterEnabled && (
              <Filter
                count={filterCount}
                filterButtonIndicator={filterButtonIndicator}
                showIcon={showFilterIcon}
                buttonsFilled={buttonsFilled}
                handleToggleFilter={handleToggleFilter}
                sx={sx}
              />
            )}
            {sortEnabled && (
              <Sort
                disabled={isProductsEmpty || loading}
                showIcon={showSortIcon}
                buttonsFilled={buttonsFilled}
                options={SORT_OPTIONS}
                sort={sort}
                sortBy={sortBy}
                onSortBy={onSortBy}
                sx={sx}
              />
            )}
          </ToolbarStyle>
          {divider}
        </RootStyle>
      ) : (
        divider
      )}
    </>
  );
};
