import React, { useMemo, useCallback } from 'react';
import { Box, Grid, Container } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import InfiniteScroll from 'react-infinite-scroller';
import { defaultTo } from 'lodash';
import { isInvalid } from '../../utils/nullable';
import { getDesktopItemsPerRow, getItemsPerRow } from '../../utils/getItemsPerRow';
import useMobile from '../../hooks/useMobile';
import { CollectionCardListItem } from './CollectionCardListItem';
import EmptyContent from '../core/EmptyContent';
import { Loader } from '../core/Loader';
import {
  DEFAULT_COLLECTIONS_MISSING_DESCRIPTION,
  DEFAULT_COLLECTIONS_MISSING_TITLE,
  DEFAULT_DESKTOP_GRID_SIZE,
  DEFAULT_MOBILE_GRID_SIZE,
  PRODUCTS_TABLE_VIEW_TYPE
} from '../../utils/constants';
import { CompactCollectionListItem } from './CompactCollectionListItem';

const LoaderWrapper = styled(Box)({
  height: '200px'
});

export const CollectionListTable = ({
  config,
  isCollectionsEmpty,
  loading,
  collections,
  hasNext,
  total,
  handleLoadMore,
  handleNavigationClick
}) => {
  const isMobile = useMobile();
  const { desktopCollectionRows, mobileCollectionRows, productsViewType, messaging } = config;
  const isWideView = productsViewType === PRODUCTS_TABLE_VIEW_TYPE[1];
  const numberOfCollections = defaultTo(total, 0);

  const getMobileRows = useCallback(
    () => (mobileCollectionRows ? getItemsPerRow(mobileCollectionRows, numberOfCollections) : DEFAULT_MOBILE_GRID_SIZE),
    [mobileCollectionRows, numberOfCollections]
  );

  const getDesktopRows = useCallback(
    () =>
      desktopCollectionRows
        ? getDesktopItemsPerRow(desktopCollectionRows, numberOfCollections)
        : DEFAULT_DESKTOP_GRID_SIZE,
    [desktopCollectionRows, numberOfCollections]
  );

  const LoaderComponent = () => (
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  );

  const CollectionsView = useMemo(() => {
    const gridSpacing = {
      xs: isMobile ? 3 : 5
    };

    const gridSizing = {
      xs: isMobile ? getMobileRows() : getDesktopRows()
    };

    const compactCollectionView = config?.compactCollectionView;

    return loading ? (
      <LoaderComponent />
    ) : (
      <InfiniteScroll
        initialLoad={false}
        loadMore={handleLoadMore}
        hasMore={hasNext}
        loader={<LoaderComponent key={0} />}
      >
        <Grid container direction="row" spacing={isWideView ? 0 : gridSpacing}>
          {collections.map((_collection) => (
            <Grid key={_collection.id} item {...gridSizing}>
              {compactCollectionView ? (
                <CompactCollectionListItem
                  key={_collection.id}
                  isWideView={isWideView}
                  config={config}
                  collection={_collection}
                  handleNavigationClick={handleNavigationClick}
                />
              ) : (
                <CollectionCardListItem
                  key={_collection.id}
                  config={config}
                  collection={_collection}
                  handleNavigationClick={handleNavigationClick}
                  isWideView={isWideView}
                />
              )}
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>
    );
  }, [
    isWideView,
    config,
    loading,
    collections,
    handleLoadMore,
    hasNext,
    isMobile,
    getMobileRows,
    getDesktopRows,
    handleNavigationClick
  ]);

  return (
    <Container
      maxWidth={false}
      sx={{
        '&.MuiContainer-root': {
          ...(isWideView && { paddingLeft: 0, paddingRight: 0 })
        }
      }}
    >
      {isCollectionsEmpty ? (
        <EmptyContent
          title={isInvalid(messaging?.title) ? DEFAULT_COLLECTIONS_MISSING_TITLE : messaging?.title}
          description={
            isInvalid(messaging?.description) ? DEFAULT_COLLECTIONS_MISSING_DESCRIPTION : messaging?.description
          }
        />
      ) : (
        CollectionsView
      )}
    </Container>
  );
};
