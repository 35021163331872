import { alpha, styled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

export const ColourChip = styled(Box)(({ theme }) => ({
  width: 20,
  height: 20,
  borderRadius: theme.shape.colourIndicatorsBorderRadius,
  border: `1px solid ${alpha(theme.palette.border, 0.32)}`,
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1)
}));
