export const STEPS = ['Information', 'Shipping', 'Payment'];

// Last step is the payment page. The completed transaction page is NOT treated as a step
export const isLastStep = (activeStep) => activeStep === STEPS.length - 1;
export const isFirstStep = (activeStep) => activeStep === 0;

export const SHIPPING_STEP = 1;
export const PAYMENT_STEP = 2;
export const CREDIT_CARD_OPTION_ID = 1;

export const INVALID_DISCOUNT_ERROR = 'Discount is invalid';
export const UNKNOWN_DISCOUNT_ERROR = 'Unable to validate discount at this moment.';
