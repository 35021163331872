import { defaultTo, replace } from 'lodash';

// PAYMENT BUTTON MESSAGE
export const DEFAULT_PAYMENT_BUTTON_MESSAGE = 'Complete Payment';
export const MOBILE_PAYMENT_BUTTON_MESSAGE = 'Pay Now';

// ERROR MESSAGES
export const DEFAULT_PAYMENT_ERROR_ORDER_MESSAGE_HEADER = 'Payment Failed';
export const DEFAULT_PAYMENT_ERROR_ORDER_MESSAGE_TITLE = 'We are unable to process your payment';
export const DEFAULT_PAYMENT_ERROR_ORDER_MESSAGE_BODY = `Unfortunately, There was a problem processing your payment. If this problem persists, please try a different payment method.`;

// INTERNAL ERROR MESSAGES
export const DEFAULT_POST_PAYMENT_ERROR_ORDER_MESSAGE_HEADER = 'Something went wrong';
export const DEFAULT_POST_PAYMENT_ERROR_ORDER_MESSAGE_TITLE = 'We are working to fix this!';
export const DEFAULT_POST_PAYMENT_ERROR_ORDER_MESSAGE_BODY = `Unfortunately, There was a problem completing your order.`;

// INTERNAL ERROR MESSAGES
export const DEFAULT_UNKNOWN_ERROR_ORDER_MESSAGE_HEADER = 'Something went wrong';
export const DEFAULT_UNKNOWN_ERROR_ORDER_MESSAGE_TITLE = 'We cannot confirm your order';
export const DEFAULT_UNKNOWN_ERROR_ORDER_MESSAGE_BODY = `Looks like something went wrong confirming your order. Please try again shortly.`;

// ERROR NEXT STEPS
export const DEFAULT_PAYMENT_ERROR_NEXT_STEPS_MESSAGE =
  'Not to worry, Your shopping cart is still active. This means you can pick up right where you left off.';
export const DEFAULT_POST_PAYMENT_ERROR_NEXT_STEPS_MESSAGE = (supportEmail) => {
  const message = `You do not have to do anything at this moment. We have been notified of this problem and we will reach out to you shortly via email with the progress of your order.`;
  return supportEmail
    ? [
        message,
        `If you have any questions you can reach us at ${supportEmail} or via the 'Contact Us' page. Make sure to reference your order number.`
      ].join('<br/><br/>')
    : [
        message,
        `If you have any questions you can reach us via the 'Contact Us' page. Make sure to reference your order number.`
      ].join('<br/><br/>');
};
export const DEFAULT_UNKNOWN_ERROR_NEXT_STEPS_MESSAGE = (supportEmail) => {
  return supportEmail
    ? `If this problem persists please reach out to us at ${supportEmail}`
    : 'If this problem persists please reach out to us via our contact us page';
};

export const DEFAULT_PRE_PAYMENT_CHECKOUT_ERROR_MESSAGE =
  'We are unable to process your checkout for the selected payment method. If this problem persists please try an alternative payment method.';
export const DEFAULT_PROCESSING_CHECKOUT_FORM_ERROR_MESSAGE =
  'Unfortunately, something went wrong processing your payment method. If this problem persists please try again with an alternative payment method.';

// SUCCESS MESSAGES
export const DEFAULT_ORDER_MESSAGE_HEADER = 'Thank you for your order';
export const DEFAULT_ORDER_MESSAGE_TITLE = 'Your order is confirmed';
export const DEFAULT_ORDER_MESSAGE_BODY =
  'You will receive a confirmation email with the details of your order shortly.';
export const DEFAULT_NEXT_STEPS_TITLE = 'Next Steps';
export const DEFAULT_NEXT_STEPS_MESSAGE = 'You will get shipping and delivery updates by email.';
export const DEFAULT_DISCOUNT_MESSAGE_TITLE = 'Discount for your next purchase.';
export const DEFAULT_DISCOUNT_MESSAGE_BODY = 'Use this discount at checkout for your next purchase ';

// PROCESSING MESSAGES
export const DEFAULT_PROCESSING_ORDER_MESSAGE_HEADER = 'Processing...';
export const DEFAULT_PROCESSING_ORDER_MESSAGE_TITLE = 'We are processing your payment';
export const DEFAULT_PROCESSING_ORDER_MESSAGE_BODY =
  'Hang tight, your payment is being processed. We will reach out to you via email once this is completed.';

// PAYMENTS
export const DEFAULT_SHIPPING_OPTION_MISSING_TITLE = 'Shipping Options Unavailable';
export const DEFAULT_SHIPPING_OPTION_MISSING_DESCRIPTION =
  'There are no shipping options available at this moment. Please try again shortly.';
export const DEFAULT_PAYMENT_OPTION_MISSING_TITLE = 'Payment Methods Unavailable';
export const DEFAULT_PAYMENT_OPTION_MISSING_DESCRIPTION =
  'There are no payment methods available at this moment. Please try again shortly.';

// ERROR MESSAGES
export const DEFAULT_PRODUCTS_MISSING_TITLE = 'No products found';
export const DEFAULT_PRODUCTS_MISSING_DESCRIPTION = 'There are no products to display at this moment';
export const DEFAULT_COLLECTIONS_MISSING_TITLE = 'No collections found';
export const DEFAULT_COLLECTIONS_MISSING_DESCRIPTION = 'There are no collections to display at this moment';
export const DEFAULT_CART_EMPTY_TITLE = 'Cart Is Empty';
export const DEFAULT_CART_EMPTY_DESCRIPTION = 'Look like you have no items in your shopping cart';
export const DEFAULT_CART_EMPTY_BUTTON_TEXT = 'Back to shopping';

// CHECKOUT
export const DEFAULT_CHECKOUT_LOADING_MESSAGE = 'Just a moment while we complete your order...';
export const DEFAULT_CHECKOUT_BACK_HOME_BUTTON_TITLE = 'Back To Shopping';
export const DEFAULT_CHECKOUT_CONTACT_US_PREFIX_MESSAGE = 'Need help?';

// ERROR PAGES
export const DEFAULT_ERROR_404_PAGE_DESCRIPTION = 'This page you are looking for does not exist';
export const DEFAULT_ERROR_500_PAGE_DESCRIPTION = 'Something went wrong loading your page. Please try again shortly';
export const DEFAULT_ERROR_BACK_HOME_BUTTON_TITLE = 'Back to shopping';

// PARAMETERS
export const SUPPORT_EMAIL_TEMPLATE_PATTERN = '$email';
export const CUSTOMER_FIRSTNAME_TEMPLATE_PATTERN = '$firstname';
export const CUSTOMER_LASTNAME_TEMPLATE_PATTERN = '$lastname';

export const fromNameTemplate = (heading, firstName, lastName) => {
  const a = replace(heading, CUSTOMER_FIRSTNAME_TEMPLATE_PATTERN, defaultTo(firstName, ''));
  return replace(a, CUSTOMER_LASTNAME_TEMPLATE_PATTERN, defaultTo(lastName, ''));
};

export const fromEmailTemplate = (heading, email) => {
  return replace(heading, SUPPORT_EMAIL_TEMPLATE_PATTERN, defaultTo(email, ''));
};

// FOOTER SUBSCRIPTION
export const DEFAULT_SUBSCRIPTION_TITLE = 'Newsletter';
export const DEFAULT_SUBSCRIPTION_DESCRIPTION = 'Stay up to date about our latest products and news';
export const DEFAULT_SUBSCRIPTION_PLACEHOLDER = 'Enter your email address';
export const DEFAULT_SUBSCRIPTION_BUTTON_TEXT = 'Sign Up';

// CURRENCY
export const DEFAULT_CURRENCY_LABEL_TEXT = 'Currency';

// FILTERS
export const DEFAULT_FILTER_HEADING = 'Filters';
export const DEFAULT_FILTER_CLEAR_HEADING = 'Clear All';
