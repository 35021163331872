import React from 'react';
import { Collapse, FormControlLabel, Grid, Radio } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { isEmpty } from 'lodash';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
import useMobile from '../../../hooks/useMobile';
import { GenericPaymentInput } from './GenericPaymentInput';
import { PaymentMethodLabel } from './PaymentMethodLabel';
import { PaymentMethodView } from './PaymentMethodView';

export const StandardPaymentMethod = ({ config, option, form, size, children }) => {
  const { values } = form;
  const { id, key, services } = option;
  const { showPaymentServices, showPaymentProviderLogo } = config;
  const isShowServices = !isEmpty(services) && showPaymentServices;
  const isMobile = useMobile();

  return (
    <Grid key={id} item {...size}>
      <PaymentMethodView>
        <FormControlLabel
          value={key}
          control={<Radio checkedIcon={<Icon icon={checkmarkCircle2Fill} />} />}
          disableTypography
          label={
            <PaymentMethodLabel
              showLogo={showPaymentProviderLogo}
              option={option}
              isMobile={isMobile}
              isShowServices={isShowServices}
            />
          }
          componentsProps={{
            typography: {
              sx: {
                width: '100%'
              }
            }
          }}
          sx={{ flexGrow: 1, py: (theme) => (isMobile ? theme.spacing(2) : theme.spacing(3)) }}
        />
        <Collapse in={values.payment === key} sx={{ width: '100%' }}>
          {children || <GenericPaymentInput option={option} />}
        </Collapse>
      </PaymentMethodView>
    </Grid>
  );
};
