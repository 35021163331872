import React from 'react';
import { alpha, styled, useTheme } from '@material-ui/core/styles';
import { Box, Stack, Typography, useMediaQuery } from '@material-ui/core';
import * as AspectRatioPrimitive from '@radix-ui/react-aspect-ratio';
import { defaultTo, isEmpty } from 'lodash';
import { MLazyLoadImage } from '../core/@react-lazy-load-image-component-extend';
import missingImageDefault from '../../static/placeholder/collection_image.jpg';
import { COLLECTION_PAGE } from '../../utils/pageTypes';
import { trimString } from '../../utils/stringUtils';
import { getTextAlignPosition } from '../../utils/getPositions';

export const AspectRatio = AspectRatioPrimitive;

const CollectionContainer = styled(Box)(() => ({
  position: 'relative',
  textAlign: 'left',
  cursor: 'pointer',
  '&:hover': { opacity: 0.72 },
  transition: 'all 0.3s ease-in-out'
}));

const BackgroundOverlay = styled(Box)(({ theme }) => ({
  top: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  position: 'absolute',
  borderRadius: theme.shape.borderRadiusSm
}));

const Image = styled(MLazyLoadImage)(() => ({
  zIndex: 0,
  width: '100%',
  height: '100%',
  top: 0,
  position: 'absolute'
}));

const Content = styled(Stack)(({ theme }) => ({
  zIndex: 2,
  position: 'absolute',
  padding: theme.spacing(3),
  width: '100%',
  bottom: 0,
  color: 'white'
}));

export const CompactCollectionListItem = ({ config, isWideView, collection, handleNavigationClick }) => {
  const { name, resource, image, count } = collection;
  const { prominentTitle, textPosition, showNumberOfProducts, compactShowSpaceBetweenRows } = config;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const textPositionAlignment = getTextAlignPosition(textPosition);

  return (
    <CollectionContainer
      onClick={() => {
        if (resource) {
          handleNavigationClick(COLLECTION_PAGE, resource);
        }
      }}
      sx={{
        ...(Boolean(compactShowSpaceBetweenRows && isWideView) && {
          marginBottom: (theme) => (isMobile ? theme.spacing(3) : theme.spacing(6))
        })
      }}
    >
      <AspectRatio.Root ratio={1 / 1}>
        <BackgroundOverlay sx={{ backgroundColor: (theme) => alpha(theme.palette.grey[900], 0.5) }} />
        <Image alt={defaultTo(name, '')} src={image || missingImageDefault} />

        <Content spacing={0.5} sx={{ textAlign: textPositionAlignment }}>
          {!isEmpty(name) && (
            <Typography
              variant={isMobile ? 'body1' : 'h6'}
              color="inherit"
              sx={{
                fontWeight: (theme) =>
                  prominentTitle ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular
              }}
            >
              {trimString(50, name)}
            </Typography>
          )}
          {Boolean(showNumberOfProducts && count) && (
            <Typography color="inherit" variant={isMobile ? 'body2' : 'body1'}>
              {`${count} Products`}
            </Typography>
          )}
        </Content>
      </AspectRatio.Root>
    </CollectionContainer>
  );
};
