import React, { useMemo } from 'react';
import { alpha, styled } from '@material-ui/core/styles';
import { Box, Button, Typography, Stack } from '@material-ui/core';
import * as AspectRatioPrimitive from '@radix-ui/react-aspect-ratio';
import { defaultTo, isEmpty } from 'lodash';
import { getHorizontalPosition, getVerticalPosition } from '../../utils/getPositions';
import useMobile from '../../hooks/useMobile';
import { MLazyLoadImage } from '../core/@react-lazy-load-image-component-extend';
import { varFadeIn, varFadeInUp, MotionInView } from '../core/animate';
import placeholderImage from '../../static/placeholder/Image_and_text.jpg';
import LayoutOrientation from '../core/LayoutOrientation';
import { getSelectedImage } from '../../utils/getSelectedImage';

export const AspectRatio = AspectRatioPrimitive;

const HEIGHT_CONSTRAINT_STYLE = {
  minHeight: '400px',
  maxHeight: '850px'
};

const ComponentContainer = styled(Box)(() => ({
  position: 'relative'
}));

const ImageContainer = styled(MLazyLoadImage)(() => ({
  width: '100%',
  height: '100%',
  ...HEIGHT_CONSTRAINT_STYLE
}));

const BackgroundOverlay = styled(Box)(() => ({
  top: 0,
  zIndex: 2,
  width: '100%',
  height: '100%',
  position: 'absolute'
}));

const Content = styled(Box)(({ theme }) => ({
  top: 0,
  bottom: 0,
  zIndex: 3,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(10)
}));

function SimpleImageAndText({ component, handleNavigationClick = () => {} }) {
  const isMobile = useMobile();

  const SimpleImageAndTextMemo = useMemo(() => {
    const {
      title,
      subtitle,
      contentPosition,
      showOverlay,
      desktopImage,
      mobileImage,
      buttonText,
      navigation,
      titleColour,
      subTitleColour
    } = component;

    const img = getSelectedImage(isMobile, desktopImage, mobileImage);

    return (
      <ComponentContainer
        sx={{
          ...HEIGHT_CONSTRAINT_STYLE
        }}
      >
        <MotionInView variants={varFadeIn} sx={{ height: '100%' }}>
          <AspectRatio.Root ratio={isMobile ? 3 / 4 : 16 / 9}>
            <ImageContainer alt={defaultTo(title, '')} src={img || placeholderImage} imgSx={{ borderRadius: 0 }} />
          </AspectRatio.Root>
        </MotionInView>

        <BackgroundOverlay
          sx={{
            ...(showOverlay && {
              backgroundImage: (theme) =>
                `linear-gradient(to top, ${theme.palette.grey[900]} 0%,${alpha(theme.palette.grey[900], 0)} 100%)`
            })
          }}
        />

        <Content sx={{ ...(isMobile && { padding: (theme) => theme.spacing(3, 3, 8) }) }}>
          <LayoutOrientation
            verticalPosition={getVerticalPosition(contentPosition)}
            horizontalPosition={getHorizontalPosition(contentPosition)}
          >
            <MotionInView variants={varFadeInUp}>
              <Stack spacing={1} sx={{ textAlign: getHorizontalPosition(contentPosition) }}>
                {!isEmpty(title) && (
                  <Typography
                    variant="h3"
                    sx={{ color: (theme) => defaultTo(titleColour, theme.palette.common.white) }}
                  >
                    {title}
                  </Typography>
                )}
                {!isEmpty(subtitle) && (
                  <Typography
                    variant="h6"
                    sx={{ color: (theme) => defaultTo(subTitleColour, theme.palette.common.white) }}
                  >
                    {subtitle}
                  </Typography>
                )}
              </Stack>
            </MotionInView>
            {!isEmpty(buttonText) && (
              <MotionInView variants={varFadeInUp}>
                <Button variant="contained" onClick={() => handleNavigationClick(navigation?.type, navigation?.path)}>
                  {buttonText}
                </Button>
              </MotionInView>
            )}
          </LayoutOrientation>
        </Content>
      </ComponentContainer>
    );
  }, [isMobile, component, handleNavigationClick]);

  return SimpleImageAndTextMemo;
}

export default SimpleImageAndText;
