// ----------------------------------------------------------------------

import { alpha } from '@material-ui/core/styles';

export default function Divider(theme) {
  return {
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: alpha(theme.palette.divider, 0.24)
        }
      }
    }
  };
}
