import React, { useMemo } from 'react';
import { Grid, Container, Stack, Box } from '@material-ui/core';
import { defaultTo, isEmpty } from 'lodash';
import useMobile from '../../hooks/useMobile';
import { ProductCardListItem } from './ProductCardListItem';
import {
  VERTICAL_MOBILE_SCROLL_DIRECTION,
  DEFAULT_RECENTLY_VIEWED_SIZE,
  DESKTOP_CONTAINER_LEFT_RIGHT_PADDING,
  DESKTOP_STACK_SPACING,
  MOBILE_CONTAINER_LEFT_RIGHT_PADDING,
  MOBILE_STACK_SPACING,
  PRODUCTS_TABLE_VIEW_TYPE,
  DEFAULT_PRODUCTS_RECENTLY_VIEWED_TITLE
} from '../../utils/constants';
import { getTextAlignPosition } from '../../utils/getPositions';
import { StandardPageHeading } from '../core/StandardPageHeading';

const gridSpacing = {
  xs: 3
};

export const ProductsRecentlyViewedTable = ({ config, currency, products, handleNavigationClick }) => {
  const {
    title,
    titleColour,
    recentlyViewedSize,
    showProductInfo = true,
    mobileScrollDirection,
    productsViewType
  } = config.recentlyViewed;
  const size = defaultTo(recentlyViewedSize, DEFAULT_RECENTLY_VIEWED_SIZE);
  const isMobile = useMobile();
  const isWideView = productsViewType === PRODUCTS_TABLE_VIEW_TYPE[1];
  const stackSpacing = isMobile ? MOBILE_STACK_SPACING : DESKTOP_STACK_SPACING;
  const containerWidthPadding = isMobile ? MOBILE_CONTAINER_LEFT_RIGHT_PADDING : DESKTOP_CONTAINER_LEFT_RIGHT_PADDING;
  const isVerticalMobileScroll =
    defaultTo(mobileScrollDirection, VERTICAL_MOBILE_SCROLL_DIRECTION) === VERTICAL_MOBILE_SCROLL_DIRECTION;
  const showVerticalScrollDirection = isMobile ? isVerticalMobileScroll : true;

  const HorizontalScroll = useMemo(() => {
    return (
      <Stack
        direction="row"
        alignItems="flex-start"
        spacing={isWideView ? 0 : 3}
        sx={{ overflowY: 'hidden', paddingBottom: (theme) => theme.spacing(2) }}
      >
        {products.slice(0, size).map((_product) => (
          <ProductCardListItem
            key={_product.id}
            config={{
              ...config,
              ...config.recentlyViewed
            }}
            currency={currency}
            showProductInfo={showProductInfo}
            product={_product}
            handleNavigationClick={handleNavigationClick}
            isWideView={isWideView}
            sx={{ maxWidth: '350px', ...(!showVerticalScrollDirection && { minWidth: '250px' }) }}
          />
        ))}
      </Stack>
    );
  }, [
    config,
    size,
    products,
    currency,
    showProductInfo,
    showVerticalScrollDirection,
    isWideView,
    handleNavigationClick
  ]);

  const VerticalScroll = useMemo(() => {
    const gridSizing = {
      xs: isMobile ? 12 : 3
    };
    return (
      <Box>
        <Grid
          container
          direction="row"
          spacing={isWideView ? 0 : gridSpacing}
          {...(!isMobile && { justifyContent: getTextAlignPosition(config.recentlyViewed.position) })}
        >
          {products.slice(0, size).map((_product) => (
            <Grid key={_product.id} item {...gridSizing}>
              <ProductCardListItem
                config={{
                  ...config,
                  ...config.recentlyViewed
                }}
                currency={currency}
                showProductInfo={showProductInfo}
                product={_product}
                handleNavigationClick={handleNavigationClick}
                isWideView={isWideView}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }, [isMobile, config, size, products, currency, showProductInfo, isWideView, handleNavigationClick]);

  return (
    <Container
      maxWidth={false}
      sx={{
        '&.MuiContainer-root': {
          ...(isWideView
            ? { paddingLeft: 0, paddingRight: 0 }
            : { padding: (theme) => theme.spacing(0, containerWidthPadding) })
        }
      }}
    >
      <Stack spacing={stackSpacing} direction="column">
        <StandardPageHeading title={isEmpty(title) ? DEFAULT_PRODUCTS_RECENTLY_VIEWED_TITLE : title} titleColour={titleColour} />
        {showVerticalScrollDirection ? VerticalScroll : HorizontalScroll}
      </Stack>
    </Container>
  );
};
