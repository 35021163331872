import React from 'react';
import PropTypes from 'prop-types';
// material
import { styled } from '@material-ui/core/styles';
import { Typography, Box, Button } from '@material-ui/core';

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  padding: theme.spacing(5),
  minHeight: 300
}));

// ----------------------------------------------------------------------

EmptyContent.propTypes = {
  title: PropTypes.string,
  img: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string,
  onButtonClick: PropTypes.func
};

export default function EmptyContent({
  title,
  description,
  img,
  imgComponent,
  buttonText,
  onButtonClick,
  colour,
  variant = 'h3',
  fontSx,
  ...other
}) {
  return (
    <RootStyle {...other}>
      {img && <Box component="img" alt="empty content" src={img} sx={{ height: 240, mb: 3 }} />}
      {imgComponent && <Box sx={{ height: 75, mb: 3 }}>{imgComponent}</Box>}
      <Typography
        variant={variant}
        gutterBottom
        sx={{ fontWeight: 300, color: (theme) => colour || theme.palette.primary.main, ...fontSx }}
      >
        {title}
      </Typography>

      {description && (
        <Typography variant="body1" sx={{ color: (theme) => colour || theme.palette.text.secondary, ...fontSx }}>
          {description}
        </Typography>
      )}
      {buttonText && (
        <Box sx={{ mt: (theme) => theme.spacing(5), justifyContent: 'center' }}>
          <Button color="primary" variant="contained" onClick={onButtonClick}>
            {buttonText}
          </Button>
        </Box>
      )}
    </RootStyle>
  );
}
