import React, { useState } from 'react';
import { Stack, Typography, Box, Tooltip, CircularProgress } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { alpha, styled } from '@material-ui/core/styles';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import Check from '@material-ui/icons/Check';
import { getTextAlignPosition } from '../../utils/getPositions';
import {
  DEFAULT_SUBSCRIPTION_TITLE,
  DEFAULT_SUBSCRIPTION_DESCRIPTION,
  DEFAULT_SUBSCRIPTION_PLACEHOLDER,
  DEFAULT_SUBSCRIPTION_BUTTON_TEXT
} from '../../utils/constants';
import { defaultTo } from '../../utils/nullable';
import { LinkHeaderText } from './LinkHeaderText';
import MTextField from '../core/@material-extend/MTextField';
import inputStyle from './InputStyle';

export default function fakeRequest(time) {
  return new Promise((res) => setTimeout(res, time));
}

const NEWSLETTER_MAX_WIDTH = 600;
const NEWSLETTER_MIN_WIDTH = 350;

const RootStyle = styled(Box)(() => ({
  display: 'flex'
}));

const InnerStyle = styled(Box)(() => ({
  maxWidth: `${NEWSLETTER_MAX_WIDTH}px`,
  minWidth: `${NEWSLETTER_MIN_WIDTH}px`
}));

export const NewsletterInput = ({ isMobile, config, primaryColour, secondaryColour, onCreateCustomer, sx }) => {
  const {
    title,
    description,
    horizontalPosition,
    textPosition: textPositionRoot,
    mobileTextPosition: mobileTextPositionRoot,
    placeholder,
    buttonText,
    buttonColour: buttonColourRoot,
    buttonTextColour: buttonTextColourRoot,
    input
  } = config;
  const textPosition = isMobile ? mobileTextPositionRoot : textPositionRoot;
  const buttonColour = (theme) => defaultTo(buttonColourRoot, theme.palette.primary.main);
  const buttonTextColour = (theme) => defaultTo(buttonTextColourRoot, theme.palette.primary.contrastText);
  const [state, setState] = useState({
    sent: false
  });

  const NewsletterSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: ''
    },
    validationSchema: NewsletterSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setState({ sent: false });
        await onCreateCustomer(values.email);
        setState({ sent: true });
        setSubmitting(false);
      } catch (error) {
        setState({ sent: false });
        setSubmitting(false);
      }
    }
  });

  const { touched, errors, isSubmitting, getFieldProps, handleSubmit } = formik;

  return (
    <RootStyle
      sx={{
        justifyContent: isMobile ? 'center' : getTextAlignPosition(horizontalPosition),
        textAlign: getTextAlignPosition(textPosition),
        ...sx
      }}
    >
      <InnerStyle>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={1} direction="column" sx={{ color: primaryColour }}>
              <LinkHeaderText variant="body1" sx={{ color: primaryColour }}>
                {defaultTo(title, DEFAULT_SUBSCRIPTION_TITLE)}
              </LinkHeaderText>
              <Typography variant="body1" sx={{ color: secondaryColour }}>
                {defaultTo(description, DEFAULT_SUBSCRIPTION_DESCRIPTION)}
              </Typography>
              <Box sx={{ padding: (theme) => theme.spacing(2, 0, 1, 0) }}>
                <MTextField
                  shape={input}
                  disabled={state.sent}
                  fullWidth
                  label={defaultTo(placeholder, DEFAULT_SUBSCRIPTION_PLACEHOLDER)}
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{
                    ...inputStyle(primaryColour)
                  }}
                  InputProps={{
                    style: {
                      color: primaryColour
                    }
                  }}
                  InputLabelProps={{
                    style: {
                      color: primaryColour
                    }
                  }}
                />
              </Box>
              <Box>
                <LoadingButton
                  disabled={state.sent}
                  loading={isSubmitting}
                  variant="contained"
                  type="submit"
                  loadingIndicator={
                    <Box
                      sx={{
                        display: 'flex',
                        color: (theme) => buttonTextColour(theme)
                      }}
                    >
                      <CircularProgress color="inherit" size={18} />
                    </Box>
                  }
                  sx={{
                    '&.MuiLoadingButton-root:hover': {
                      backgroundColor: (theme) => buttonColour(theme)
                    },

                    '&.MuiLoadingButton-loading': {
                      minWidth: '100px',
                      backgroundColor: (theme) => alpha(buttonColour(theme), 0.24)
                    },
                    '&.Mui-disabled': {
                      minWidth: '100px',
                      backgroundColor: (theme) => alpha(buttonColour(theme), 0.24)
                    },
                    backgroundColor: (theme) => buttonColour(theme),
                    boxShadow: 'none'
                  }}
                >
                  {state.sent ? (
                    <Tooltip title="Success">
                      <Box sx={{ display: 'flex', color: (theme) => buttonTextColour(theme) }}>
                        <Check color="inherit" />
                      </Box>
                    </Tooltip>
                  ) : (
                    !isSubmitting && (
                      <Box component="span" sx={{ color: (theme) => buttonTextColour(theme) }}>
                        {defaultTo(buttonText, DEFAULT_SUBSCRIPTION_BUTTON_TEXT)}
                      </Box>
                    )
                  )}
                </LoadingButton>
              </Box>
            </Stack>
          </Form>
        </FormikProvider>
      </InnerStyle>
    </RootStyle>
  );
};
