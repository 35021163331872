import { alpha } from '@material-ui/core/styles';

const inputStyle = (colour) => {
  return {
    '& svg': {
      color: colour
    },
    '& label.Mui-focused': {
      color: colour
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: colour
    },
    '& .MuiOutlinedInput-input': {
      '&.Mui-disabled': {
        color: colour,
        '-webkit-text-fill-color': colour
      }
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: alpha(colour, 0.5)
      },
      '&:hover fieldset': {
        borderColor: colour
      },
      '&.Mui-focused fieldset': {
        borderColor: colour
      },
      '&.Mui-disabled': {
        backgroundColor: (theme) => alpha(theme.palette.common.white, 0.1)
      }
    }
  };
};

export default inputStyle;
