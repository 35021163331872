import React from 'react';
import { Box } from '@material-ui/core';
import { SummarySection, TextHeadingStyle, TextStyle } from './SummarySection';

export default function ContactDetailsView({
  showBorder,
  title,
  customer: { email },
  sx: { title: titleSx, content: contentSx }
}) {
  return (
    <SummarySection showBorder={showBorder} title={title || 'Contact Details'} sx={titleSx}>
      <Box sx={contentSx}>
        <TextHeadingStyle variant="subtitle1">Email</TextHeadingStyle>
        <TextStyle variant="body1">{email}</TextStyle>
      </Box>
    </SummarySection>
  );
}
