import React from 'react';
import { Typography } from '@material-ui/core';
import clockFill from '@iconify/icons-bi/clock-fill';
import {
  DEFAULT_PROCESSING_ORDER_MESSAGE_HEADER,
  DEFAULT_PROCESSING_ORDER_MESSAGE_TITLE,
  DEFAULT_PROCESSING_ORDER_MESSAGE_BODY
} from '../../../../utils/constants';
import { SummarySection } from '../SummarySection';
import { OrderDetails } from '../OrderMessageSuccessView';

export default function OrderMessageProcessingView({
  heading,
  title,
  message,
  headingProps,
  showHeaderIcon,
  showBorder,
  orderId,
  customer,
  sx
}) {
  const { title: titleSx, content: contentSx } = sx;
  return (
    <SummarySection
      showBorder={showBorder}
      header={
        <OrderDetails
          icon={clockFill}
          heading={heading}
          headingProps={headingProps}
          fallbackHeading={DEFAULT_PROCESSING_ORDER_MESSAGE_HEADER}
          orderId={orderId}
          customer={customer}
          showHeaderIcon={showHeaderIcon}
          sx={sx}
        />
      }
      title={title || DEFAULT_PROCESSING_ORDER_MESSAGE_TITLE}
      sx={titleSx}
    >
      <Typography variant="body1" sx={{ color: 'text.secondary', ...contentSx }}>
        {message || DEFAULT_PROCESSING_ORDER_MESSAGE_BODY}
      </Typography>
    </SummarySection>
  );
}
