import React from 'react';
import { Box, Button, Stack, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { DEFAULT_ERROR_BACK_HOME_BUTTON_TITLE } from '../../utils/constants';

const MessageContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(10),
  display: 'flex',
  justifyContent: 'center',
  minHeight: 500,
  alignItems: 'center'
}));

const Message = styled(Stack)(() => ({
  width: 680,
  textAlign: 'center'
}));

export default function GenericErrorPage({ title, description, buttonText, handleNavigationClick }) {
  return (
    <MessageContainer>
      <Message spacing={2} direction="column">
        <Typography variant="h3" sx={{ color: (theme) => theme.palette.primary.main }}>
          {title}
        </Typography>
        <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.primary }}>
          {description}
        </Typography>
        <div>
          <Button variant="contained" onClick={handleNavigationClick} sx={{ mt: (theme) => theme.spacing(2) }}>
            {buttonText || DEFAULT_ERROR_BACK_HOME_BUTTON_TITLE}
          </Button>
        </div>
      </Message>
    </MessageContainer>
  );
}
