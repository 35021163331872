import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { defaultTo } from 'lodash';
import { paramCase } from 'change-case';
import useMobile from '../../../hooks/useMobile';
import { DEFAULT_PAYMENT_BUTTON_MESSAGE } from '../../../utils/constants';
import EmptyContent from '../../core/EmptyContent';
import { pxToRem, DEFAULT_FONT_SIZES } from '../../../theme/typography';
import IllustrationBrowser from '../illustration/IllustrationBrowser';

export const GenericPaymentInput = ({ option }) => {
  const isMobile = useMobile();
  const theme = useTheme();

  const { key, title } = option;
  const isCash = paramCase(defaultTo(key, '')) === 'cash';
  const redirectMessage = `After clicking ${DEFAULT_PAYMENT_BUTTON_MESSAGE}, you will be redirected to ${title} to complete your order securely`;
  const completeMessage = `After clicking ${DEFAULT_PAYMENT_BUTTON_MESSAGE}, you will be presented with the final details of your order`;

  return (
    <EmptyContent
      title=""
      description={isCash ? completeMessage : redirectMessage}
      imgComponent={<IllustrationBrowser colour={theme.palette.border} />}
      sx={{
        minHeight: 0,
        height: '240px',
        ...(isMobile && { px: (theme) => theme.spacing(0) })
      }}
      fontSx={{
        ...(isMobile && { fontSize: pxToRem(DEFAULT_FONT_SIZES.textMedium) })
      }}
    />
  );
};
