import roundAddShoppingCart from '@iconify/icons-ic/round-add-shopping-cart';
import shoppingBasket from '@iconify/icons-ion/basket-outline';
import plus from '@iconify/icons-akar-icons/plus';
import plusOne from '@iconify/icons-ic/round-plus-one';
import cubeAdd from '@iconify/icons-fluent/cube-add-20-regular';

export const getAddToCartIcon = (type) => {
  const defaultIcon = roundAddShoppingCart;

  switch (type) {
    case 'CART':
      return defaultIcon;
    case 'BASKET':
      return shoppingBasket;
    case 'PLUS':
      return plus;
    case 'PLUS_ONE':
      return plusOne;
    case 'CUBE':
      return cubeAdd;
    default:
      return defaultIcon;
  }
};
