import React from 'react';
import { Box, Stack } from '@material-ui/core';
import { Icon } from '@iconify/react';
import { paramCase } from 'change-case';
import { defaultTo } from 'lodash';
import imageFill from '@iconify/icons-bi/image-fill';
import icKlarna from '../../static/icons/ic_klarna.svg';
import icKlarnaLogo from '../../static/icons/ic_klarna_logo.svg';
import icClearpay from '../../static/icons/ic_clearpay.svg';
import icClearpayLogo from '../../static/icons/ic_clearpay_logo.png';
import icRevolut from '../../static/icons/ic_revolut.svg';
import icRevolutLogo from '../../static/icons/ic_revolut_logo.svg';
import icPaypal from '../../static/icons/ic_paypal.svg';
import icPaypalLogo from '../../static/icons/ic_paypal_logo.svg';
import icMastercard from '../../static/icons/ic_mastercard.svg';
import icMaestro from '../../static/icons/ic_maestro.svg';
import icUnionPay from '../../static/icons/ic_union_pay.svg';
import icVisa from '../../static/icons/ic_visa.svg';
import icAmex from '../../static/icons/ic_amex.svg';
import icDiscover from '../../static/icons/ic_discover.svg';
import icDinersClub from '../../static/icons/ic_diners_club.svg';
import icApplePay from '../../static/icons/ic_apple_pay.svg';
import icGooglePay from '../../static/icons/ic_google_pay.svg';

import { PAYMENT_METHOD_EXAMPLE_TYPE } from '../../utils/payments';

const PROVIDER_ICONS = [
  {
    key: 'revolut',
    image: icRevolutLogo
  },
  {
    key: 'clearpay',
    image: icClearpayLogo
  },
  {
    key: 'paypal',
    image: icPaypalLogo
  },
  {
    key: 'klarna',
    image: icKlarnaLogo
  }
];

const SERVICE_ICONS = [
  {
    key: 'mastercard',
    image: icMastercard
  },
  {
    key: 'maestro',
    image: icMaestro
  },
  {
    key: 'visa',
    image: icVisa
  },
  {
    key: 'amex',
    image: icAmex
  },
  {
    key: 'discover',
    image: icDiscover
  },
  {
    key: 'diners_club',
    image: icDinersClub
  },
  {
    key: 'union_pay',
    image: icUnionPay
  },
  {
    key: 'revolut',
    image: icRevolut
  },
  {
    key: 'clearpay',
    image: icClearpay
  },
  {
    key: 'paypal',
    image: icPaypal
  },
  {
    key: 'klarna',
    image: icKlarna
  },
  {
    key: 'apple_pay',
    image: icApplePay
  },
  {
    key: 'google_pay',
    image: icGooglePay
  }
];

const IconView = ({ id, icon, alt, sx }) => (
  <Box key={id} component="img" alt={alt} src={icon} sx={{ maxWidth: 'unset', ...sx }} />
);

export const ServiceIcons = ({ services, sx, ...other }) => (
  <Stack spacing={0.3} direction="row" sx={{ flexFlow: 'wrap' }} {...other}>
    {services.map((service) => {
      const find = SERVICE_ICONS.find(
        (_icon) => paramCase(defaultTo(_icon.key, '')) === paramCase(defaultTo(service, ''))
      );
      return (
        find && (
          <IconView
            key={find.key}
            id={find.name}
            alt="Provider service icon"
            icon={find.image}
            sx={{
              maxWidth: '35px',
              height: 'fit-content',
              maxHeight: '24px',
              alignSelf: 'center',
              ...sx
            }}
          />
        )
      );
    })}
  </Stack>
);

export const ProviderIcon = ({ isMobile, type, provider }) => {
  if (type === PAYMENT_METHOD_EXAMPLE_TYPE) {
    const size = isMobile ? 25 : 30;
    return (
      <Box sx={{ color: (theme) => theme.palette.text.secondary, mt: (theme) => theme.spacing(0.5) }}>
        <Icon icon={imageFill} width={size} height={size} />
      </Box>
    );
  }
  const find = PROVIDER_ICONS.find(
    (_icon) => paramCase(defaultTo(_icon.key, '')) === paramCase(defaultTo(provider, ''))
  );
  return find ? (
    <Box>
      <IconView
        key={find.key}
        alt="Payment provider icon"
        icon={find.image}
        sx={{
          mt: '6px',
          height: '30px'
        }}
      />
    </Box>
  ) : null;
};
