import React from 'react';
import { Button, Box, Badge } from '@material-ui/core';
import { Icon } from '@iconify/react';
import filterListRounded from '@iconify/icons-material-symbols/filter-list-rounded';

export const Filter = ({ count, filterButtonIndicator, showIcon, buttonsFilled, handleToggleFilter, sx }) => {
  const isIndicatorDisabled = filterButtonIndicator == null;
  const isCountIndicator = filterButtonIndicator === 'COUNT';
  const isDotIndicator = filterButtonIndicator === 'DOT';
  const isButtonFilled = buttonsFilled === 'FILLED';

  return (
    <Box sx={{ ...sx }}>
      <Button
        color={isButtonFilled ? 'primary' : 'inherit'}
        {...(isButtonFilled && {
          variant: 'contained'
        })}
        disableRipple
        onClick={handleToggleFilter}
        {...(showIcon && {
          startIcon:
            isIndicatorDisabled || count === 0 ? (
              <Icon icon={filterListRounded} color="inherit" />
            ) : (
              <Badge
                color={isButtonFilled ? 'secondary' : 'primary'}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                {...(isCountIndicator && { badgeContent: count })}
                max={99}
                {...(isDotIndicator && { variant: 'dot' })}
              >
                <Icon icon={filterListRounded} color="inherit" />
              </Badge>
            )
        })}
        sx={{
          minHeight: (theme) => theme.spacing(5.5),
          fontSize: (theme) => theme.typography.body2,
          fontWeight: (theme) => theme.typography.fontWeightMedium,
          padding: (theme) => theme.spacing(1, 2)
        }}
      >
        <span>Filter</span>
      </Button>
    </Box>
  );
};
