import React from 'react';
import { Typography } from '@material-ui/core';
import { DEFAULT_NEXT_STEPS_MESSAGE, DEFAULT_NEXT_STEPS_TITLE } from '../../../utils/constants';
import { SummarySection } from './SummarySection';

export default function NextStepsMessageView({
  showBorder,
  title,
  message,
  sx: { title: titleSx, content: contentSx }
}) {
  return (
    <SummarySection showBorder={showBorder} title={title || DEFAULT_NEXT_STEPS_TITLE} sx={titleSx}>
      <Typography variant="body1" sx={{ color: 'text.secondary', ...contentSx }}>
        {message || DEFAULT_NEXT_STEPS_MESSAGE}
      </Typography>
    </SummarySection>
  );
}
