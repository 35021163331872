import React from 'react';
import { Box, Stack, Skeleton } from '@material-ui/core';
import { darken } from '@material-ui/core/styles';

const SkeletonStyle = ({ colour, sx, ...other }) => (
  <Skeleton
    {...other}
    sx={{
      backgroundColor: (theme) => (colour ? darken(colour, 0.15) : darken(theme.palette.primary.main, 0.15)),
      ...sx
    }}
  />
);

const FormInputSkeleton = ({ colour }) => <SkeletonStyle colour={colour} variant="rectangular" sx={{ height: 50 }} />;

export const SkeletonCart = ({ colour }) => (
  <Stack spacing={3} direction="column">
    <SkeletonStyle colour={colour} variant="text" sx={{ width: '50%' }} />
    <Stack spacing={2} direction="row">
      <Box>
        <SkeletonStyle colour={colour} variant="rectangular" sx={{ width: 64, height: 64 }} />
      </Box>
      <Stack spacing={1} direction="column" sx={{ width: '100%' }}>
        <SkeletonStyle colour={colour} variant="text" />
        <SkeletonStyle colour={colour} variant="text" sx={{ width: '50%' }} />
      </Stack>
    </Stack>
    <FormInputSkeleton colour={colour} />
    <Stack spacing={1} direction="column">
      <SkeletonStyle colour={colour} variant="text" />
      <SkeletonStyle colour={colour} variant="text" />
    </Stack>
  </Stack>
);
