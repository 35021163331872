// material
import React from 'react';
import { HeaderNavigationTab } from './HeaderNavigationTab';
import { StandardHeaderBar } from './StandardHeaderBar';
import HeaderToolbarStyle from './styled/HeaderToolbarStyle';
import { APPBAR_DESKTOP_STANDARD } from './util/HeaderConstant';

const StandardHeader = (props) => {
  const {
    enableNavigation,
    navigationViewType,
    showDivider,
    textColour,
    isNavigationActive,
    currentTabValue,
    navigation,
    navigationPosition,
    handleNavigationClick
  } = props;

  const StandardHeaderBarView = <StandardHeaderBar {...props} />;

  return (
    <>
      <HeaderToolbarStyle>{StandardHeaderBarView}</HeaderToolbarStyle>
      {enableNavigation && (
        <HeaderToolbarStyle sx={{ '&.MuiToolbar-root': { height: APPBAR_DESKTOP_STANDARD } }}>
          <HeaderNavigationTab
            config={{ viewType: navigationViewType, showDivider, textColour }}
            enabled={isNavigationActive}
            activeTab={currentTabValue}
            navigation={navigation.data}
            position={navigationPosition}
            handleNavigationClick={handleNavigationClick}
          />
        </HeaderToolbarStyle>
      )}
    </>
  );
};

export default StandardHeader;
