import React from 'react';
import { Divider, Stack, Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { isEmpty } from 'lodash';
import useMobile from '../../hooks/useMobile';
import ContactForm from './ContactForm';
import { getTextAlignPosition } from '../../utils/getPositions';
import {
  MOBILE_STACK_SPACING,
  DESKTOP_STACK_SPACING,
  DESKTOP_CONTAINER_LEFT_RIGHT_PADDING,
  CONTACT_VIEW_DIRECTION,
  CONTACT_VERTICAL_VIEW_TYPE,
  CONTACT_HORIZONTAL_VIEW_TYPE,
  MOBILE_CONTAINER_LEFT_RIGHT_PADDING,
  MOBILE_CONTAINER_TOP_BOTTOM_PADDING,
  DESKTOP_CONTAINER_TOP_BOTTOM_PADDING
} from '../../utils/constants';
import Markdown from '../core/Markdown';
import { StandardPageHeading } from '../core/StandardPageHeading';

const Content = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(DESKTOP_CONTAINER_TOP_BOTTOM_PADDING),
  marginBottom: theme.spacing(DESKTOP_CONTAINER_TOP_BOTTOM_PADDING)
}));

const ContactContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(0, DESKTOP_CONTAINER_LEFT_RIGHT_PADDING),
  display: 'flex',
  justifyContent: 'space-around'
}));

export default function ContactView({ config, page, handleContactSuccess }) {
  const isMobile = useMobile();
  const {
    showTitle,
    titleColour,
    contactNotes,
    viewDirection,
    verticalViewType,
    horizontalViewType,
    horizontalContactPosition
  } = config?.contact.general;
  const { showDivider } = config?.layout?.body;
  const isVertical = viewDirection === CONTACT_VIEW_DIRECTION[0];
  const horizontalDirection = horizontalViewType === CONTACT_HORIZONTAL_VIEW_TYPE[0] ? 'row' : 'row-reverse';
  const showCompactContactNotes = Boolean(isVertical && verticalViewType === CONTACT_VERTICAL_VIEW_TYPE[0]);
  const isVerticalViewWide = verticalViewType === CONTACT_VERTICAL_VIEW_TYPE[1];
  const shouldChangeContactPosition = Boolean(!isMobile && isVertical && isVerticalViewWide);

  const stackSpacing = isMobile ? MOBILE_STACK_SPACING : DESKTOP_STACK_SPACING;
  return (
    <Content
      spacing={stackSpacing}
      {...(showDivider ? { divider: <Divider sx={{ width: '100%' }} orientation="horizontal" /> } : null)}
      direction="column"
      alignItems="center"
      sx={{
        ...(isMobile && {
          marginTop: (theme) => theme.spacing(MOBILE_CONTAINER_TOP_BOTTOM_PADDING),
          marginBottom: (theme) => theme.spacing(MOBILE_CONTAINER_TOP_BOTTOM_PADDING)
        })
      }}
    >
      {showTitle && <StandardPageHeading title={page} titleColour={titleColour} />}
      <ContactContainer
        sx={{
          flexDirection: isVertical ? 'column' : horizontalDirection,
          ...(isMobile && { padding: (theme) => theme.spacing(0, MOBILE_CONTAINER_LEFT_RIGHT_PADDING) })
        }}
        {...(isVertical && { alignItems: 'center' })}
      >
        {Boolean(!isMobile && !isEmpty(contactNotes) && !showCompactContactNotes) && (
          <Markdown children={contactNotes} />
        )}
        <ContactForm
          config={{
            ...config?.global?.theme,
            ...config?.contact?.general
          }}
          showNotes={isMobile || showCompactContactNotes}
          handleContactSuccess={handleContactSuccess}
          isVertical={isVertical}
          sx={{
            ...(shouldChangeContactPosition && { alignSelf: getTextAlignPosition(horizontalContactPosition) })
          }}
        />
      </ContactContainer>
    </Content>
  );
}
