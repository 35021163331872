// ------- Types
/**
 * To access root path of resource simply leave the path as null.
 * e.g. If you are accessing /products/ then you will need to provide type: PRODUCT path: null
 */
export const SYSTEM_PAGE = 'SYSTEM_PAGE';
export const CUSTOM_PAGE = 'CUSTOM_PAGE';
export const PRODUCT_PAGE = 'PRODUCT';
export const COLLECTION_PAGE = 'COLLECTION';

// --- Paths - Param case
export const HOME_PATH = 'home';
export const SEARCH_PATH = 'search';
export const CART_PATH = 'cart';
export const CHECKOUT_PATH = 'checkout';
export const CONTACT_PATH = 'contact-us';
export const PRODUCTS_PATH = 'products';
export const COLLECTIONS_PATH = 'collections';

export function createTabValue(type, path) {
  return `${type}-${path}`;
}
