import React from 'react';
import { Icon } from '@iconify/react';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import snapchatFill from '@iconify/icons-akar-icons/snapchat-fill';
import youtubeFill from '@iconify/icons-akar-icons/youtube-fill';
import tiktokFill from '@iconify/icons-akar-icons/tiktok-fill';
import instagramFill from '@iconify/icons-akar-icons/instagram-fill';
import pinterestFill from '@iconify/icons-akar-icons/pinterest-fill';
import redditFill from '@iconify/icons-akar-icons/reddit-fill';
import { find } from 'lodash';

const SOCIAL_LINKS = [
  {
    key: 'FACEBOOK',
    icon: <Icon icon={facebookFill} />
  },
  {
    key: 'INSTAGRAM',
    icon: <Icon icon={instagramFill} />
  },
  {
    key: 'SNAPCHAT',
    icon: <Icon icon={snapchatFill} />
  },
  {
    key: 'TIKTOK',
    icon: <Icon icon={tiktokFill} />
  },
  {
    key: 'LINKEDIN',
    icon: <Icon icon={linkedinFill} />
  },
  {
    key: 'TWITTER',
    icon: <Icon icon={twitterFill} />
  },
  {
    key: 'YOUTUBE',
    icon: <Icon icon={youtubeFill} />
  },
  {
    key: 'PINTEREST',
    icon: <Icon icon={pinterestFill} />
  },
  {
    key: 'REDDIT',
    icon: <Icon icon={redditFill} />
  }
];

export const getSocialIcons = (key) => {
  return find(SOCIAL_LINKS, (link) => link.key === key).icon;
};
